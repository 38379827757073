.main-content .page-content {
	padding: 20px 20px 0;
}

.page-content .header {
	padding: 0 !important;	
}

/* GLOBALS */
.ta-c {
	text-align: center;
}
.mb-20 {
	margin-bottom: 20px;
}

.validation-errors {
	margin-bottom: 20px;
}
.validation-errors li {
	display: block;
	color: #FF0000;
}
.fetching p {
	text-align: center;
	font-size: 2em;
}

/* SIDE-BAR */
.sidebar .logopanel h1 a {
	background: none;
	color: #fff;
}

/* LEFT NAV */
.nav-hover {
	background: #181A1D;
}

/* LOGIN PAGE */

/* PRODUCTS PAGE */
.custom-price {
	background: #18a689;
	color: #fff;
}
.custom-price:hover {

}

/* HOLD TIGHT */
.hold-tight-overlay {
	position: absolute;
	z-index: 999999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: .5;
	display: block;
}
.hold-tight-container {
	position: relative;
	z-index: 9999999;
	top: 50%;
	left: 50%;
	width: 250px;
	height: 100px;
	background: #fff;
	margin-left: -75px;
	margin-top: -150px;
	overflow: hidden;
	box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
	border-radius: 6px;
	display: block;
}
.hold-tight-container > .message {
	margin: 10px;
	text-align: center;
}
.form-label-bold label {
	font-weight: bold;
}

/*** LINE ITEMS ***/
.over-flow {
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.line-items > .items-header,
.line-items > .items-footer {
	height: 35px;
	background: #F9F8F8;
	border: 1px solid #ECECEC;
	font-size: 1.2em;
	margin-bottom: 10px;
}
.line-items > .items-footer > a {
	margin-left: 15px;
}
.line-items > .items-header > .item-details {
	margin-left: 10px;
 }
.line-item-column:last-child {
	margin-right: 0;
}
.line-item-column {
	vertical-align: middle !important;
	display: inline-block;
	margin-right: 1.5%;
	vertical-align: top;
}
.line-items-subsection .section {
	/*margin-bottom: 25px;*/
	padding: 20px;
}
.line-item-total {
	background: #F9F8F8;
	border: 1px solid #ECECEC;
	margin-top: 50px;
}
.discount-dropdown {
	min-width: 43px;
	margin-top: 0;
}
/*
.line-item-column.subtotal {
	width: 100%;
}
.line-item-column.subtotal > .subtotal-label {
	width: inherit;
}
.line-item-column.subtotal > .subtotal-value {
	
}
*/
.case, #check_all, ._save {
  zoom: 1.25;
}
.item-remove {
	color: #F05050 !important;
}
.not-active .item-remove {
	color: grey !important;
}
.item-details {
	width: 37%;
}
.item-quantity {
	width: 8.5%;
}
.item-discount {
	width: 9.5%;
}
.item-rate {
	width: 10%;
}
.item-tax {
	width: 11%;
}
.item-amount {
	width: 11%;
}

.ui-menu-item {
	cursor: pointer;
}
.ui-menu-item:hover {
	background: #ECEDEE;
}

.ui-autocomplete {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  display: none;
  min-width: 160px;
  _width: 160px;
  padding: 4px 0;
  margin: 2px 0 0 0;
  list-style: none;
  background-color: #ffffff;
  border-color: #ccc;
  border-color: rgba(0, 0, 0, 0.2);
  border-style: solid;
  border-width: 1px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  *border-right-width: 2px;
  *border-bottom-width: 2px;
 
  .ui-menu-item > a.ui-corner-all {
	display: block;
	padding: 3px 15px;
	clear: both;
	font-weight: normal;
	line-height: 18px;
	color: #555555;
	white-space: nowrap;
 
	&.ui-state-hover, &.ui-state-active {
	  color: #ffffff;
	  text-decoration: none;
	  background-color: #0088cc;
	  border-radius: 0px;
	  -webkit-border-radius: 0px;
	  -moz-border-radius: 0px;
	  background-image: none;
	}
  }
}

.edit-padding {
  padding: 5px;
}
.edit-pricing {
  margin: 0; 
}
.delete-pricing {
  margin-left: 5px;
}
.isloading-show {
  position: relative;
  top: 30% !important;
  left: 50%;
  margin-left: -57px;
  padding: 10px;
  font-size: 1.15em;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
}
#client-account p {
	margin-bottom: 5px;
}
.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 2px !important;
}
.btn {
  padding: 7px 10px 8px;
  margin-right: 0 !important;
}
#client-account h3 {
  margin-top: 0;
}
.btn-sm {
  margin: 4px;
}


.select2-container--default .select2-selection--single {
    height: 34px;
    border-radius: 3px;
    padding-left: 10px;
    font-size: 14px;
    background: #fff;
    border: 1px solid #BDC4C9;
    display: block;
    box-shadow: inset 0px 1px 0px #F1F0F1;
    line-height: 34px !important;
    color: #555 !important;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 2px;
  text-shadow: none;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #555;
  line-height: 33px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #000 transparent;
  border-width: 0 3px 6px 3px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #000 transparent transparent transparent;
  border-style: solid;
  border-width: 6px 3px 0 3px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: 0px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container {
	z-index: 9999;
}