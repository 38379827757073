/* CANT DO THIS IN GULP */
/*@import "http://fonts.googleapis.com/css?family=Lato:300,400,700,900";
@import "http://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700";
@import "../plugins/bootstrap/css/bootstrap.min.css";
@import "../plugins/bootstrap/css/bootstrap-rtl.min.css";
@import "../plugins/mcustom-scrollbar/mcustom_scrollbar.min.css";
@import "../plugins/animation-css/animate.min.css";
@import "icons/font-awesome/font-awesome.css";
@import "icons/line-icons/line-icons.css";
@import "icons/line-icons/simple-line-icons.css";
@import "../plugins/select2/select2.css";
@import "../plugins/slick/slick.css";
@import "../plugins/icheck/skins/all.css";
@import "../plugins/bootstrap/css/jasny-bootstrap.css";
@import "icons/rounded/style.css";
@import "icons/octicons/octicons.css";
@import "icons/weather/css/weather-icons.min.css";
@import "../plugins/bootstrap-loading/lada.min.css";
@import "../plugins/bootstrap-tags-input/bootstrap-tagsinput.css";
@import "../plugins/bootstrap-editable/css/bootstrap-editable.css";
@import "../plugins/prettify/prettify.css";*/
/**** SIDEBAR  ****/
/**** TOPBAR ****/
/**** QUICKVIEW SIDEBAR ****/
/**** BOXED LAYOUT ****/
/**** MAIN CONTENT ****/
/**** WIDGETS ****/
/**** PAGES ****/
/**** SEARCH ****/
/**** BUILDER ****/
/******** UI **********/
/* Main Color */
/* Alert & Notification */
/* Popover */
/* Form */
/* Select Input */
/* Tabs */
/* Table */
/* Summernote */
/******* THEMES *******/
/* Mixin */
/* MANDATORY CSS */
/* Select & Radio Inputs */
/* Text & Image Slider */
/* Checbox & Radio Icheck */
/* File Upload and Input Masks */
/* OPTIONAL CSS */
/* Depend if you use this functionnalities in your project */
/* Rounded Icons - See Icons Page - OPTIONAL */
/* Topbar Left Icons - OPTIONAL */
/* Weather Icons - See Icons Page - OPTIONAL */
/* Buttons Loading State - See Buttons Page - OPTIONAL */
/* Tags Input - See Form Elements Page - OPTIONAL */
/* Inline Edition X-Editable - OPTIONAL */
/* Show Html Code - OPTIONAL */
/* FONTS */
@font-face {
  font-family: 'weather';
  src: url('icons/weather/artill_clean_icons-webfont.eot');
  src: url('icons/weather/artill_clean_icons-webfont.eot?#iefix') format('embedded-opentype'), url('icons/weather/artill_clean_icons-webfont.woff') format('woff'), url('icons/weather/artill_clean_icons-webfont.ttf') format('truetype'), url('icons/weather/weatherfont/artill_clean_icons-webfont.svg#artill_clean_weather_iconsRg') format('svg');
  font-weight: normal;
  font-style: normal;
}
html {
  height: 100%;
}
body {
  height: 100%;
  background: #F5F5F5;
  color: #5B5B5B;
  font-family: 'Lato', 'Open Sans', Helvetica, sans-serif !important;
  line-height: 1.42857143;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body > section {
  opacity: 0;
  height: 100%;
}
body p strong {
  font-family: 'Lato';
  font-weight: 600;
}
body > .pace > .pace-activity {
  display: none !important;
}
input,
select,
textarea {
  color: #636e7b;
  font-family: 'Lato', 'Open Sans', Helvetica, sans-serif !important;
}
ol,
ul {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
pre {
  color: #121212;
}
a {
  color: #319DB5;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:focus,
a:active {
  outline: none;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Lato', 'Open Sans', Helvetica, sans-serif;
  font-weight: 100;
}
h1.light,
h2.light,
h3.light,
h4.light,
h5.light {
  font-family: 'Lato', 'Open Sans', Helvetica, sans-serif;
  font-weight: 100;
}
h1,
.h1 {
  font-size: 45px;
  margin-top: 0;
  font-family: 'Lato', 'Open Sans', Helvetica, sans-serif;
}
.font-nothing {
  font-family: 'Nothing You Could Do', 'Arial', cursive;
}
.font-nothing strong {
  font-weight: 600;
  font-family: 'Nothing You Could Do', 'Arial', cursive;
}
div,
p,
span {
  text-align: left;
}
h2,
.h2 {
  font-size: 25px;
}
h3 {
  font-size: 20px;
}
strong {
  font-family: 'Lato';
  font-weight: 900;
}
p {
  line-height: 23px;
}
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  text-align: left;
}
.boxed-layout {
  position: relative;
  width: 1170px;
  margin-right: auto;
  margin-left: auto;
}
.fixed-sidebar.fixed-topbar.modal-open {
  padding-right: 0 !important;
}
a.scrollup {
  display: none;
  background: #2B2E33;
  height: 32px;
  width: 32px;
  color: #fff;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  text-decoration: none;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 9999;
  opacity: .5;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  border-radius: 50% !important;
  border-radius: 16px;
}
a.scrollup i {
  display: inline-block;
  padding-top: 3px;
}
a.scrollup:hover {
  opacity: 1;
  color: #fff;
}
.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}
@-moz-document url-prefix() {
  .topbar .header-left .topnav .menu__handle::before,
  .topbar .header-left .topnav .menu__handle::after {
    -moz-transition: -moz-transform 0s;
    -webkit-transition: -webkit-transform 0s;
    transition: transform 0s;
  }
}
::-webkit-scrollbar {
  z-index: 30;
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: #eaeaea;
  border-left: 1px solid #c1c1c1;
}
::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}
::-webkit-scrollbar-track {
  border-radius: 0;
  box-shadow: none;
  border: 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 0;
  box-shadow: none;
  border: 0;
}
@media print {
  body {
    margin-top: 0;
  }
  .main-content {
    padding: 0;
    margin: 0 !important;
    background-color: transparent;
  }
  .no-print,
  .no-print *,
  .sidebar,
  .topbar,
  .header {
    display: none !important;
  }
  .invoice {
    max-width: 100%;
    max-height: 100%;
    padding: 0 !important;
    border: none;
  }
}
.loader-overlay {
  -moz-opacity: 1;
  -moz-perspective: 10000;
  -moz-perspective: 10000px;
  -moz-transition: all 800ms ease-in-out;
  -o-transition: all 800ms ease-in-out;
  -webkit-opacity: 1;
  -webkit-perspective: 10000;
  -webkit-perspective: 10000px;
  -webkit-transition: all 800ms ease-in-out;
  background: #2D2E2F;
  bottom: 0;
  filter: alpha(opacity=100);
  left: 0;
  opacity: 1;
  overflow: hidden;
  perspective: 10000;
  perspective: 10000px;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 800ms ease-in-out;
  z-index: 10000;
  zoom: 1;
}
.loader-overlay .loader-inner {
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  position: absolute;
  top: 50%;
}
.loader-overlay.loaded {
  -moz-opacity: 0;
  -webkit-opacity: 0;
  filter: alpha(opacity=0);
  opacity: 0;
  visibility: hidden;
  zoom: 1;
}
#status {
  font-size: 32px;
  height: 30px;
  left: 46%;
  margin: -15px 0 0 -15px;
  position: absolute;
  top: 45%;
  width: 30px;
}
.spinner {
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  bottom: 0;
  height: 2em;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  user-select: none;
  width: 5em;
}
.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
  margin-right: .5em;
}
.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  margin-right: .5em;
}
.spinner > div {
  -moz-border-radius: 100%;
  -webkit-animation-fill-mode: both;
  -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
  -webkit-border-radius: 100%;
  animation-fill-mode: both;
  animation: bouncedelay 1.4s infinite ease-in-out;
  background-color: #ffffff;
  border-radius: 100%;
  display: inline-block;
  height: 1em;
  width: 1em;
}
.landing-slider .spinner > div {
  background-color: rgba(255, 255, 255, 0.5);
}
.landing-els .spinner > div {
  background-color: rgba(0, 0, 0, 0.2);
}
.context-menu .dropdown-menu {
  padding: 0;
}
.context-menu .dropdown-menu .dropdown-title {
  background: #319DB5;
  padding: 4px 15px;
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
  font-family: 'Lato', arial, sans-serif;
  font-weight: 900;
  letter-spacing: 1.5px;
}
#map .amcharts-chart-div svg {
  left: 0;
  right: auto;
}
#noty_topLeft_layout_container li:first-child {
  position: relative;
  left: 40px;
  top: 0;
}
.circular {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  margin-left: -20px;
  height: 80px;
  position: relative;
  color: #319DB5;
  width: 80px;
}
.path {
  stroke-dasharray: 1,200;
  stroke-dashoffset: 0;
  -webkit-animation: dash 1.5s ease-in-out infinite, color 4s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite, color 4s ease-in-out infinite;
  stroke-linecap: round;
}
.main-content .page-content .panel .panel-header h2 {
  margin-bottom: 5px;
  margin-top: 7px;
}
.main-content .page-content[class^='bg-'].panel h1,
.main-content .page-content[class*=' bg-'].panel h1,
.main-content .page-content[class^='bg-'].panel h2,
.main-content .page-content[class*=' bg-'].panel h2,
.main-content .page-content[class^='bg-'].panel h3,
.main-content .page-content[class*=' bg-'].panel h3,
.main-content .page-content .panel[class^='bg-']h2,
.main-content .page-content .panel[class*=' bg-']h2,
.main-content .page-content .panel[class^='bg-']h3,
.main-content .page-content .panel[class*=' bg-']h3 {
  color: #ffffff !important;
}
.note-editor .btn.btn-transparent {
  margin-left: 0;
  margin-right: 0;
}
.note-editor .btn {
  height: 37px !important;
}
.note-editor .dropdown-toggle .caret {
  margin-top: 5px;
}
.note-air-popover .btn.btn-transparent {
  height: 37px !important;
  margin-left: 0;
  margin-right: 0;
}
.note-color .dropdown-toggle {
  width: 32px !important;
}
.text-center {
  text-align: center !important;
}
@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1,100;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,100;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,100;
    stroke-dashoffset: -62;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1,100;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,100;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,100;
    stroke-dashoffset: -62;
  }
}
@-webkit-keyframes color {
  100%,
  0% {
    stroke: #319db5;
  }
  30% {
    stroke: #9b7ed1;
  }
  60% {
    stroke: #18a689;
  }
  80%,
  90% {
    stroke: #d62d20;
  }
}
@keyframes color {
  100%,
  0% {
    stroke: #319db5;
  }
  30% {
    stroke: #9b7ed1;
  }
  60% {
    stroke: #18a689;
  }
  80%,
  90% {
    stroke: #d62d20;
  }
}
@-webkit-keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.topbar {
  -moz-transition: color 0.2s ease-out 0;
  -webkit-transition: color 0.2s ease-out 0;
  background: #ffffff;
  height: 50px;
  transition: color 0.2s ease-out 0;
}
.topbar::after {
  clear: both;
  content: '';
  display: block;
}
.topbar .header-left {
  float: left;
}
.topbar .header-left .topnav {
  float: left;
  height: 50px;
  margin: 0;
}
.topbar .header-left .topnav .menutoggle {
  background: none;
  color: #2b2e33;
  cursor: pointer;
  float: left;
  font-size: 22px;
  height: 50px;
  position: relative;
  text-decoration: none !important;
  width: 50px;
}
.topbar .header-left .topnav .menutoggle i {
  display: inline-block;
  margin: 14px 0 0 13px;
}
.topbar .header-left .topnav .menutoggle i:before {
  -webkit-font-smoothing: antialiased;
  content: "r";
  font-family: "line-icons" !important;
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: normal !important;
  line-height: 1;
  speak: none;
  text-transform: none !important;
}
.topbar .header-left .topnav .menu__handle {
  background: rgba(0, 0, 0, 0);
  border: none;
  height: 24px;
  left: 18px;
  outline: none;
  padding: 0;
  position: absolute;
  top: 11px;
  width: 20px;
  z-index: 2000;
}
.topbar .header-left .topnav .menu__handle::before {
  -moz-transform-origin: 50% 50%;
  -moz-transform: translate3d(0, -5px, 0);
  -webkit-transform-origin: 50% 50%;
  -webkit-transform: translate3d(0, -5px, 0);
  transform-origin: 50% 50%;
  transform: translate3d(0, -5px, 0);
  background: #2b2e33;
  content: '';
  height: 2px;
  left: 0;
  position: absolute;
  top: 50%;
  width: 100%;
  -webkit-transition: transform 0.3s linear;
  -moz-transition: transform 0.3s linear;
  -o-transition: transform 0.3s linear;
  -ms-transition: transform 0.3s linear;
  transition: transform 0.3s linear;
}
.topbar .header-left .topnav .menu__handle::after {
  -moz-transform-origin: 50% 50%;
  -moz-transform: translate3d(0, 5px, 0);
  -webkit-transform-origin: 50% 50%;
  -webkit-transform: translate3d(0, 5px, 0);
  background: #2b2e33;
  content: '';
  height: 2px;
  left: 0;
  position: absolute;
  top: 50%;
  transform-origin: 50% 50%;
  transform: translate3d(0, 5px, 0);
  width: 100%;
  -webkit-transition: transform 0.3s linear;
  -moz-transition: transform 0.3s linear;
  -o-transition: transform 0.3s linear;
  -ms-transition: transform 0.3s linear;
  transition: transform 0.3s linear;
}
.topbar .header-left .topnav .menu__handle span {
  height: 2px;
  left: 0;
  overflow: hidden;
  position: absolute;
  text-indent: 200%;
  top: 12px;
  -webkit-transition: opacity 0.25s linear;
  -moz-transition: opacity 0.25s linear;
  -o-transition: opacity 0.25s linear;
  -ms-transition: opacity 0.25s linear;
  transition: opacity 0.25s linear;
  width: 100%;
}
.topbar .header-left .topnav .mmenu .dropdown a {
  color: #656565;
}
.topbar .header-left .topnav .mmenu .dropdown-menu {
  border: 1px solid #efefef;
  box-shadow: none;
  left: auto;
  margin-top: 0 !important;
  padding: 0;
}
.topbar .header-left .topnav .mmenu .dropdown-menu a {
  color: #656565;
}
.topbar .header-left .topnav .mmenu .dropdown-menu .withoutdesc {
  display: block;
  margin-top: 0;
  padding: 15px 20px;
  text-align: left;
  text-transform: none;
  width: 100%;
}
.topbar .header-left .topnav .mmenu .dropdown-menu .withoutdesc ul li {
  padding: 3px 10px;
}
.topbar .header-left .topnav .mmenu .dropdown-menu .withoutdesc ul li:hover {
  background-color: #f5f5f5!important;
  color: #262626;
  text-decoration: none;
}
.topbar .header-left .topnav .mmenu .dropdown-menu .withoutdesc ul li:focus {
  background-color: #f5f5f5!important;
  color: #262626;
  text-decoration: none;
}
.topbar .header-left .topnav .mmenu .dropdown-menu .withoutdesc li:last-child {
  border-bottom: 0 solid #fff;
}
.topbar .header-left .topnav .mmenu .dropdown-menu > li > a {
  padding: 6px 15px;
}
.topbar .header-left .topnav .mmenu .navbar-nav > li > .dropdown-menu {
  margin-top: 1px;
}
.topbar .header-left .topnav .mmenu i {
  color: #BFBFBF;
}
.topbar .header-left .topnav .mmenu .form-control {
  border: 1px solid #efefef;
  margin-top: 10px;
}
.topbar .header-left .topnav .mmenu .btn {
  margin: 10px 0 20px;
}
.topbar .header-left .topnav .mmenu video {
  height: auto;
  max-width: 100%;
}
.topbar .header-left .topnav .mmenu iframe {
  max-width: 100%;
}
.topbar .header-left .topnav .mmenu embed {
  max-width: 100%;
}
.topbar .header-left .topnav .mmenu object {
  max-width: 100%;
}
.topbar .header-left .topnav .mmenu a:hover {
  text-decoration: none;
}
.topbar .header-left .topnav .mmenu .mmenu-content.withdesc a:after {
  color: #CFCFCF;
  content: attr(data-description);
  display: block;
  font-size: 11px;
  font-weight: 400;
  line-height: 0;
  margin: 10px 0 15px;
  text-transform: uppercase;
}
.topbar .header-left .topnav .mmenu .dropdown-submenu {
  position: relative;
}
.topbar .header-left .topnav .mmenu .dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.topbar .header-left .topnav .mmenu .dropdown-submenu:hover > a after {
  border-left-color: #fff;
}
.topbar .header-left .topnav .mmenu .dropdown-submenu > .dropdown-menu {
  left: 100%;
  margin-left: 0;
  margin-top: 0 !important;
  top: 0;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px 6px;
  border-radius: 0 6px 6px 6px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.topbar .header-left .topnav .mmenu .dropdown-submenu > a:after {
  border-color: transparent;
  border-left-color: #cccccc;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  content: " ";
  display: block;
  float: right;
  height: 0;
  margin-right: -2px;
  margin-top: 5px;
  width: 0;
}
.topbar .header-left .topnav .mmenu .dropdown-submenu.pull-left {
  float: none;
}
.topbar .header-left .topnav .mmenu .dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.topbar .header-left .topnav .mmenu p {
  color: #656565;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
}
.topbar .header-left .topnav .mmenu .half {
  left: auto!important;
  right: auto!important;
  width: 50%;
  float: none;
  text-align: left;
}
.topbar .header-left .topnav .mmenu .container {
  position: relative;
}
.topbar .header-left .topnav .mmenu .nav.navbar-right .dropdown-menu {
  left: auto;
  right: 0;
}
.topbar .header-left .topnav .mmenu .mmenu-content {
  padding: 15px 25px;
}
.topbar .header-left .topnav .mmenu .dropdown.mmenu-fw .dropdown-menu {
  left: 0;
  margin-top: 0 !important;
  right: 0;
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.18);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.18);
}
.topbar .header-left .topnav .mmenu .title {
  border-bottom: 1px solid #efefef;
  font-family: 'Lato', 'Open Sans';
  font-size: 14px;
  margin-top: 15px;
  padding-bottom: 10px;
  text-transform: uppercase;
}
.topbar .header-left .topnav .mmenu .title strong {
  font-family: 'Lato', 'Open Sans';
  font-weight: 900;
}
.topbar .header-left .topnav .mmenu ul {
  list-style: none;
}
.topbar .header-left .topnav .sidebar-collapsed .mmenu .dropdown.mmenu-fw .dropdown-menu {
  left: 50px;
  right: 0;
}
.topbar .header-left .topnav .mmenu-dark.mmenu .dropdown .dropdown-menu {
  background-color: #202226;
  border: none;
  color: #ffffff;
}
.topbar .header-left .topnav .mmenu-dark.mmenu .dropdown .dropdown-menu .title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.topbar .header-left .topnav .mmenu-dark.mmenu .dropdown .dropdown-menu hr {
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}
.topbar .header-left .topnav .mmenu-dark.mmenu .dropdown .dropdown-menu .withoutdesc ul li {
  padding: 3px 10px;
}
.topbar .header-left .topnav .mmenu-dark.mmenu .dropdown .dropdown-menu .withoutdesc ul li:hover {
  background-color: rgba(255, 255, 255, 0.05) !important;
  color: #fff;
}
.topbar .header-left .topnav .mmenu-dark.mmenu .dropdown .dropdown-menu .withoutdesc ul li:focus {
  background-color: rgba(255, 255, 255, 0.05) !important;
  color: #fff;
}
.topbar .header-left .topnav .mmenu-dark.mmenu .dropdown-menu a {
  color: #DADADA;
}
.topbar .header-left .topnav .mmenu-dark.mmenu .mmenu-content.withdesc a:after {
  color: #6A6A6A;
}
.topbar .header-left .topnav .mmenu-dark.mmenu p {
  color: #959595;
}
.topbar .header-left .topnav .mmenu-dark.mmenu .form-control {
  background-color: #3D4043;
  border: 1px solid #232323;
  color: #D3D3D3;
}
.topbar .header-left .topnav > ul {
  float: left;
  height: 50px;
  margin: 0;
  padding: 0;
}
.topbar .header-left .topnav > ul > li {
  float: left;
  height: 100%;
  margin: 0;
}
.topbar .header-left .topnav > ul > li .dropdown-menu {
  margin-top: 2px;
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.topbar .header-left .topnav > ul > li .dropdown-menu i {
  display: none;
}
.topbar .header-left .topnav > ul > li .dropdown-menu > li > a {
  font-size: 13px;
  padding: 6px 10px;
}
.topbar .header-left .topnav > ul > li .dropdown-menu > li > a:hover {
  background: #319DB5;
  color: #ffffff;
}
.topbar .header-left .topnav > ul > li > a {
  color: #666666;
  font-size: 14px;
  height: 100%;
  padding: 13px 19px 13px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.topbar .header-left .topnav > ul > li > a::before {
  -moz-transform: scale(0);
  -moz-transition: -moz-transform 0.3s;
  -webkit-transform: scale(0);
  -webkit-transition: -webkit-transform 0.3s;
  content: '';
  height: 2px;
  left: 0;
  position: absolute;
  top: 0;
  transform: scale(0);
  transition: transform 0.3s;
  width: 100%;
}
.topbar .header-left .topnav > ul > li > a i {
  font-size: 14px;
  margin-right: 6px;
}
.topbar .header-left .topnav > ul > li > a i.icons-arrows-06 {
  display: inline-block;
  font-size: 9px;
  margin-bottom: -1px;
  padding-left: 7px;
  vertical-align: bottom;
}
.topbar .header-left .topnav > ul > li > a .badge {
  margin-left: 10px;
}
.topbar .header-left .topnav > ul.nav-icons > li > a {
  padding: 0;
}
.topbar .header-left .topnav > ul.nav-icons > li > a:hover {
  padding: 0;
}
.topbar .header-left ul.nav-icons li {
  padding: 10px 20px;
}
.topbar .header-left ul.nav-icons li a {
  background: none !important;
  font-size: 19px;
  width: 20px;
}
.topbar .header-left ul.nav-icons li a:before {
  display: none !important;
}
.topbar .header-left ul.nav-icons li span {
  color: #484E55;
  display: block;
  font-size: 20px;
  padding-top: 4px;
}
.topbar .header-left ul.nav-icons li span.icon-user-unfollow {
  background: none !important;
  font-size: 19px;
  width: 20px;
}
.topbar .header-left ul.nav-icons li span.icon-user-following {
  background: none !important;
  font-size: 19px;
  width: 20px;
}
.topbar .header-right {
  float: right;
}
.topbar .header-right .nav > li {
  float: left;
}
.topbar .header-right .navbar-nav > li > a {
  height: 50px;
  line-height: normal;
}
.topbar .header-right .navbar-nav > li > a:hover {
  background: none !important;
}
.topbar .header-right .navbar-nav > li > a:focus {
  background: none !important;
}
.topbar .header-right .header-menu > li > a > i {
  display: block;
  float: left;
  margin-top: 14px;
}
.topbar .header-right .header-menu > li > a > span:not(.badge) {
  display: block;
  float: left;
  line-height: 48px;
}
.topbar .header-right .header-menu .badge-header {
  font-size: 12px;
  padding: 2px 5px;
  position: relative;
  right: 8px;
  top: -8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.topbar .header-right .header-menu .dropdown-menu > li > a.pull-right {
  clear: none;
}
.topbar .header-right .header-menu .header-icon {
  display: inline;
  height: 22px;
  max-width: 100%;
}
.topbar .header-right .header-menu .dropdown-menu {
  border-top-left-radius: 1px !important;
  border-top-right-radius: 1px !important;
  left: auto;
  margin: 0;
  max-width: 290px !important;
  min-width: 170px !important;
  padding: 0;
  right: 0;
  top: 53px;
  width: 280px;
}
.topbar .header-right .header-menu .dropdown-menu:after {
  border-bottom: 6px solid #2b2e33;
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  content: "";
  display: inline-block;
  position: absolute;
  right: 17px;
  top: -6px;
}
.topbar .header-right .header-menu .dropdown-menu .dropdown-header {
  background: #2b2e33;
  color: #666666;
  font-family: 'Open Sans';
  font-size: 15px;
  padding: 8px 15px;
}
.topbar .header-right .header-menu .dropdown-menu .dropdown-header:hover {
  background: #2b2e33;
  color: #666666;
  cursor: default;
}
.topbar .header-right .header-menu .dropdown-menu .dropdown-header p {
  color: #E4E4E4 !important;
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 13px;
  margin: 0;
}
.topbar .header-right .header-menu .dropdown-menu ul {
  list-style-type: none;
  padding-left: 0;
}
.topbar .header-right .header-menu .dropdown-menu li {
  background: none;
  padding: 0;
}
.topbar .header-right .header-menu .dropdown-menu li:hover {
  background: none;
}
.topbar .header-right .header-menu .dropdown-menu li a:hover {
  background: #f5f5f5;
}
.topbar .header-right .header-menu .dropdown-menu li ul li {
  padding: 12px 10px 6px 10px;
}
.topbar .header-right .header-menu .dropdown-menu li ul li:hover {
  background: #eaeaea;
}
.topbar .header-right .header-menu .dropdown-menu li ul li a {
  display: block;
  width: 100%;
}
.topbar .header-right .header-menu .dropdown-menu li ul li a i {
  width: 30px;
}
.topbar .header-right .header-menu .dropdown-menu li ul li a:hover {
  text-decoration: none;
}
.topbar .header-right .header-menu .dropdown-menu li ul li .dropdown-time {
  color: #777777;
  display: inline-block;
  float: right;
  font-size: 11px;
  text-align: right;
}
.topbar .header-right .header-menu .dropdown-menu a {
  color: #121212;
}
.topbar .header-right .header-menu .dropdown-menu a:hover {
  background: none;
}
.topbar .header-right .header-menu .dropdown-menu .dropdown-footer {
  background: #F8F8F8;
  border-top: 1px solid #E2E2E2;
  color: #121212;
  font-family: 'Open Sans';
  font-size: 12px;
  padding: 5px;
}
.topbar .header-right .header-menu .dropdown-menu .dropdown-footer a {
  padding: 3px 5px;
}
.topbar .header-right .header-menu .dropdown-menu .dropdown-footer a:hover {
  background: none;
}
.topbar .header-right .header-menu .dropdown-menu > li > a.pull-left {
  clear: none;
}
.topbar .header-right .header-menu #notifications-header > a > i {
  color: #666666;
  display: inline-block;
  font-size: 20px;
}
.topbar .header-right .header-menu #messages-header > a > i {
  color: #666666;
  display: inline-block;
  font-size: 20px;
}
.topbar .header-right .header-menu #messages-header ul li img {
  height: 30px;
  margin-top: -5px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.topbar .header-right .header-menu #messages-header .dropdown-body p {
  font-size: 12px;
  font-style: italic;
  margin: 5px 0 5px;
}
.topbar .header-right .header-menu #user-header {
  margin-right: 10px;
}
.topbar .header-right .header-menu #user-header a {
  color: #666666;
}
.topbar .header-right .header-menu #user-header img {
  float: left;
  margin-right: 6px;
  margin-top: 6px;
  width: 36px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.topbar .header-right .header-menu #user-header li a:hover {
  background-color: #eaeaea;
  text-decoration: none;
}
.topbar .header-right .header-menu #user-header .dropdown-menu {
  width: 150px !important;
}
.topbar .header-right .header-menu #user-header .dropdown-menu li a {
  background: #2b2e33;
  color: #959FA9;
  display: block;
  font-size: 13px;
  padding: 10px 12px;
}
.topbar .header-right .header-menu #user-header .dropdown-menu li a:hover {
  color: #ffffff;
}
.topbar .header-right .header-menu #user-header .dropdown-menu li a i {
  padding-right: 8px;
  position: absolute;
}
.topbar .header-right .header-menu #user-header .dropdown-menu li a span {
  padding-left: 25px;
}
.topbar .header-right .header-menu #user-header .dropdown-menu li.dropdown-footer {
  background-color: #E9E9E9;
  padding: 0;
}
.topbar .header-right .header-menu #user-header .dropdown-menu li.dropdown-footer a i {
  position: relative;
}
.topbar .header-right .header-menu #user-header .dropdown-menu .dropdown-footer a {
  color: #575757;
  display: inline-block;
  font-size: 16px;
  padding: 8px;
  text-align: center;
  width: 56px;
}
.topbar .header-right .header-menu #user-header .dropdown-menu .dropdown-footer a:hover {
  background-color: #dcdcdc;
}
.topbar .header-right .header-menu #user-header .dropdown-menu .dropdown-footer a i {
  padding-right: 0;
}
.topbar .header-right .header-menu #user-header > a > i {
  font-size: 19px;
  padding-right: 5px;
}
.topbar .header-right .header-menu #language-header {
  margin-right: 10px;
}
.topbar .header-right .header-menu #language-header a {
  color: #666666;
}
.topbar .header-right .header-menu #language-header a i {
  font-size: 19px;
  padding-right: 7px;
}
.topbar .header-right .header-menu #language-header li a:hover {
  text-decoration: none;
}
.topbar .header-right .header-menu #language-header .dropdown-menu {
  max-width: 140px !important;
  min-width: 70px !important;
  width: 130px !important;
}
.topbar .header-right .header-menu #language-header .dropdown-menu li a {
  display: block;
  font-size: 13px;
  padding: 10px 12px;
}
.topbar .header-right .header-menu #language-header .dropdown-menu li a i {
  padding-right: 8px;
  position: absolute;
}
.topbar .header-right .header-menu #language-header .dropdown-menu li a img {
  width: 22px;
}
.topbar .header-right .header-menu #language-header .dropdown-menu li a span {
  padding-left: 10px;
}
.topbar .header-right .header-menu #language-header .dropdown-menu:after {
  border-bottom: none;
  border-left: none;
  border-right: none;
}
.topbar .header-right .header-menu #notifications-header .glyph-icon {
  font-size: 22px;
  margin-top: 2px;
}
.topbar .header-right .header-menu .notification {
  display: inline-block;
  height: 8px;
  left: 10px;
  position: absolute;
  top: 7px;
  width: 8px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.topbar .header-right .header-menu .tp-icon {
  background: #ffffff;
  border-color: #fff;
  color: #333333;
  font-size: 16px;
  height: 50px;
  padding: 12px 15px;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.topbar .header-right .header-menu .tp-icon + .dropdown-menu:after {
  right: 17px;
}
.topbar .header-right .header-menu .chat-icon {
  border-right: 0;
}
.topbar .header-right .header-menu .dropdown-menu-usermenu {
  background: #2b2e33;
  min-width: 200px;
}
.topbar .header-right .header-menu .dropdown-menu-head ul {
  border-top: 0;
  border: 1px solid #dddddd;
  padding: 0 10px;
}
.topbar .header-right .header-menu .dropdown-menu-head li a {
  color: #333333;
  opacity: 1;
  padding: 0;
}
.topbar .header-right .header-menu .dropdown-menu-head li a:hover {
  background-color: #ffffff !important;
  color: #428BCA;
  text-decoration: none;
}
.topbar .header-right .header-menu .btn-group {
  margin-bottom: 0;
}
.topbar .header-right .header-menu .nav > li {
  float: left;
}
.topbar .header-right .header-menu .navbar-nav {
  margin: 0;
}
.topbar .header-right .header-menu .navbar-nav .open .dropdown-menu > li > a {
  padding: 3px 5px;
}
.topbar .header-right .header-menu .navbar-nav .open .dropdown-menu {
  max-width: 290px !important;
  min-width: 170px !important;
  position: absolute !important;
  width: 280px !important;
}
.topbar .header-right .header-menu.navbar-nav {
  float: left !important;
  margin: 0;
}
.topbar .header-right .header-menu.navbar-nav .dropdown-menu {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  position: absolute !important;
  -webkit-box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.03);
  box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.03);
}
.topbar .header-right .header-menu.navbar-nav > li > a {
  line-height: 50px;
  padding-bottom: 0;
  padding-top: 0;
}
.topbar .nav .open > a {
  background: none !important;
  border: none !important;
}
.topbar .nav .open > a:hover {
  background: none !important;
  border: none !important;
}
.topbar .nav .open > a:focus {
  background: none !important;
  border: none !important;
}
.topbar.c-light .header-left .topnav > ul > li > a,
.topbar.c-light .header-left .topnav .menutoggle,
.topbar.c-light .header-right .header-menu #notifications-header > a > i,
.topbar.c-light .header-right .header-menu #messages-header > a > i,
.topbar.c-light .header-right .header-menu #user-header a {
  color: #EBEBEB !important;
}
.topbar .header-left .topnav > ul > li > a:hover,
.topbar .header-left .topnav > ul:not(.nav-icons) > li > a:focus,
.topbar .header-left .topnav > ul:not(.nav-icons) > li.open > a,
.topbar .header-left .topnav > ul:not(.nav-icons) > li.active > a {
  background: none;
  color: #298598;
  padding: 13px 19px 13px;
}
.topbar .header-left .topnav > ul > li.active > a::before,
.topbar .header-left .topnav > ul > li.open > a::before,
.topbar .header-left .topnav > ul > li > a:hover::before,
.topbar .header-left .topnav > ul > li > a:focus::before {
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  width: 100%;
}
.menu__handle span {
  background: #2b2e33;
}
.topbar .header-left .topnav .mmenu .nav,
.topbar .header-left .topnav .mmenu .collapse,
.topbar .header-left .topnav .mmenu .dropup,
.topbar .header-left .topnav .mmenu .dropdown {
  position: static;
}
.topbar.topbar-condensed .header-left .topnav > ul > li > a {
  font-size: 11px;
  padding: 17px 10px 12px;
}
.topbar.topbar-condensed .header-left .topnav .logopanel2 {
  display: none;
}
.topbar.topbar-condensed .header-right .header-menu #notifications-header {
  display: none;
}
body:not(.sidebar-collapsed) .topbar .header-left .topnav .menutoggle:hover .menu__handle::before {
  -moz-transform: translate3d(0, -6px, 0);
  -webkit-transform: translate3d(0, -6px, 0);
  transform: translate3d(0, -6px, 0);
}
body:not(.sidebar-collapsed) .topbar .header-left .topnav .menutoggle:hover .menu__handle::after {
  -moz-transform: translate3d(0, 6px, 0);
  -webkit-transform: translate3d(0, 6px, 0);
  transform: translate3d(0, 6px, 0);
}
.fixed-topbar .topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 240px;
  z-index: 801;
  z-index: 300;
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
}
.sidebar-collapsed.fixed-topbar .topbar {
  left: 50px;
}
@media screen and (min-width: 1025px) {
  .sidebar-collapsed .topbar .header-left .topnav .menu__handle {
    background: rgba(0, 0, 0, 0);
    left: 14px;
    top: 12px;
  }
  .sidebar-collapsed .topbar .header-left .topnav .menu__handle::before {
    -moz-transform: rotate3d(0, 0, 1, 45deg);
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    background: #ffffff;
    transform: rotate3d(0, 0, 1, 45deg);
  }
  .sidebar-collapsed .topbar .header-left .topnav .menu__handle::after {
    -moz-transform: rotate3d(0, 0, 1, -45deg);
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    background: #ffffff;
    transform: rotate3d(0, 0, 1, -45deg);
  }
  .sidebar-collapsed .topbar .header-left .topnav .menu__handle span {
    opacity: 0;
  }
  .sidebar-collapsed .menu__handle span {
    background: #ffffff;
  }
}
@media screen and (max-width: 1024px) {
  .topbar.topbar-condensed .header-left .topnav > ul {
    left: 50px;
  }
  .topbar.topbar-condensed .header-left .topnav > ul > li {
    left: -50px;
  }
  .topbar .large-dropdown-menu {
    min-width: 160px;
  }
  .topbar .header-left .nav-icons {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  .sidebar-hover .topbar .header-right .header-menu #language-header {
    display: none;
  }
  .sidebar-hover .topnav .logopanel2 {
    width: 150px !important;
  }
}
@media screen and (max-width: 610px) {
  .topbar .header-right .header-menu #notifications-header .dropdown-menu {
    left: -50px;
  }
  .topbar .header-right .header-menu #messages-header .dropdown-menu {
    left: -120px;
  }
  .topbar .header-right .header-menu .dropdown-menu:after {
    display: none;
  }
  .sidebar-hover .topbar .header-right .header-menu #notifications-header {
    display: none;
  }
}
@media screen and (max-width: 550px) {
  .topbar .header-right .header-menu #language-header {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .topbar .header-right .header-menu #messages-header {
    display: none;
  }
}
@media screen and (max-width: 430px) {
  .topbar .header-right .header-menu #notifications-header {
    display: none;
  }
}
.sidebar {
  background-color: #2b2e33;
  left: 0;
  position: absolute;
  top: 50px;
  bottom: 0;
  width: 240px;
  z-index: 100;
}
.sidebar .nav > li + li {
  margin-top: 0;
  margin-bottom: 3px;
}
.sidebar .badge {
  font-family: verdana,arial;
  font-size: 11px;
  margin-top: 4px;
}
.sidebar .ui-resizable-handle {
  right: 0;
}
.sidebar .logopanel {
  background: #202226;
  height: 50px;
  left: 0;
  padding: 10px;
  position: fixed;
  right: auto;
  top: 0;
  width: 240px;
  z-index: 2;
  -webkit-transition: color 0.2s ease-out;
  -moz-transition: color 0.2s ease-out;
  -o-transition: color 0.2s ease-out;
  -ms-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out;
}
.sidebar .logopanel h1 {
  color: #ffffff;
  font-family: 'Lato', 'Open Sans', Helvetica, sans-serif;
  font-weight: 900;
  font-size: 30px;
  letter-spacing: -1.5px;
  line-height: 31px;
  margin: 0;
}
.sidebar .logopanel h1 a {
  background: url(../images/logo/logo-white.png) no-repeat;
  display: block;
  height: 31px;
}
.sidebar .logopanel h1 span {
  color: #E6E7E8;
}
.sidebar .logopanel h1 img {
  margin-left: 10px;
  margin-top: -4px;
}
.sidebar .sidebar-inner {
  background-color: #2b2e33;
  height: 100%;
  padding: 0;
  z-index: 2;
}
.sidebar .sidebar-inner .menu-title {
  font-size: 12px;
  margin-bottom: 0;
  padding: 20px 20px 15px 20px;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  text-transform: uppercase;
}
.sidebar .sidebar-inner .menu-title a {
  color: #B9B9B9;
  text-decoration: none !important;
}
.sidebar .sidebar-inner .menu-title a:hover {
  color: #ffffff;
}
.sidebar .sidebar-inner .menu-title a.hide-widget {
  color: #ffffff;
  font-size: 7px;
  opacity: 0.3;
  position: relative;
}
.sidebar .sidebar-inner .menu-title a.hide-widget:hover {
  opacity: 0.7;
}
.sidebar .sidebar-inner .menu-title a.hide-widget i {
  -moz-transition-property: -moz-transform;
  -moz-transition-timing-function: linear;
  -webkit-transition-property: -webkit-transform;
  -webkit-transition-timing-function: linear;
  transition-property: transform;
  transition-timing-function: linear;
  position: absolute;
  right: 0;
  top: 0;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
.sidebar .sidebar-inner .menu-title a.hide-widget.widget-hidden i {
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sidebar .sidebar-inner .menu-title .menu-settings {
  background: none !important;
  position: relative;
}
.sidebar .sidebar-inner .menu-title .menu-settings a {
  background: none !important;
  position: relative;
}
.sidebar .sidebar-inner .menu-title .menu-settings .dropdown-menu {
  -moz-border-radius: 0 0 3px 3px;
  -webkit-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
  margin-top: 2px;
}
.sidebar .sidebar-inner .menu-title .menu-settings .dropdown-menu > li > a {
  color: #666666;
  font-size: 13px;
  padding: 6px 10px;
  text-transform: none;
}
.sidebar .sidebar-inner .menu-title .menu-settings .dropdown-menu > li > a:hover {
  background: #DFE1E3 !important;
  color: #121212 !important;
}
.sidebar .sidebar-inner .menu-title.widget-title {
  padding: 10px 0;
}
.sidebar .sidebar-inner .nav-sidebar {
  margin-bottom: 30px;
}
.sidebar .sidebar-inner .nav-sidebar .arrow {
  font-size: 14px !important;
  margin-right: 0 !important;
  margin-top: 5px;
  opacity: 0.4 !important;
  position: absolute;
  right: 20px;
}
.sidebar .sidebar-inner .nav-sidebar .arrow:before {
  content: "\f105";
  display: inline-block;
  -webkit-transition: transform 0.15s linear;
  -moz-transition: transform 0.15s linear;
  -o-transition: transform 0.15s linear;
  -ms-transition: transform 0.15s linear;
  transition: transform 0.15s linear;
}
.sidebar .sidebar-inner .nav-sidebar .arrow:not(.fa) before {
  content: "s";
  display: inline-block;
  float: right;
  font-family: "line-icons" !important;
  font-size: 10px;
  -webkit-transition: transform 0.15s linear;
  -moz-transition: transform 0.15s linear;
  -o-transition: transform 0.15s linear;
  -ms-transition: transform 0.15s linear;
  transition: transform 0.15s linear;
}
.sidebar .sidebar-inner .nav-sidebar .menu-txt {
  display: inline-block;
  height: 15px;
}
.sidebar .sidebar-inner .nav-sidebar li > a .fa.arrow {
  padding-left: 2px;
  padding-right: 0;
  width: 10px;
}
.sidebar .sidebar-inner .nav-sidebar li .fa {
  font-size: 16px;
}
.sidebar .sidebar-inner .nav-sidebar li .icons-arrows-06 {
  font-size: 16px;
}
.sidebar .sidebar-inner .nav-sidebar li i {
  float: left;
  margin-right: 10px;
  padding-top: 3px;
  text-align: left;
}
.sidebar .sidebar-inner .nav-sidebar .nav-parent .children {
  background: #202226;
  display: none;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  visibility: visible;
}
.sidebar .sidebar-inner .nav-sidebar .nav-parent .icons-arrows-06 {
  float: right;
  margin-right: 0;
  -webkit-transition: transform 0.15s linear;
  -moz-transition: transform 0.15s linear;
  -o-transition: transform 0.15s linear;
  -ms-transition: transform 0.15s linear;
  transition: transform 0.15s linear;
}
.sidebar .sidebar-inner .nav-sidebar .nav-parent .icons-arrows-06:before {
  content: "\f105";
  font-family: "FontAwesome" !important;
  -webkit-transition: transform 0.15s linear;
  -moz-transition: transform 0.15s linear;
  -o-transition: transform 0.15s linear;
  -ms-transition: transform 0.15s linear;
  transition: transform 0.15s linear;
}
.sidebar .sidebar-inner .nav-sidebar .nav-parent.active .children {
  display: block;
}
.sidebar .sidebar-inner .nav-sidebar .nav-parent.active .icons-arrows-06:before {
  float: right;
  transform: rotate(90deg);
}
.sidebar .sidebar-inner .nav-sidebar .children > li > a {
  color: #94989d;
  display: block;
  font-size: 13px;
  height: 34px;
  line-height: 24px;
  overflow: hidden;
  padding: 7px 20px 7px 49px;
  font-family: "Open Sans", arial;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.sidebar .sidebar-inner .nav-sidebar .children > li > a:hover {
  color: #ffffff;
  text-decoration: none;
}
.sidebar .sidebar-inner .nav-sidebar .children > li > a > span:not(.badge) {
  margin-top: 2px;
}
.sidebar .sidebar-inner .nav-sidebar .children > li.active > a {
  color: #ffffff;
}
.sidebar .sidebar-inner .nav-sidebar .children > li .fa {
  font-size: 12px;
  margin-right: 5px;
  opacity: .5;
  text-align: left;
  vertical-align: baseline;
  width: auto;
}
.sidebar .sidebar-inner .nav-sidebar .children ul {
  border: 0;
  margin-left: 12px;
}
.sidebar .sidebar-inner .nav-sidebar .children:after {
  border-left: 1px solid #2b2e33;
  bottom: 0;
  content: "";
  left: 27px;
  position: absolute;
  top: 0;
}
.sidebar .sidebar-inner .nav-sidebar .children li:before {
  background: #2b2e33;
  bottom: auto;
  content: "";
  height: 8px;
  left: 23px;
  margin-top: 15px;
  position: absolute;
  right: auto;
  width: 8px;
  z-index: 1;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.sidebar .sidebar-inner .nav-sidebar .children > li.hidden-item {
  -moz-opacity: 0;
  -webkit-opacity: 0;
  filter: alpha(opacity=0);
  opacity: 0;
  visibility: hidden;
  zoom: 1;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.sidebar .sidebar-inner .nav-sidebar .children > li.hidden-item:before {
  -moz-opacity: 0;
  -webkit-opacity: 0;
  filter: alpha(opacity=0);
  opacity: 0;
  visibility: hidden;
  zoom: 1;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.sidebar .sidebar-inner .nav-sidebar .children > li.is-hidden {
  -moz-opacity: 0;
  -webkit-opacity: 0;
  filter: alpha(opacity=0);
  margin-left: -20px;
  opacity: 0;
  zoom: 1;
}
.sidebar .sidebar-inner .nav-sidebar .children > li.is-hidden:before {
  -moz-opacity: 0;
  -webkit-opacity: 0;
  filter: alpha(opacity=0);
  margin-left: -20px;
  opacity: 0;
  zoom: 1;
}
.sidebar .sidebar-inner .nav-sidebar .children > li.is-shown {
  -moz-opacity: 1;
  -moz-transition-delay: 0;
  -o-transition-delay: 0;
  -webkit-opacity: 1;
  -webkit-transition-delay: 0;
  filter: alpha(opacity=100);
  margin-left: 0;
  opacity: 1;
  transition-delay: 0;
  zoom: 1;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.sidebar .sidebar-inner .nav-sidebar .children > li.is-shown:before {
  -moz-opacity: 1;
  -webkit-opacity: 1;
  filter: alpha(opacity=100);
  margin-left: 0;
  opacity: 1;
  zoom: 1;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.sidebar .sidebar-inner .nav-sidebar .children > li.is-shown:nth-child(1) {
  -moz-transition-delay: 40ms;
  -webkit-transition-delay: 40ms;
  -o-transition-delay: 40ms;
  transition-delay: 40ms;
}
.sidebar .sidebar-inner .nav-sidebar .children > li.is-shown:nth-child(2) {
  -moz-transition-delay: 60ms;
  -webkit-transition-delay: 60ms;
  -o-transition-delay: 60ms;
  transition-delay: 60ms;
}
.sidebar .sidebar-inner .nav-sidebar .children > li.is-shown:nth-child(3) {
  -moz-transition-delay: 80ms;
  -webkit-transition-delay: 80ms;
  -o-transition-delay: 80ms;
  transition-delay: 80ms;
}
.sidebar .sidebar-inner .nav-sidebar .children > li.is-shown:nth-child(4) {
  -moz-transition-delay: 100ms;
  -webkit-transition-delay: 100ms;
  -o-transition-delay: 100ms;
  transition-delay: 100ms;
}
.sidebar .sidebar-inner .nav-sidebar .children > li.is-shown:nth-child(5) {
  -moz-transition-delay: 120ms;
  -webkit-transition-delay: 120ms;
  -o-transition-delay: 120ms;
  transition-delay: 120ms;
}
.sidebar .sidebar-inner .nav-sidebar .children > li.is-shown:nth-child(6) {
  -moz-transition-delay: 140ms;
  -webkit-transition-delay: 140ms;
  -o-transition-delay: 140ms;
  transition-delay: 140ms;
}
.sidebar .sidebar-inner .nav-sidebar .children > li.is-shown:nth-child(7) {
  -moz-transition-delay: 160ms;
  -webkit-transition-delay: 160ms;
  -o-transition-delay: 160ms;
  transition-delay: 160ms;
}
.sidebar .sidebar-inner .nav-sidebar .children > li.is-shown:nth-child(8) {
  -moz-transition-delay: 180ms;
  -webkit-transition-delay: 180ms;
  -o-transition-delay: 180ms;
  transition-delay: 180ms;
}
.sidebar .sidebar-inner .nav-sidebar .children > li.is-shown:nth-child(9) {
  -moz-transition-delay: 200ms;
  -webkit-transition-delay: 200ms;
  -o-transition-delay: 200ms;
  transition-delay: 200ms;
}
.sidebar .sidebar-inner .nav-sidebar .children > li.is-shown:nth-child(10) {
  -moz-transition-delay: 220ms;
  -webkit-transition-delay: 220ms;
  -o-transition-delay: 220ms;
  transition-delay: 220ms;
}
.sidebar .sidebar-inner .nav-sidebar .children > li.is-shown:nth-child(11) {
  -moz-transition-delay: 240ms;
  -webkit-transition-delay: 240ms;
  -o-transition-delay: 240ms;
  transition-delay: 240ms;
}
.sidebar .sidebar-inner .nav-sidebar .children > li.is-shown:nth-child(12) {
  -moz-transition-delay: 260ms;
  -webkit-transition-delay: 260ms;
  -o-transition-delay: 260ms;
  transition-delay: 260ms;
}
.sidebar .sidebar-inner .nav-sidebar .children > li.is-shown:nth-child(13) {
  -moz-transition-delay: 280ms;
  -webkit-transition-delay: 280ms;
  -o-transition-delay: 280ms;
  transition-delay: 280ms;
}
.sidebar .sidebar-inner .nav-sidebar .children > li.is-shown:nth-child(14) {
  -moz-transition-delay: 300ms;
  -webkit-transition-delay: 300ms;
  -o-transition-delay: 300ms;
  transition-delay: 300ms;
}
.sidebar .sidebar-inner .nav-sidebar > li:not(.nav-active) > a:hover {
  background: none;
}
.sidebar .sidebar-inner .nav-sidebar > li > a {
  border-radius: 0;
  color: #ffffff;
  display: block;
  font-family: 'Open Sans';
  font-size: 13px;
  letter-spacing: 0.6px;
  line-height: 21px;
  overflow: hidden;
  padding: 7px 20px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.transition(color,.2s, ease-out) .sidebar .sidebar-inner .nav-sidebar > li > a:hover {
  color: #fff;
}
.transition(color,.2s, ease-out) .sidebar .sidebar-inner .nav-sidebar > li > a:hover i {
  opacity: 1;
}
.transition(color,.2s, ease-out) .sidebar .sidebar-inner .nav-sidebar > li > a:hover span {
  opacity: 1;
}
.sidebar .sidebar-inner .nav-sidebar > li > a i {
  opacity: 0.62;
}
.sidebar .sidebar-inner .nav-sidebar > li > a span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sidebar .sidebar-inner .nav-sidebar > li > a span:not(.badge) {
  opacity: 0.62;
}
.sidebar .sidebar-inner .nav-sidebar > li > a span:not(.badge) > span {
  opacity: 1;
}
.sidebar .sidebar-inner .nav-sidebar > li.nav-parent > a:focus {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  background-color: none;
  box-shadow: none;
}
.sidebar .sidebar-inner .nav-sidebar.ui-sortable li a {
  cursor: move;
}
.sidebar .sidebar-inner .nav-sidebar.ui-sortable li i:before {
  content: "\e067" !important;
  font-family: 'Simple-Line-Icons' !important;
}
.sidebar .sidebar-inner .nav-sidebar.remove-menu li i {
  font-size: 13px;
}
.sidebar .sidebar-inner .nav-sidebar.remove-menu li i:before {
  content: "\e126" !important;
  font-family: "line-icons" !important;
}
.sidebar .sidebar-inner .nav-sidebar.remove-menu li:hover {
  cursor: pointer;
}
.sidebar .sidebar-inner .nav-sidebar.remove-menu li:hover i {
  color: #FF6666 !important;
}
.sidebar .sidebar-inner .nav-sidebar.remove-menu li:hover > a {
  background-color: rgba(0, 0, 0, 0.1) !important;
  color: #ffffff;
}
.sidebar .sidebar-inner .nav-sidebar.remove-menu a {
  pointer-events: none;
}
.sidebar .sidebar-inner .nav-sidebar > li.active > a {
  background-color: #319db5;
  color: #ffffff;
  opacity: 1;
}
.sidebar .sidebar-inner .nav-sidebar > li.active > a:focus {
  color: #ffffff;
}
.sidebar .sidebar-inner .nav-sidebar > li.nav-parent.active > a:hover {
  background-color: #319db5;
  color: #ffffff !important;
}
.sidebar .sidebar-inner .nav-sidebar-placeholder {
  background-color: rgba(0, 0, 0, 0.1) !important;
  visibility: visible !important;
}
.sidebar .sidebar-inner .sidebar-widgets {
  display: block;
  margin-bottom: 100px;
  padding: 0 20px 7px;
  position: relative;
}
.sidebar .sidebar-inner .sidebar-widgets .icon-picture {
  color: #E67373;
}
.sidebar .sidebar-inner .sidebar-widgets .icon-lock {
  color: #A6A6A6;
}
.sidebar .sidebar-inner .sidebar-widgets ul.folders {
  list-style: none;
  margin: 0px;
  overflow: hidden;
  padding-left: 0;
}
.sidebar .sidebar-inner .sidebar-widgets ul.labels {
  list-style: none;
  margin: 0px;
  overflow: hidden;
  padding-left: 0;
}
.sidebar .sidebar-inner .sidebar-widgets a {
  color: #ffffff;
  display: block;
  font-family: 'Open Sans';
  font-size: 13px;
  letter-spacing: 0.4px;
  position: relative;
}
.sidebar .sidebar-inner .sidebar-widgets a:hover {
  text-decoration: none !important;
}
.sidebar .sidebar-inner .sidebar-widgets ul a {
  background: none !important;
  overflow: hidden;
  padding: 5px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sidebar .sidebar-inner .sidebar-widgets ul i {
  padding-right: 12px;
}
.sidebar .sidebar-inner .sidebar-widgets a.new-folder {
  background: none !important;
  color: #949494;
}
.sidebar .sidebar-inner .sidebar-widgets a.new-folder:hover {
  color: #D1D1D1;
}
.sidebar .sidebar-inner .sidebar-widgets a.new-label {
  background: none !important;
  color: #949494;
}
.sidebar .sidebar-inner .sidebar-widgets a.new-label:hover {
  color: #D1D1D1;
}
.sidebar .sidebar-inner .sidebar-widgets .add-folder {
  display: none;
}
.sidebar .sidebar-inner .sidebar-widgets .add-folder input {
  margin-top: 8px;
}
.sidebar .sidebar-inner .sidebar-widgets .add-label {
  display: none;
}
.sidebar .sidebar-inner .sidebar-widgets .add-label input {
  margin-top: 8px;
}
.sidebar .sidebar-inner .sidebar-widgets .charts-sidebar .sidebar-charts-inner {
  height: 53px;
  padding: 15px 0;
}
.sidebar .sidebar-inner .sidebar-widgets .charts-sidebar .sidebar-charts-inner .sidebar-charts-left {
  float: left;
  margin-top: -7px;
  text-align: left;
}
.sidebar .sidebar-inner .sidebar-widgets .charts-sidebar .sidebar-charts-inner .sidebar-charts-left .sidebar-chart-title {
  color: rgba(255, 255, 255, 0.3);
  font-size: 11px;
  opacity: 0.3;
  text-transform: uppercase;
}
.sidebar .sidebar-inner .sidebar-widgets .charts-sidebar .sidebar-charts-inner .sidebar-charts-left .sidebar-chart-number {
  color: #ffffff;
  font-family: 'Lato';
  font-size: 18px;
  margin-top: 0;
  margin-top: 9px;
  opacity: 0.7;
}
.sidebar .sidebar-inner .sidebar-widgets .charts-sidebar .sidebar-charts-inner .sidebar-charts-right {
  float: right;
  text-align: right;
}
.sidebar .sidebar-inner .sidebar-widgets .charts-sidebar hr.divider {
  background: -moz-linear-gradient(left, rgba(205, 205, 205, 0), #4d4d4d, rgba(205, 205, 205, 0));
  background: -o-linear-gradient(left, rgba(205, 205, 205, 0), #4d4d4d, rgba(205, 205, 205, 0));
  background: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, rgba(205, 205, 205, 0)), color-stop(50%, #4d4d4d), color-stop(100%, rgba(205, 205, 205, 0)));
  background: -webkit-linear-gradient(left, rgba(205, 205, 205, 0), #4d4d4d, rgba(205, 205, 205, 0));
  background: linear-gradient(left, rgba(205, 205, 205, 0), #4d4d4d, rgba(205, 205, 205, 0));
  border: 0;
  height: 1px;
  margin-bottom: 0;
  margin-top: 0;
  width: 100%;
}
.sidebar .sidebar-inner .sidebar-widgets .charts-sidebar.progress-chart .sidebar-charts-inner {
  height: 45px;
  padding-top: 0;
}
.sidebar .sidebar-inner .sidebar-widgets .charts-sidebar.progress-chart .sidebar-chart-title {
  color: rgba(255, 255, 255, 0.6);
  float: left;
  font-family: 'Open Sans';
  font-size: 13px;
  margin-bottom: 5px;
  margin-top: 0;
  opacity: 1;
  text-transform: none;
}
.sidebar .sidebar-inner .sidebar-widgets .charts-sidebar.progress-chart .sidebar-chart-number {
  color: rgba(255, 255, 255, 0.3);
  float: right;
  font-size: 11px;
  margin-top: 2px;
}
.sidebar .sidebar-inner .sidebar-widgets .charts-sidebar.progress-chart .progress {
  background: rgba(255, 255, 255, 0.2);
  height: 6px;
}
.sidebar .sidebar-inner .sidebar-footer {
  background-color: #202226;
  bottom: 0px;
  clear: both;
  display: block;
  left: 0;
  padding: 0;
  position: fixed;
  width: 240px;
  z-index: 1000;
}
.sidebar .sidebar-inner .sidebar-footer .progress {
  margin-bottom: 5px;
  position: relative;
  top: 15px;
  width: 70%;
}
.sidebar .sidebar-inner .sidebar-footer .pull-left {
  padding: 12px 0 8px;
  text-align: center;
  width: 25%;
}
.sidebar .sidebar-inner .sidebar-footer .pull-left:hover {
  background-color: #373a41;
  text-decoration: none !important;
}
.sidebar .sidebar-inner .sidebar-footer i {
  color: #5E646D;
  font-size: 14px;
}
.sidebar .sidebar-inner .sidebar-footer a:hover i {
  color: #F7F7F7;
}
.sidebar .sidebar-inner .sidebar-top h4 {
  color: #e2e2e2;
  margin-bottom: 5px;
  overflow: hidden;
  padding-left: 3px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 137px;
}
.sidebar .sidebar-inner .sidebar-top .searchform {
  display: block;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 5px 15px 10px 15px;
  position: relative;
}
.sidebar .sidebar-inner .sidebar-top .searchform::after {
  clear: both;
  content: '';
  display: block;
}
.sidebar .sidebar-inner .sidebar-top .searchform input {
  background: #202226;
  border: 0;
  height: auto;
  margin: 0;
  padding: 10px;
  width: 100%;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.sidebar .sidebar-inner .sidebar-top .searchform input:focus {
  background: #ffffff;
}
.sidebar .sidebar-inner .sidebar-top .userlogged {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: block;
  margin-bottom: 0;
  margin: 0;
  padding: 5px 10px 0 10px;
}
.sidebar .sidebar-inner .sidebar-top .userlogged > i {
  float: left;
  font-size: 60px;
  margin-bottom: -21px;
  padding-right: 12px;
}
.sidebar .sidebar-inner .sidebar-top .user-details {
  float: left;
  padding-left: 5px;
  text-align: left;
}
.sidebar .sidebar-inner .sidebar-top .user-details .user-login .btn {
  margin-right: 0;
}
.sidebar .sidebar-inner .sidebar-top .user-login {
  display: inline-block;
  float: none;
  margin-left: 0;
  margin-top: 0;
  margin: auto;
  text-align: center;
}
.sidebar .sidebar-inner .sidebar-top .user-login .btn {
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #616574;
  margin-right: 0;
  padding: 4px 8px;
}
.sidebar .sidebar-inner .sidebar-top .user-login .btn i {
  margin-top: 0 !important;
}
.sidebar .sidebar-inner .sidebar-top .user-login .btn i:not(.fa) {
  margin-top: 3px !important;
}
.sidebar .sidebar-inner .sidebar-top .user-login .btn .fa {
  padding-left: 3px;
}
.sidebar .sidebar-inner .sidebar-top .user-login .dropdown-menu {
  margin-top: 3px;
  min-width: 100px;
  width: 100%;
}
.sidebar .sidebar-inner .sidebar-top .user-login .dropdown-menu li a {
  color: #616574;
  font-size: 12px;
  padding: 4px 8px;
}
.sidebar .sidebar-inner .sidebar-top .user-login .dropdown-menu li a:hover {
  background-color: #616574;
  color: #ffffff;
}
.sidebar .sidebar-inner .sidebar-top .user-login .dropdown-menu li a i {
  padding-right: 0;
}
.sidebar .sidebar-inner .sidebar-top.big-img {
  margin-bottom: 0;
  padding-top: 10px;
  text-align: center;
}
.sidebar .sidebar-inner .sidebar-top.big-img .user-image {
  margin: auto;
  width: 130px;
}
.sidebar .sidebar-inner .sidebar-top.big-img .user-image img {
  border: 3px solid rgba(255, 255, 255, 0.2);
  display: block;
}
.sidebar .sidebar-inner .sidebar-top.big-img h4 {
  color: #e2e2e2;
  margin: auto;
  margin-bottom: 8px;
  margin-top: 10px;
  text-align: center;
}
.sidebar .sidebar-inner .sidebar-top.big-img .user-details {
  float: none;
  padding-left: 0;
  text-align: center;
}
.sidebar .sidebar-inner .sidebar-top.small-img {
  margin-bottom: 0;
  margin-top: 0;
  padding: 20px;
  padding-bottom: 0;
  text-align: center;
}
.sidebar .sidebar-inner .sidebar-top.small-img .user-image {
  float: left;
  margin: auto;
  width: 35%;
}
.sidebar .sidebar-inner .sidebar-top.small-img .user-image img {
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: block;
}
.sidebar .sidebar-inner .sidebar-top.small-img h4 {
  color: #e2e2e2;
  text-align: left;
  width: 110px;
}
.sidebar .sidebar-inner .sidebar-top.small-img .user-details {
  float: left;
  padding-left: 10px;
  text-align: left;
}
.sidebar .sidebar-top .user-login .dropdown-menu li a:focus {
  background-color: #616574;
  color: #ffffff;
}
body:not(.sidebar-fixed, .topbar-fixed) .sidebar .logopanel {
  background: #202226;
  height: 50px;
  left: 0;
  padding: 10px;
  position: absolute;
  right: auto;
  top: -50px;
  width: 240px;
  z-index: 2;
}
body:not(.rtl) .sidebar .sidebar-inner .nav-sidebar li.active > a .fa.arrow:before {
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
body:not(.rtl) .sidebar .sidebar-inner .nav-sidebar .nav-parent > a .fa.arrow.active:before {
  content: "\f105";
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.nav-sidebar > li > a:active {
  background: none;
  color: #e2e2e2;
}
.nav-sidebar > li > a:focus {
  background: none;
  color: #e2e2e2;
}
.sidebar .sidebar-inner .nav-sidebar > li.active > a i,
.sidebar .sidebar-inner .nav-sidebar > li.active > a span:not(.badge),
.sidebar .sidebar-inner .nav-sidebar > li.nav-active > a i,
.sidebar .sidebar-inner .nav-sidebar > li.nav-active > a span:not(.badge) {
  opacity: 1;
}
.sidebar.c-dark .sidebar-inner .nav-sidebar > li > a {
  color: #121212 !important;
}
.sidebar .sidebar-inner .nav-sidebar > li.nav-parent > a:hover,
.sidebar .sidebar-inner .nav-sidebar > li > a:hover,
.nav-sidebar > li > a:active,
.nav-sidebar > li > a:focus {
  color: #fff;
  opacity: 1 !important;
}
body:not(.sidebar-collapsed) .sidebar .sidebar-inner .nav-sidebar > li.nav-parent:not(.active) > a:hover,
.sidebar .sidebar-inner .nav-sidebar > li:not(.active) > a:hover,
.nav-sidebar > li > a:active,
.nav-sidebar > li > a:focus {
  background: none;
}
.nav-sidebar > li.active > a:hover {
  background-color: #319db5;
  color: #ffffff;
  opacity: 1;
}
.nav-sidebar > li.active > a:focus {
  background-color: #319db5;
  color: #ffffff;
  opacity: 1;
}
.nav-sidebar > li.nav-parent.active > a:active {
  background-color: #319db5;
  color: #ffffff !important;
}
.nav-sidebar .children > li > a:active {
  color: #ffffff;
  text-decoration: none;
}
.nav-sidebar .children > li > a:focus {
  color: #ffffff;
  text-decoration: none;
}
.sidebar-widgets a:active {
  text-decoration: none !important;
}
.sidebar-widgets a:focus {
  text-decoration: none !important;
}
.progress-chart .sidebar-chart-number {
  font-family: 'Open Sans';
  font-size: 13px;
  margin-bottom: 5px;
  margin-top: 0;
  opacity: 1;
  text-transform: none;
}
.theme-sltl .sidebar .sidebar-inner .sidebar-widgets .charts-sidebar.progress-chart .sidebar-chart-title,
.theme-sltl .sidebar .sidebar-inner .sidebar-widgets .charts-sidebar.progress-chart .sidebar-chart-number,
.theme-sltd .sidebar .sidebar-inner .sidebar-widgets .charts-sidebar.progress-chart .sidebar-chart-title,
.theme-sltd .sidebar .sidebar-inner .sidebar-widgets .charts-sidebar.progress-chart .sidebar-chart-number,
.theme-sltl .sidebar .sidebar-inner .sidebar-widgets .charts-sidebar .sidebar-chart-number,
.theme-sltl .sidebar .sidebar-inner .sidebar-widgets .charts-sidebar .sidebar-chart-title {
  color: rgba(0, 0, 0, 0.6);
}
.sidebar-footer .pull-left:active {
  background-color: #373a41;
  text-decoration: none !important;
}
.sidebar-footer .pull-left:focus {
  background-color: #373a41;
  text-decoration: none !important;
}
.sidebar-condensed .sidebar {
  top: 50px;
}
.sidebar-condensed:not(.sidebar-collapsed) .sidebar {
  width: 190px;
}
.sidebar-condensed:not(.sidebar-collapsed) .sidebar .sidebar-inner .sidebar-footer {
  width: 190px;
}
.sidebar-condensed:not(.sidebar-collapsed) .sidebar .sidebar-inner .nav-sidebar > li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.sidebar-condensed:not(.sidebar-collapsed) .sidebar .sidebar-inner .nav-sidebar > li:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}
.sidebar-condensed:not(.sidebar-collapsed) .sidebar .sidebar-inner .nav-sidebar > li > a span {
  display: block;
  float: none;
  text-align: center;
}
.sidebar-condensed:not(.sidebar-collapsed) .sidebar .sidebar-inner .nav-sidebar > li > a i {
  font-size: 24px;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 8px;
}
.sidebar-condensed:not(.sidebar-collapsed) .sidebar .sidebar-inner .nav-sidebar > li > a .arrow {
  opacity: 0.3;
  position: absolute;
  top: 28px;
}
.sidebar-condensed:not(.sidebar-collapsed) .sidebar .sidebar-top {
  margin-bottom: 0;
  text-align: center;
}
.sidebar-condensed:not(.sidebar-collapsed) .sidebar .sidebar-top .user-image {
  margin: auto;
  width: 90px;
}
.sidebar-condensed:not(.sidebar-collapsed) .sidebar .sidebar-top .user-image img {
  border: 3px solid rgba(255, 255, 255, 0.2);
  display: block;
}
.sidebar-condensed:not(.sidebar-collapsed) .sidebar .sidebar-top h4 {
  color: #e2e2e2;
  padding-left: 5px;
  text-align: left;
  width: 105px;
}
.sidebar-condensed:not(.sidebar-collapsed) .sidebar .sidebar-top .user-login {
  display: inline-block;
  float: none;
  margin: auto;
  text-align: center;
}
.sidebar-condensed:not(.sidebar-collapsed) .sidebar .sidebar-top .user-login .btn {
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid #343847;
  color: #616574;
  padding: 4px 8px;
}
.sidebar-condensed:not(.sidebar-collapsed) .sidebar .sidebar-top .user-login .btn i {
  margin-top: 0 !important;
}
.sidebar-condensed:not(.sidebar-collapsed) .sidebar .sidebar-top .user-login .btn i:not(.fa) {
  margin-top: 3px !important;
}
.sidebar-condensed:not(.sidebar-collapsed) .sidebar .sidebar-top .user-login .btn .fa {
  padding-left: 3px;
}
.sidebar-condensed:not(.sidebar-collapsed) .sidebar .sidebar-top .user-login .dropdown-menu {
  margin-top: 3px;
  min-width: 100%;
  width: 100%;
}
.sidebar-condensed:not(.sidebar-collapsed) .sidebar .sidebar-top .user-login .dropdown-menu li a {
  color: #616574;
  font-size: 12px;
  padding: 4px 8px;
}
.sidebar-condensed:not(.sidebar-collapsed) .sidebar .sidebar-top .user-login .dropdown-menu li a:hover {
  background-color: #616574;
  color: #ffffff;
}
.sidebar-condensed:not(.sidebar-collapsed) .sidebar .sidebar-top .user-login .dropdown-menu li a i {
  padding-right: 0;
}
.sidebar-condensed:not(.sidebar-collapsed) .sidebar .sidebar-top.small-img .user-image {
  margin: auto;
  width: 55px;
}
.sidebar-condensed:not(.sidebar-collapsed) .sidebar .sidebar-top.small-img .user-image img {
  border: 2px solid rgba(255, 255, 255, 0.2);
}
.sidebar-condensed:not(.sidebar-collapsed) .sidebar .sidebar-top.big-img .user-image {
  margin: auto;
  width: 120px;
}
.sidebar-condensed:not(.sidebar-collapsed) .main-content {
  margin-left: 190px;
}
.sidebar-condensed:not(.sidebar-collapsed) .main-content .topbar {
  left: 190px;
}
.sidebar-condensed .sidebar .logopanel {
  width: 190px;
}
.sidebar-condensed .sidebar .sidebar-top .user-login .dropdown-menu li a:focus {
  background-color: #616574;
  color: #ffffff;
}
.sidebar-condensed .sidebar .sidebar-inner .nav-sidebar > li > a i {
  display: block;
  float: none;
  text-align: center;
}
.sidebar-condensed .sidebar .sidebar-inner .sidebar-top h4 {
  font-size: 15px;
}
.sidebar-condensed .sidebar .sidebar-inner .sidebar-top .user-details {
  padding-left: 5px;
}
.sidebar-condensed .sidebar .sidebar-inner .sidebar-top .userlogged > i {
  padding-right: 0;
}
.sidebar-condensed .sidebar .sidebar-inner .sidebar-inner .sidebar-top .userlogged > i {
  padding-right: 0px;
}
.sidebar-condensed .sidebar .sidebar-inner .sidebar-top.small-img {
  padding: 10px;
}
.sidebar-condensed .sidebar .sidebar-inner .sidebar-top.small-img h4 {
  font-size: 14px;
  width: 95px;
}
.sidebar-condensed .sidebar .sidebar-top.small-img .user-login {
  margin-left: -5px;
  margin-right: 0;
}
.sidebar-light:not(.sidebar-collapsed) .sidebar {
  background: none !important;
  width: 260px;
}
.sidebar-light:not(.sidebar-collapsed) .sidebar .sidebar-top h4 {
  width: 115px;
}
.sidebar-light:not(.sidebar-collapsed) .sidebar .sidebar-inner {
  background-color: rgba(0, 0, 0, 0.05);
  margin: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.sidebar-light:not(.sidebar-collapsed) .sidebar .sidebar-inner .nav-sidebar {
  margin-bottom: 0;
}
.sidebar-light:not(.sidebar-collapsed) .sidebar .sidebar-inner .nav-sidebar .arrow {
  right: 10px;
}
.sidebar-light:not(.sidebar-collapsed) .sidebar .sidebar-inner .nav-sidebar > li > a {
  line-height: 23px;
  padding: 10px;
}
.sidebar-light:not(.sidebar-collapsed) .sidebar .sidebar-inner .nav-sidebar > li > a i {
  font-size: 15px;
}
.sidebar-light:not(.sidebar-collapsed) .sidebar .sidebar-inner .sidebar-top .searchform {
  display: none;
}
.sidebar-light:not(.sidebar-collapsed) .sidebar .sidebar-inner .sidebar-widgets {
  margin-bottom: 0;
}
.sidebar-light:not(.sidebar-collapsed) .sidebar .sidebar-footer {
  display: none;
}
.sidebar-light:not(.sidebar-collapsed) .sidebar-top {
  padding-top: 10px;
}
.sidebar-light .sidebar .sidebar-inner .sidebar-top.small-img {
  padding: 10px;
}
.sidebar-light .sidebar .sidebar-inner .sidebar-top.small-img h4 {
  font-size: 14px;
}
.sidebar-light .sidebar .sidebar-inner .sidebar-top.small-img .user-login {
  margin-left: -5px;
  margin-right: 0;
}
.sidebar-light.sidebar-top:not(.sidebar-collapsed) .sidebar {
  width: 100%;
}
.sidebar-light.sidebar-top:not(.sidebar-collapsed) .sidebar .sidebar-inner {
  background-color: transparent;
  margin: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.sidebar-light.sidebar-top:not(.sidebar-collapsed) .sidebar .sidebar-inner .nav-sidebar > li > a {
  height: 66px;
  line-height: 21px;
  padding: 10px 15px 12px;
}
.sidebar-light.fixed-sidebar:not(.sidebar-collapsed) .sidebar-inner {
  padding-bottom: 80px;
}
.sidebar-hover .sidebar {
  margin-left: -220px;
  margin-right: auto;
  opacity: 0;
  top: 0 !important;
  z-index: 500 !important;
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.sidebar-hover .sidebar .sidebar-inner .sidebar-top.small-img {
  margin-top: 40px;
}
.sidebar-hover .sidebar .sidebar-inner .sidebar-footer {
  left: -220px;
  opacity: 0;
}
.sidebar-hover .sidebar .logopanel {
  position: absolute;
}
.sidebar-hover .sidebar-inner {
  margin-top: 50px;
}
.sidebar-hover .sidebar.sidebar-inner .sidebar-top.big-img {
  margin-top: 48px;
}
.sidebar-hover .main-content {
  margin-left: 0;
}
.sidebar-hover .topbar .header-left .topnav .menutoggle {
  display: none;
}
.sidebar-hover .topnav .logopanel2 {
  background: #ffffff;
  color: #666666;
  float: left;
  height: 49px;
  padding: 10px 10px 10px 40px;
  width: 240px;
}
.sidebar-hover .topnav .logopanel2 h1 {
  font-family: 'Lato', 'Open Sans', Helvetica, sans-serif;
  font-weight: 900;
  font-size: 30px;
  letter-spacing: -1.5px;
  line-height: 30px;
}
.sidebar-hover .logopanel2 h1 a {
  background: url(../images/logo/logo-dark.png) no-repeat;
  display: block;
  height: 30px;
}
.submenu-hover .sidebar .sidebar-inner .nav-sidebar > li > a .arrow {
  display: none;
}
.submenu-hover .sidebar .sidebar-inner .nav-sidebar .nav-parent .children {
  display: none;
  left: 240px;
  right: auto;
  margin-top: 0;
  min-width: 215px;
  position: absolute;
  top: 0;
  z-index: 4000;
}
.submenu-hover .sidebar .sidebar-inner .nav-sidebar .nav-parent .children:hover {
  display: block;
}
.submenu-hover .sidebar .sidebar-inner .nav-sidebar .nav-hover .children {
  display: block;
  -webkit-box-shadow: 3px 3px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 3px 3px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 3px 3px 1px rgba(0, 0, 0, 0.2);
}
.submenu-hover .sidebar .sidebar-inner .nav-sidebar .children > li > a {
  padding-left: 20px;
  padding-right: 0;
}
.submenu-hover .sidebar .sidebar-inner .nav-sidebar .children > li > a .badge {
  margin-left: 0;
  margin-right: 10px;
}
.submenu-hover .sidebar .sidebar-inner .nav-sidebar .children li:before {
  display: none;
}
.submenu-hover .sidebar .sidebar-inner .nav-sidebar .children:after {
  display: none;
}
.submenu-hover .sidebar .sidebar-inner .nav-sidebar > li.nav-hover {
  background: rgba(255, 255, 255, 0.02);
}
.submenu-hover .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border: solid transparent;
  content: " ";
  height: 0;
  left: auto;
  margin-top: -10px;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  z-index: 2000;
  border-color: rgba(136, 183, 213, 0);
  border-left: none;
  border-right: 10px solid #202226;
  border-width: 10px;
}
.submenu-hover .sidebar-light.sidebar .sidebar-inner .nav-sidebar .nav-parent .children {
  margin-left: 220px;
  margin-right: 0;
}
.submenu-hover .sidebar-condensed.sidebar .sidebar-inner .nav-sidebar .nav-parent .children {
  margin-left: 210px;
  margin-right: 0;
}
.submenu-hover.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar .children {
  display: block;
  display: none;
  left: 45px;
  margin: 0;
  position: absolute;
  right: 0;
  top: 41px;
}
.submenu-hover.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar .nav-hover .children {
  display: block;
}
.submenu-hover.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar .nav-parent .children {
  min-width: 204px;
}
.submenu-hover.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  display: none;
}
.submenu-hover.sidebar-condensed .sidebar .sidebar-inner .nav-sidebar .nav-parent .children {
  margin-left: 190px;
  margin-right: 0;
}
.submenu-hover.sidebar-light .sidebar .sidebar-inner .nav-sidebar .nav-parent .children {
  margin-left: 220px;
  margin-right: 0;
}
.submenu-hover.sidebar-top .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  display: none;
}
.sidebar-hover.fixed-topbar .main-content .topbar {
  left: 0;
}
.fixed-sidebar .sidebar {
  position: fixed;
  right: auto;
  left: 0;
  bottom: 0;
  z-index: 202;
}
@media screen and (min-width: 1024px) {
  .sidebar-collapsed .topbar {
    margin-left: -50px;
  }
  .sidebar-collapsed .topbar .header-left .topnav .menutoggle {
    background: #319db5;
    color: #ffffff;
    opacity: 0.9;
    -webkit-transition: all 0s ease-out;
    -moz-transition: all 0s ease-out;
    -o-transition: all 0s ease-out;
    -ms-transition: all 0s ease-out;
    transition: all 0s ease-out;
  }
  .sidebar-collapsed .topbar .header-left .topnav .menutoggle i:before {
    content: "s";
  }
  .sidebar-collapsed .topbar .header-left .topnav .menutoggle:hover {
    color: #ffffff;
    opacity: 1;
  }
  .sidebar-collapsed .sidebar {
    top: 49px;
    width: 50px;
    z-index: 300;
  }
  .sidebar-collapsed .sidebar .logopanel {
    display: none !important;
  }
  .sidebar-collapsed .sidebar .sidebar-top {
    display: none !important;
  }
  .sidebar-collapsed .sidebar .sidebar-inner {
    padding: 0;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .sidebartitle {
    display: none;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar {
    margin: 5px;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar li a {
    padding: 10px;
    position: relative;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar li a i {
    float: none;
    margin-right: 0;
    text-align: center;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar li .arrow {
    display: none;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar ul {
    display: none;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar ul a {
    line-height: normal;
    padding: 16px 20px 14px 35px;
    text-align: left;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar ul a:hover {
    background: none;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar li.nav-hover ul {
    background: #202226;
    display: block;
    left: 45px;
    margin: 0;
    min-width: 204px;
    padding-bottom: 10px;
    position: absolute;
    top: 41px;
    z-index: 100;
    -webkit-border-radius: 0 0 2px 0;
    -moz-border-radius: 0 0 2px 0;
    border-radius: 0 0 2px 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar .children:after {
    border-left: 1px solid #2B2E33;
    bottom: 0;
    content: "";
    left: 17px;
    position: absolute;
    top: 0;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar .children li:before {
    background-color: #2B2E33;
    border-color: rgba(0, 0, 0, 0);
    bottom: auto;
    content: "";
    height: 8px;
    left: 14px;
    margin-top: 19px;
    position: absolute;
    right: 0;
    width: 8px;
    z-index: 1;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar .nav-parent.active .children {
    display: none;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar .nav-parent.nav-hover.active .children {
    display: block;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li > a {
    height: 41px;
    overflow: visible;
    text-align: center;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li > a:hover {
    -webkit-border-radius: 2px 0 0 2px;
    -moz-border-radius: 2px 0 0 2px;
    border-radius: 2px 0 0 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li > a span {
    display: none;
    overflow: visible;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li > a .badge {
    display: none;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-parent {
    z-index: 120;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-parent > a {
    background-image: none;
    text-align: center;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li > a > span:not(.arrow) {
    display: none;
    left: 40px;
    min-width: 209px;
    padding: 10px 10px 10px 5px;
    position: absolute;
    text-align: left;
    top: 0;
    z-index: 100;
    -webkit-border-radius: 0 2px 0 0;
    -moz-border-radius: 0 2px 0 0;
    border-radius: 0 2px 0 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-box-shadow: 0 3px 0 rgba(0, 0, 0, 0.03);
    -moz-box-shadow: 0 3px 0 rgba(0, 0, 0, 0.03);
    box-shadow: 0 3px 0 rgba(0, 0, 0, 0.03);
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.active > a > span:last-child {
    -webkit-border-radius: 0 2px 0 0;
    -moz-border-radius: 0 2px 0 0;
    border-radius: 0 2px 0 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-hover > a {
    background: #ffffff;
    -webkit-border-radius: 2px 0 0 2px;
    -moz-border-radius: 2px 0 0 2px;
    border-radius: 2px 0 0 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    color: #1d2939;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-hover > a span:not(.arrow) {
    display: inline-block;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-hover > a span:not(.badge) {
    color: #121212;
    opacity: 1;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-hover > a i {
    color: #121212;
    opacity: 1;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .sidebar-footer {
    display: none;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .sidebar-widgets {
    display: none;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-hover:not(.nav-active) > a span:not(.badge) {
    background: #ffffff;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-hover.active > a {
    background: #319db5;
    color: #ffffff;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-hover.active > a i {
    color: #ffffff;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-hover.active > a span:not(.badge) {
    background: #319db5 !important;
    color: #ffffff;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-hover.active > a > span:last-child {
    background: #319db5;
    color: #ffffff;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-hover.nav-active.active > a {
    background: #319db5;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-hover.nav-active > a i {
    color: #ffffff !important;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-hover.nav-active > a span:not(.badge) {
    color: #ffffff !important;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-hover.nav-active:not(.active) > a span:not(.badge) {
    background: #ffffff;
    color: #1d2939 !important;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-hover.nav-active:not(.active) > a i {
    background: #ffffff;
    color: #1d2939 !important;
  }
  .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-hover.active:not(.nav-active) > a i {
    color: #ffffff !important;
  }
  .sidebar-collapsed .sidebar-inner .menu-title {
    display: none;
  }
  .sidebar-collapsed .main-content {
    margin-left: 50px;
  }
  .sidebar-collapsed.fixed-sidebar .topbar .header-left .topnav > ul {
    margin-left: 0;
  }
  .sidebar-collapsed.fixed-sidebar:not(.fixed-topbar) .topbar {
    margin-left: 0;
  }
  .sidebar-collapsed.fixed-sidebar:not(.fixed-topbar) .topbar .header-left .topnav .menutoggle {
    position: fixed;
  }
  .sidebar-collapsed.fixed-topbar .topbar .header-left .topnav > ul {
    margin-left: 0;
  }
  .fixed-topbar.sidebar-collapsed .topbar .header-left .topnav .menutoggle {
    left: 0;
    position: relative;
  }
  .fixed-sidebar.sidebar-collapsed .topbar .header-left .topnav .menutoggle {
    left: 0;
    position: relative;
  }
  .sidebar-top .main-content {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .sidebar-top .main-content .page-content {
    top: 116px;
  }
  .sidebar-top:not(.fixed-topbar) .main-content {
    margin-top: 116px;
  }
  .sidebar-top:not(.fixed-topbar) .sidebar .logopanel {
    background: #ffffff;
    left: 0;
    position: absolute;
    right: auto;
    top: -50px;
    top: -50px !important;
    width: 140px;
  }
  .sidebar-top .menutoggle {
    display: none;
  }
  .sidebar-top .topbar {
    left: 140px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 801;
  }
  .sidebar-top .topbar .header-left .topnav .menutoggle {
    height: 50px;
  }
  .sidebar-top .sidebar {
    bottom: auto!important;
    float: none;
    height: 66px;
    overflow: visible!important;
    position: fixed;
    top: 50px;
    width: 100%;
  }
  .sidebar-top .sidebar .logopanel {
    background: #ffffff;
    left: 0;
    position: fixed;
    right: auto;
    top: -50px;
    top: 0 !important;
    width: 140px;
    -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  }
  .sidebar-top .sidebar .logopanel h1 a {
    background: url(../images/logo/logo-dark.png) no-repeat;
  }
  .sidebar-top .sidebar:before {
    display: none;
  }
  .sidebar-top .sidebar .sidebar-inner {
    padding: 0;
  }
  .sidebar-top .sidebar .sidebar-inner .nav-sidebar .nav-parent.active .children {
    display: none !important;
  }
  .sidebar-top .sidebar .sidebar-inner .nav-sidebar .nav-parent.nav-hover .children {
    display: block !important;
    margin: 0;
    padding: 0;
    position: absolute;
  }
  .sidebar-top .sidebar .sidebar-inner .nav-sidebar .children > li > a {
    padding-left: 15px;
    padding-right: 15px;
  }
  .sidebar-top .sidebar .sidebar-inner .nav-sidebar .children:after {
    display: none;
  }
  .sidebar-top .sidebar .sidebar-inner .nav-sidebar .children li:before {
    display: none;
  }
  .sidebar-top .sidebar .sidebar-inner .arrow {
    display: none;
  }
  .sidebar-top .sidebar .sidebar-inner .nav-sidebar > li > a i {
    text-align: center;
  }
  .sidebar-top .sidebar .sidebar-inner .nav-sidebar > li > a span:not(.badge) {
    text-align: center;
  }
  .sidebar-top .sidebar nav > .nav {
    float: left;
  }
  .sidebar-top .sidebar .nav > li {
    display: inline-block;
    float: left;
    position: relative;
  }
  .sidebar-top .sidebar .nav > li .children {
    display: none;
    height: auto!important;
    left: 0;
    position: absolute !important;
    top: auto!important;
    width: 200px;
    z-index: 1050;
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  .sidebar-top .sidebar .nav > li .children -header {
    display: none!important;
  }
  .sidebar-top .sidebar .nav > li > a {
    height: 66px;
    padding: 10px 15px 12px;
    text-align: center;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .sidebar-top .sidebar .nav > li > a:hover {
    background: #202226;
  }
  .sidebar-top .sidebar .nav > li > a > i {
    display: block;
    float: none !important;
    font-size: 14px;
    line-height: 40px;
    margin: -10px auto -7px;
    width: 40px;
  }
  .sidebar-top .sidebar .nav > li > a > span {
    display: block;
    font-weight: 400;
  }
  .sidebar-top .sidebar .nav li li a {
    padding-left: 15px;
    padding-right: 15px;
  }
  .sidebar-top .sidebar .nav li:hover > .children {
    display: block;
    height: auto!important;
    margin-left: 0;
    opacity: 1;
    overflow: auto;
  }
  .sidebar-top .sidebar .nav li:focus > .children {
    display: block;
    height: auto!important;
    margin-left: 0;
    opacity: 1;
    overflow: auto;
  }
  .sidebar-top .sidebar .nav li:active > .children {
    display: block;
    height: auto!important;
    margin-left: 0;
    opacity: 1;
    overflow: auto;
  }
  .sidebar-top .sidebar-footer ~ div {
    padding-bottom: 0;
  }
  .sidebar-top .aside-wrap {
    float: none;
    overflow: visible!important;
    position: relative;
    top: 0;
    width: 100%;
  }
  .sidebar-top .nav-wrap {
    float: none;
    overflow: visible!important;
    position: relative;
    top: 0;
    width: 100%;
  }
  .sidebar-top:not(.fixed-sidebar) .sidebar {
    position: absolute;
  }
  .sidebar-top .nav-sidebar > li.nav-hover > a {
    background: #202226 !important;
  }
  .sidebar-top .nav-sidebar > li + li {
    margin-top: 0;
  }
  .sidebar-top.fixed-topbar .topbar {
    left: 140px;
    right: 0;
    position: fixed !important;
  }
  .sidebar-top.sidebar-fixed.app-header-fixed {
    padding-top: 115px;
  }
  .sidebar-top.sidebar-fixed .sidebar {
    position: fixed;
    top: 50px;
    width: 100%;
    z-index: 1000;
  }
  .fixed-sidebar.sidebar-top .sidebar {
    bottom: auto!important;
    height: 66px;
    position: fixed;
    top: 50px;
  }
  .sidebar-top .sidebar-top,
  .sidebar-top .menu-title,
  .sidebar-top .sidebar .sidebar-footer,
  .sidebar-top .sidebar .sidebar-widgets {
    display: none!important;
  }
}
@media screen and (max-width: 1024px) {
  body {
    position: relative;
  }
  body:not(.sidebar-hover) .sidebar {
    display: none;
  }
  body > section {
    height: auto;
  }
  .topbar {
    left: 0 !important;
  }
  .topbar .header-left .topnav .menutoggle i:before {
    content: "s" !important;
  }
  .fixed-topbar .topbar {
    left: 0 !important;
  }
  .main-content {
    margin-left: 0 !important;
    width: 100%;
  }
  .sidebar-collapsed .sidebar {
    display: none;
  }
  .sidebar-collapsed .main-content {
    margin-left: 0;
  }
  .sidebar-collapsed .topbar {
    margin-left: 0 !important;
  }
  .sidebar-show section {
    overflow-x: hidden;
  }
  .sidebar-show .main-content {
    margin-left: 240px !important;
    overflow: hidden;
  }
  .sidebar-show .topbar .header-left .topnav .menutoggle i:before {
    content: "r" !important;
  }
  body.sidebar-show:not(.sidebar-hover) .sidebar {
    display: block;
  }
  .sidebar-show.fixed-topbar .topbar {
    left: 240px !important;
    overflow: hidden;
  }
  .nav-horizontal {
    position: relative;
  }
  .nav-horizontal .caret {
    display: none;
  }
}
.sidebar-collapsed .topbar .header-left .topnav .mmenu .dropdown.mmenu-fw .dropdown-menu {
  left: 50px;
  right: 0;
}
body:not(.sidebar-top):not(.fixed-sidebar):not(.sidebar-light) .sidebar:before {
  background-color: inherit;
  border-color: inherit;
  border-style: inherit;
  border-width: inherit;
  bottom: 0;
  content: "";
  display: block;
  position: fixed;
  top: 0;
  width: inherit;
  z-index: -1;
}
.sidebar-light:not(.fixed-sidebar) .sidebar .sidebar-inner {
  height: auto;
}
.submenu-hover.sidebar-condensed .sidebar .sidebar-inner .nav-sidebar .nav-parent .children {
  left: 190px;
  margin-left: 0;
  right: auto;
}
body:not(.sidebar-top):not(.fixed-sidebar) .sidebar:before {
  margin-top: 50px;
}
body:not(.fixed-sidebar):not(.fixed-topbar):not(.sidebar-top) .sidebar .logopanel {
  position: absolute;
  top: -50px;
  left: 0;
}
.submenu-hover.sidebar-light .sidebar .sidebar-inner .nav-sidebar .nav-parent .children {
  left: 220px;
  margin-left: 0;
  right: auto;
}
.sidebar-condensed.sidebar-collapsed .sidebar {
  top: 50px;
}
.sidebar-light.sidebar-collapsed .sidebar {
  top: 50px;
}
.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li {
  margin-top: 2px !important;
}
.sidebar-top .hidden-st {
  display: none !important;
}
#quickview-toggle {
  margin-right: 10px;
}
#quickview-toggle i {
  color: #232323;
  font-size: 20px;
}
#quickview-sidebar {
  bottom: 0;
  left: auto;
  overflow: hidden;
  position: fixed;
  right: -280px;
  top: 0;
  -webkit-transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  -moz-transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  -o-transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  -ms-transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  width: 280px;
  z-index: 1000;
}
#quickview-sidebar.open {
  -moz-box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.15);
  -ms-transform: translate(-100%, 0);
  -o-box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.15);
  -webkit-transform: translate3d(-100%, 0, 0);
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.15);
  display: block;
  filter: progid:DXImageTransform.Microsoft.Shadow(color=rgba(0, 0, 0, 0.15), Direction=180, Strength=2);
  transform: translate3d(-100%, 0, 0);
}
#quickview-sidebar:not(.open):not(.closing) .quickview {
  display: none;
}
#quickview-sidebar:not(.open):not(.closing) .quickview-header {
  display: none;
}
#quickview-sidebar .quickview {
  -webkit-overflow-scrolling: touch;
  background: #ffffff;
  bottom: 0;
  color: #818181;
  height: auto;
  left: 0;
  left: auto;
  overflow-x: hidden;
  position: absolute;
  right: auto;
  top: 50px;
  width: 280px;
  z-index: 1;
  -webkit-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  -ms-transition: width 0.3s ease;
  transition: width 0.3s ease;
}
#quickview-sidebar .quickview .tab-content {
  border-top: none;
  padding: 0;
  position: relative;
}
#quickview-sidebar .quickview .tab-content #chat {
  padding: 0 20px;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation {
  bottom: 0;
  left: -300px;
  padding: 0;
  position: relative;
  top: 0;
  overflow-x: hidden;
  width: 280px;
  z-index: 1000;
  -webkit-transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  -moz-transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  -o-transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  -ms-transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-header {
  padding: 0;
  position: relative;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-header .user {
  border-bottom: 1px solid #E0E0E0;
  padding: 10px 15px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-header .user-img {
  float: left;
  width: 50px;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-header .user-img .badge {
  font-size: 11px;
  margin-left: -10px;
  padding: 2px 5px;
  position: absolute;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-header .user-details {
  text-align: center;
  width: 100%;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-header .user-details .user-name {
  text-align: center;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-header .user-details .user-txt {
  color: #526a82;
  text-align: center;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-header .user-status i {
  float: right;
  margin-right: 0;
  margin-top: 15px;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-header .chat-back {
  color: #424242;
  cursor: pointer;
  font-size: 16px;
  left: 25px;
  position: absolute;
  top: 22px;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-body {
  padding: 25px;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-body li {
  clear: both;
  content: " ";
  display: table;
  margin-bottom: 15px;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-body li:last-child {
  margin-bottom: 65px;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-body .conversation-img {
  float: left;
  margin-right: 15px;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-body .chat-detail {
  float: left;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-body .chat-detail .chat-bubble {
  background: #e5e9ec;
  color: #22262e;
  float: right;
  line-height: 20px;
  min-height: 40px;
  padding: 0;
  padding: 10px;
  position: relative;
  white-space: normal;
  width: 180px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-body .chat-detail .chat-bubble:after {
  border-color: rgba(0, 0, 0, 0) #e5e9ec;
  border-style: solid;
  border-width: 9px 7px 9px 0;
  content: '';
  display: block;
  left: -7px;
  position: absolute;
  top: 12px;
  width: 0;
  z-index: 1;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-body .chat-detail .chat-date {
  color: #636F77;
  display: block;
  font-size: 12px;
  margin-bottom: 8px;
  text-align: right;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-message {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 2;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-message .item-footer {
  padding: 9px 15px;
  background: #F0F0F0;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-message .item-footer i {
  float: left;
  text-align: center;
  width: 25%;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-message input {
  border-top: 1px solid #DFDFDF;
  border: none;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .chat-conversation .conversation-body .chat-detail.chat-right .chat-bubble {
  background: #2B2E33;
  color: #ffffff;
  float: left;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .chat-conversation .conversation-body .chat-detail.chat-right .chat-bubble:after {
  border-color: rgba(0, 0, 0, 0) #2b2e33;
  border-width: 9px 0 9px 7px;
  left: auto;
  right: -7px !important;
  top: 12px;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .chat-conversation .conversation-body .chat-detail.chat-right img {
  float: right!important;
  margin: 3px 0 -5px 10px !important;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .chat-conversation .conversation-message .footer-actions i:hover {
  color: #5C5C5C;
  cursor: pointer;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation.current {
  -ms-transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
#quickview-sidebar .quickview .tab-content #chat .chat-body {
  bottom: 0;
  left: auto;
  position: absolute;
  right: -280px;
  top: 0;
  width: 280px;
  -webkit-transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  -moz-transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  -o-transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  -ms-transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  z-index: 1000;
}
#quickview-sidebar .quickview .tab-content #chat .chat-body .chat-search {
  margin-top: 15px;
}
#quickview-sidebar .quickview .tab-content #chat .chat-body .chat-search .form-control {
  width: 100%;
}
#quickview-sidebar .quickview .tab-content #chat .chat-body .chat-groups i {
  margin-top: 8px;
}
#quickview-sidebar .quickview .tab-content #chat .chat-body .chat-groups .title {
  color: #526a82;
  font-family: 'Lato', arial;
  font-weight: 700;
  text-transform: uppercase;
}
#quickview-sidebar .quickview .tab-content #chat .chat-body .chat-groups ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
#quickview-sidebar .quickview .tab-content #chat .chat-body .chat-groups ul li {
  color: ##818181;
  line-height: 25px;
}
#quickview-sidebar .quickview .tab-content #chat .chat-body .chat-groups ul li:hover {
  cursor: pointer;
}
#quickview-sidebar .quickview .tab-content #chat .chat-body .chat-groups li i {
  float: left;
}
#quickview-sidebar .quickview .tab-content #chat .chat-body .chat-list li i {
  float: right;
  margin-top: 9px;
}
#quickview-sidebar .quickview .tab-content #chat .chat-body .chat-list ul li {
  display: block;
  padding: 10px 15px 7px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
#quickview-sidebar .quickview .tab-content #chat .chat-body .chat-list ul li:hover {
  background-color: #F4F5F7;
  cursor: pointer;
}
#quickview-sidebar .quickview .tab-content #chat .chat-body .chat-list .user-img {
  float: left;
  width: 50px;
}
#quickview-sidebar .quickview .tab-content #chat .chat-body .chat-list .user-img .badge {
  font-size: 11px;
  margin-left: -10px;
  padding: 2px 5px;
  position: absolute;
}
#quickview-sidebar .quickview .tab-content #chat .chat-body .chat-list .user-details {
  float: left;
  width: 130px;
}
#quickview-sidebar .quickview .tab-content #chat .chat-body .chat-body .chat-list li .user-details .user-txt {
  color: #526a82;
}
#quickview-sidebar .quickview .tab-content #chat .chat-body.current {
  -ms-transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
#quickview-sidebar .quickview .tab-content #chat .chat-body > div {
  padding: 0 20px;
}
#quickview-sidebar .quickview .tab-content .title {
  color: #526a82;
  font-family: 'Lato', arial, sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 30px;
}
#quickview-sidebar .quickview .tab-content #settings .settings {
  padding: 0 20px;
}
#quickview-sidebar .quickview .tab-content #settings .setting {
  margin-bottom: 20px;
}
#quickview-sidebar .quickview .tab-content #settings .setting .setting-info {
  color: #98A1A6;
  font-size: 12px;
  margin-top: 3px;
}
#quickview-sidebar .quickview .tab-content #settings .switch {
  margin-top: -4px;
}
#quickview-sidebar .quickview .tab-content #settings .settings-chart .chart-title {
  color: rgba(0, 0, 0, 0.6);
  float: left;
  font-family: 'Open Sans';
  font-size: 13px;
  margin-bottom: 5px;
  margin-top: 0;
  opacity: 1;
  text-transform: none;
}
#quickview-sidebar .quickview .tab-content #settings .settings-chart .chart-number {
  color: rgba(0, 0, 0, 0.5);
  float: right;
  font-size: 11px;
  margin-top: 2px;
}
#quickview-sidebar .quickview .tab-content #settings .settings-chart .progress {
  background: rgba(0, 0, 0, 0.1);
  height: 6px;
}
#quickview-sidebar .quickview .tab-content #settings .progress-chart .settings-chart .chart-.number {
  font-family: 'Open Sans';
  font-size: 13px;
  margin-bottom: 5px;
  margin-top: 0;
  opacity: 1;
  text-transform: none;
}
#quickview-sidebar .quickview .tab-content #notes {
  background: #fefaed;
}
#quickview-sidebar .quickview .tab-content #notes .list-notes {
  background: #fefaed;
  bottom: 0;
  left: -280px;
  padding: 0;
  position: fixed;
  top: 50px;
  -webkit-transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  -moz-transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  -o-transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  -ms-transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  width: 280px;
  z-index: 1000;
  -ms-transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  left: auto;
  right: 0;
}
#quickview-sidebar .quickview .tab-content #notes .list-notes.current {
  -ms-transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  right: 0;
  left: auto;
}
#quickview-sidebar .quickview .tab-content #notes .list-notes .notes h3 {
  margin-top: 5px;
}
#quickview-sidebar .quickview .tab-content #notes .list-notes .notes h3 small {
  color: #B96F6F;
}
#quickview-sidebar .quickview .tab-content #notes .list-notes .notes .mCustomScrollBox {
  overflow: hidden!important;
}
#quickview-sidebar .quickview .tab-content #notes .list-notes .notes .input-group {
  background: #ffffff;
}
#quickview-sidebar .quickview .tab-content #notes .list-notes .notes #add-note {
  color: #3F3F3F;
  display: block;
  float: none;
  font-family: 'Lato', arial, sans-serif;
  font-weight: 700;
  margin-right: 10px;
  padding: 10px 20px;
  text-align: center;
  width: 100%;
}
#quickview-sidebar .quickview .tab-content #notes .list-notes .notes #add-note i {
  padding-right: 10px;
}
#quickview-sidebar .quickview .tab-content #notes .list-notes .notes #add-note:hover {
  cursor: pointer;
}
#quickview-sidebar .quickview .tab-content #notes .list-notes .notes #notes-list {
  overflow: hidden;
  padding: 0 20px;
}
#quickview-sidebar .quickview .tab-content #notes .list-notes .notes #notes-list .note-desc {
  color: #CFCFCF;
  margin-bottom: 10px;
}
#quickview-sidebar .quickview .tab-content #notes .list-notes .notes #notes-list .note-item {
  border-bottom: 1px solid #EBEBEB;
  border: none;
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 10px 0;
}
#quickview-sidebar .quickview .tab-content #notes .list-notes .notes #notes-list .note-item:hover p {
  color: #121212;
}
#quickview-sidebar .quickview .tab-content #notes .list-notes .notes #notes-list .note-item h5 {
  margin-top: 0;
}
#quickview-sidebar .quickview .tab-content #notes .list-notes .notes #notes-list .note-item p {
  height: 20px;
  margin-bottom: 0;
  overflow: hidden;
}
#quickview-sidebar .quickview .tab-content #notes .list-notes .notes #notes-list .close {
  font-size: 14px;
}
#quickview-sidebar .quickview .tab-content #notes .list-notes .note-title {
  margin-top: 0;
}
#quickview-sidebar .quickview .tab-content #notes .list-notes.current {
  -ms-transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
#quickview-sidebar .quickview .tab-content #notes .detail-note {
  bottom: 0;
  position: absolute;
  right: -280px;
  top: 0;
  -webkit-transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  -moz-transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  -o-transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  -ms-transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  width: 280px;
  z-index: 1000;
}
#quickview-sidebar .quickview .tab-content #notes .detail-note #note-detail {
  overflow: hidden;
}
#quickview-sidebar .quickview .tab-content #notes .detail-note #note-detail .note-write {
  -moz-background-size: 100% 40px;
  -ms-background-size: 100% 40px;
  -webkit-background-size: 100% 40px;
  background-size: 100% 40px;
  background: -moz-linear-gradient(top, #f0f0f0 0%, #ffffff 5%) 0 0;
  background: -webkit-linear-gradient(top, #f0f0f0 0%, #ffffff 5%) 0 0;
  background: linear-gradient(top, #f0f0f0 0%, #ffffff 5%) 0 0;
  padding: 0;
  position: relative;
}
#quickview-sidebar .quickview .tab-content #notes .detail-note #note-detail .note-write:before {
  border-left: 1px solid #FCE1BE;
  bottom: 0;
  content: '';
  left: 20px;
  position: absolute;
  top: 0;
  width: 0;
}
#quickview-sidebar .quickview .tab-content #notes .detail-note #note-detail .note-write:after {
  border-left: 1px solid #FCE1BE;
  bottom: 0;
  content: '';
  left: 24px;
  position: absolute;
  top: 0;
  width: 0;
}
#quickview-sidebar .quickview .tab-content #notes .detail-note #note-detail .note-write textarea {
  background-color: #fefaed !important;
  border: none;
  font-family: 'Lato', arial !important;
  font-weight: 100;
  font-size: 18px;
  height: 100%;
  line-height: 40px;
  min-height: 300px;
  padding: 0 0 0 35px;
}
#quickview-sidebar .quickview .tab-content #notes .detail-note #note-detail .note-write textarea:hover {
  background-color: #fefaed !important;
  border: none!important;
  outline: none!important;
}
#quickview-sidebar .quickview .tab-content #notes .detail-note #note-detail .note-write textarea:focus {
  background-color: #fefaed !important;
  border: none!important;
  outline: none!important;
}
#quickview-sidebar .quickview .tab-content #notes .detail-note .note-header {
  background: #fefaed;
  border-bottom: 1px solid #E0E0E0;
  padding: 10px 15px;
}
#quickview-sidebar .quickview .tab-content #notes .detail-note .note-header .note-edit {
  text-align: center;
}
#quickview-sidebar .quickview .tab-content #notes .detail-note .note-header .note-subtitle {
  color: #526a82;
  font-size: 12px;
  text-align: center;
}
#quickview-sidebar .quickview .tab-content #notes .detail-note .note-header .note-back {
  color: #424242;
  cursor: pointer;
  font-size: 16px;
  left: 20px;
  position: absolute;
  top: 22px;
}
#quickview-sidebar .quickview .tab-content #notes .detail-note.current {
  -ms-transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
#quickview-sidebar .quickview-header {
  background-color: #292C35;
  font-family: 'Lato', arial;
  font-weight: 100;
  font-size: 12px;
  height: 50px;
  width: 280px;
  z-index: 10;
}
#quickview-sidebar .quickview-header .nav.nav-tabs {
  border-bottom: none;
}
#quickview-sidebar .quickview-header .nav > li {
  text-align: center;
  width: 33%;
}
#quickview-sidebar .quickview-header .nav > li > a {
  background: none;
  color: #909090;
  font-family: 'Lato', arial, sans-serif;
  font-weight: 700;
  padding: 16px 15px;
  text-transform: uppercase;
}
#quickview-sidebar .quickview-header .nav.nav-tabs > li.active > a {
  background: none;
  color: #ffffff;
}
#quickview-sidebar .quickview-header .nav-tabs > li > a::before {
  display: none;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-message input.form-control.form-white,
.conversation-message input.form-control.form-white:hover,
.conversation-message input.form-control.form-white:focus,
.conversation-message textarea.form-control.form-white:active {
  border: none;
  border-top: 1px solid #DFDFDF;
  height: 35px;
  outline: none!important;
}
#quickview-sidebar.closing {
  -ms-transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
#quickview-sidebar.closing .quickview .tab-content #chat .chat-body {
  -ms-transform: translate(0, 0) !important;
  -webkit-transform: translate3d(0, 0, 0) !important;
  transform: translate3d(0, 0, 0) !important;
}
#quickview-sidebar.closing .quickview .tab-content #chat .chat-body.current {
  -ms-transform: translate(0, 0) !important;
  -webkit-transform: translate3d(0, 0, 0) !important;
  transform: translate3d(0, 0, 0) !important;
}
#quickview-sidebar .quickview .tab-content #notes .list-notes.current {
  -ms-transform: translate(0, 0) !important;
  -webkit-transform: translate3d(0, 0, 0) !important;
  transform: translate3d(0, 0, 0) !important;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-message {
  -ms-transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  left: auto;
  right: 0;
  transform: translate3d(100%, 0, 0);
  width: 280px;
}
#quickview-sidebar .quickview .tab-content #chat .chat-conversation.current .conversation-message {
  -ms-transform: translate(0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.ie-browser #quickview-sidebar {
  -moz-transition: transform 0s cubic-bezier(0.25, 0.1, 0.25, 1) !important;
  -ms-transition: transform 0s cubic-bezier(0.25, 0.1, 0.25, 1) !important;
  -o-transition: transform 0s cubic-bezier(0.25, 0.1, 0.25, 1) !important;
  -webkit-transition: transform 0s cubic-bezier(0.25, 0.1, 0.25, 1) !important;
  transition: transform 0s cubic-bezier(0.25, 0.1, 0.25, 1) !important;
}
.ie-browser #quickview-sidebar .quickview .tab-content #chat .chat-body {
  -moz-transition: transform 0s cubic-bezier(0.25, 0.1, 0.25, 1) !important;
  -ms-transition: transform 0s cubic-bezier(0.25, 0.1, 0.25, 1) !important;
  -o-transition: transform 0s cubic-bezier(0.25, 0.1, 0.25, 1) !important;
  -webkit-transition: transform 0s cubic-bezier(0.25, 0.1, 0.25, 1) !important;
  transition: transform 0s cubic-bezier(0.25, 0.1, 0.25, 1) !important;
}
.ie-browser #quickview-sidebar .quickview .tab-content #chat .current.chat-body {
  -moz-transition: transform 0s cubic-bezier(0.25, 0.1, 0.25, 1) !important;
  -ms-transition: transform 0s cubic-bezier(0.25, 0.1, 0.25, 1) !important;
  -o-transition: transform 0s cubic-bezier(0.25, 0.1, 0.25, 1) !important;
  -webkit-transition: transform 0s cubic-bezier(0.25, 0.1, 0.25, 1) !important;
  transition: transform 0s cubic-bezier(0.25, 0.1, 0.25, 1) !important;
}
.ie-browser #quickview-sidebar.closing .quickview .tab-content #notes .list-notes {
  -moz-transition: transform 0s cubic-bezier(0.25, 0.1, 0.25, 1) !important;
  -ms-transition: transform 0s cubic-bezier(0.25, 0.1, 0.25, 1) !important;
  -o-transition: transform 0s cubic-bezier(0.25, 0.1, 0.25, 1) !important;
  -webkit-transition: transform 0s cubic-bezier(0.25, 0.1, 0.25, 1) !important;
  transition: transform 0s cubic-bezier(0.25, 0.1, 0.25, 1) !important;
}
.boxed {
  background: #353535 !important;
}
.boxed section {
  margin: auto;
  position: relative;
  width: 1200px;
}
.boxed section .main-content {
  margin-top: 0 !important;
  position: relative;
}
.boxed section .main-content .page-content {
  top: 0;
}
.boxed section .main-content .topbar {
  left: auto !important;
  margin-left: 0;
  margin-right: auto;
  margin: auto;
  position: relative;
  right: auto !important;
  width: 1200px;
}
.boxed section .sidebar .logopanel {
  float: left;
}
.boxed.sidebar-top section .sidebar {
  left: auto;
  right: auto;
  width: 1200px;
}
.boxed.sidebar-top section .sidebar .logopanel {
  left: auto;
}
.boxed.sidebar-top section .main-content .topbar {
  left: auto;
  margin-left: 140px;
  margin-right: auto;
  right: auto;
}
.boxed.fixed-sidebar.sidebar-collapsed section .sidebar {
  left: 0;
  position: absolute;
  right: auto;
}
.boxed.fixed-topbar section .main-content .topbar {
  left: auto !important;
  margin-right: auto;
  right: auto !important;
}
.boxed.sidebar-top.fixed-topbar section .sidebar .logopanel {
  float: left;
  left: auto;
  right: auto;
}
.boxed.sidebar-collapsed section .main-content .topbar {
  margin-left: -50px;
  margin-right: auto;
}
.boxed.sidebar-collapsed.fixed-topbar section .main-content .topbar {
  margin-left: -50px;
  margin-right: auto;
}
.boxed.fixed-topbar.sidebar-collapsed section .main-content .topbar .header-left .topnav .menutoggle {
  left: auto;
  left: 0;
  margin-left: 0;
  margin-right: auto;
  opacity: 0.9;
  right: auto;
}
.boxed.fixed-topbar.sidebar-collapsed section .main-content .topbar .header-left .topnav .menutoggle:hover {
  opacity: 1;
}
.boxed section .main-content .topbar {
  width: 1060px;
}
.boxed.sidebar-top .main-content .page-content {
  top: 66px;
}
.boxed.sidebar-top.fixed-topbar:not(.fixed-sidebar) .sidebar {
  top: 0;
}
.boxed.fixed-topbar .main-content .page-content {
  margin-top: 0;
}
.boxed.fixed-topbar.sidebar-top .main-content .page-content {
  margin-top: 50px;
}
.boxed.sidebar-collapsed section .main-content .topbar {
  z-index: 300;
}
.boxed.sidebar-collapsed.fixed-sidebar:not(.fixed-topbar) .topbar .header-left .topnav .menutoggle {
  position: relative;
}
.rtl .boxed.sidebar-collapsed section .main-content .topbar {
  margin-right: -50px;
}
@media (max-width: 1220px) {
  .boxed section {
    margin: auto;
    position: relative;
    width: 100%;
  }
  .boxed section .main-content .topbar {
    margin: auto;
    position: relative;
    width: 100%;
  }
}
.main-content {
  background: #F5F5F5;
  margin-left: 240px;
  min-height: 750px;
}
.main-content .page-content {
  background: #F5F5F5;
  margin-top: 50px;
  overflow: hidden;
  padding: 20px 50px 0;
  position: relative;
}
.main-content .page-content h3 {
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  margin-bottom: 15px;
  margin-top: 25px;
  text-transform: uppercase;
}
.main-content .page-content::after {
  clear: both;
  content: '';
  display: block;
}
.main-content .page-content .row-sm[class^="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}
.main-content .page-content .row-xs[class^="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}
.main-content .page-content .colors-list {
  height: 30px;
  list-style: none;
  margin: 0;
  padding: 0;
}
.main-content .page-content .colors-list > li {
  background: #000000;
  display: inline-block;
  filter: alpha(opacity=60);
  float: left;
  height: 20px;
  margin: 4px;
  opacity: .6;
  padding: 0;
  width: 20px;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.main-content .page-content .colors-list > li:first-child {
  margin-left: 0;
}
.main-content .page-content .colors-list > li:hover {
  cursor: pointer;
  filter: alpha(opacity=100);
  opacity: 1;
}
.main-content .page-content .colors-list > li.active {
  filter: alpha(opacity=100);
  height: 26px;
  margin-top: 0;
  opacity: 1;
  width: 26px;
}
.main-content .page-content .colors-list > li.white {
  background: #ffffff;
  border: 1px solid #BFBFBF;
}
.main-content .page-content .colors-list > li.primary {
  background: #319db5;
}
.main-content .page-content .colors-list > li.dark {
  background: #2B2E33;
}
.main-content .page-content .colors-list > li.red {
  background: #C9625F;
}
.main-content .page-content .colors-list > li.green {
  background: #18a689;
}
.main-content .page-content .colors-list > li.blue {
  background: #319DB5;
}
.main-content .page-content .colors-list > li.aero {
  background: #B9D5DB;
}
.main-content .page-content .colors-list > li.gray {
  background: #82848A;
}
.main-content .page-content .colors-list > li.gray-light {
  background: #F4F4F4;
}
.main-content .page-content .colors-list > li.orange {
  background: #F2A057;
}
.main-content .page-content .colors-list > li.yellow {
  background: #ffcc00;
}
.main-content .page-content .colors-list > li.pink {
  background: #DC88E6;
}
.main-content .page-content .colors-list > li.purple {
  background: #A58BD3;
}
.main-content .page-content .colors-list.color-full > li {
  border: 1px solid #5A5A5A;
}
.main-content .page-content .panel {
  background: #ffffff;
  border-radius: 0;
  border: 1px solid #E2E2E2;
  border: none;
  color: #5b5b5b;
  margin-bottom: 20px;
  min-height: 30px;
  position: relative;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}
.main-content .page-content .panel:before {
  content: " ";
  display: table;
}
.main-content .page-content .panel:after {
  content: " ";
  display: table;
}
.main-content .page-content .panel .panel-content h1 {
  font-family: 'Lato';
  font-weight: 100;
  font-size: 28px;
  margin-top: 0;
}
.main-content .page-content .panel .panel-content ul {
  padding-left: 0;
}
.main-content .page-content .panel .panel-content ol {
  padding-left: 0;
}
.main-content .page-content .panel .panel-content i {
  padding-left: 0;
  padding-right: 6px;
}
.main-content .page-content .panel .panel-content .btn i {
  padding-right: 0;
}
.main-content .page-content .panel .panel-header[class*="bg-"] h2 {
  color: #ffffff;
}
.main-content .page-content .panel .panel-header {
  display: block;
  height: auto;
  padding: 6px 15px 6px 18px;
}
.main-content .page-content .panel .panel-header h2 {
  color: #5b5b5b;
  display: block;
  font-family: 'Lato';
  font-size: 24px;
  font-weight: 100;
  line-height: normal;
  margin-bottom: 5px;
  margin-top: 7px;
  text-transform: uppercase;
}
.main-content .page-content .panel .panel-header h3 {
  display: block;
  font-family: 'Lato', 'Open Sans', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
  margin-top: 10px;
  text-transform: uppercase;
}
.main-content .page-content .panel .panel-header:hover .control-btn > a {
  background: none !important;
  color: #909090 !important;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.main-content .page-content .panel .panel-header .control-btn > a .icon-size-fullscreen:before {
  display: inline-block;
  width: 14px;
}
.main-content .page-content .panel .panel-header .control-btn .btn-group > a {
  color: #cccccc !important;
  cursor: pointer;
  margin-left: 11px;
  margin-right: 0px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.main-content .page-content .panel .control-btn {
  display: inline-block !important;
  position: absolute;
  right: 13px;
  top: 14px;
  z-index: 2;
}
.main-content .page-content .panel .control-btn .dropdown-menu {
  z-index: 2;
}
.main-content .page-content .panel .left-toolbar {
  left: 13px;
  position: absolute;
  top: 10px;
  z-index: 2;
}
.main-content .page-content .panel:hover .control-btn > a.hidden {
  display: inline-block !important;
  visibility: visible !important;
}
.main-content .page-content .panel:hover .control-btn > a.hidden.nevershow {
  display: none !important;
}
.main-content .page-content .panel .control-btn > a.nevershow {
  display: none !important;
}
.main-content .page-content .panel table .btn-group {
  z-index: 1;
}
.main-content .page-content .panel .panel-close {
  font-size: 15px;
}
.main-content .page-content .panel .panel-close:hover {
  color: #EB5055 !important;
}
.main-content .page-content .panel .control-btn > a {
  color: #cccccc !important;
  cursor: pointer;
  margin-left: 11px;
  margin-right: 0px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.main-content .page-content .panel .control-btn > a:hover {
  text-decoration: none;
  text-decoration: none !important;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.main-content .page-content .panel .control-btn > a:focus {
  text-decoration: none !important;
}
.main-content .page-content .panel .panel-header.header-line:before {
  background: rgba(0, 0, 0, 0.1);
  bottom: 0;
  content: "";
  height: 1px;
  left: 16px;
  position: absolute;
  right: 16px;
}
.main-content .page-content .panel .panel-title {
  display: inline-block;
  font-family: 'Lato', 'Open Sans', Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 100;
  margin: 5px;
  padding: 0;
}
.main-content .page-content .panel .panel-footer.footer-line {
  background-color: #ffffff;
}
.main-content .page-content .panel .panel-footer.footer-line:before {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  content: "";
  height: 1px;
  left: 0;
  position: relative;
  right: 0;
  top: -10px;
  width: 100%;
}
.main-content .page-content .panel .panel-footer {
  background-color: #F4F4F4;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid #E2E2E2;
  border-top: 1px solid #E2E2E2;
  border-top: none;
  padding: 10px 15px;
}
.main-content .page-content .panel .panel-footer .btn {
  margin-bottom: 0;
}
.main-content .page-content .panel .stat-num {
  font-size: 36px;
  font-weight: bold;
}
.main-content .page-content .panel.panel-transparent {
  -webkit-box-shadow: none;
  background: rgba(0, 0, 0, 0);
  border: none;
  box-shadow: none;
}
.main-content .page-content .panel.panel-transparent .panel-content {
  padding: 0;
}
.main-content .page-content .portlets.ui-sortable {
  z-index: 2;
}
.main-content .page-content .portlets.ui-sortable .panel-header {
  cursor: move;
}
.main-content .page-content .panel.bg-aero .panel-content {
  color: #5b5b5b !important;
}
.main-content .page-content .panel[class*='bg-'].control-btn > a {
  color: #ffffff !important;
  opacity: 0.8;
}
.main-content .page-content .panel[class*='bg-'].panel-header:hover .control-btn > a {
  opacity: 1;
}
.main-content .page-content .panel-content {
  padding: 20px 20px 10px 20px;
  -webkit-border-radius: 0 0 2px 2px;
  -moz-border-radius: 0 0 2px 2px;
  border-radius: 0 0 2px 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.main-content .page-content .panel-content h3 {
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  margin-bottom: 15px;
  margin-top: 25px;
  text-transform: uppercase;
}
.main-content .page-content .panel-transparent .panel-header {
  border: none !important;
}
.main-content .page-content .panel-transparent .panel-content {
  border: none !important;
}
.main-content .page-content .panel-transparent .panel-footer {
  border: none !important;
}
.main-content .page-content .panel-placeholder {
  background-color: #DFE1E4 !important;
  border: 1px dashed #b6bcbf;
  margin-bottom: 20px;
  visibility: visible !important;
}
.main-content .page-content .panel.maximized {
  border-top: 1px solid #E8E8E8;
  bottom: 0px;
  left: 240px;
  margin: 0px;
  padding-top: 10px;
  position: fixed;
  right: 0px;
  top: 50px;
  z-index: 11;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.main-content .page-content .panel-default > .panel-heading {
  border-bottom: none;
}
.main-content .page-content .panel-default {
  border-color: #E8E8E8;
}
.main-content .page-content .sortable .panel-header {
  cursor: move;
}
.main-content .page-content .panel-header i {
  padding-right: 6px;
}
.main-content .page-content .panel-stat {
  border: none;
  overflow: hidden;
  position: relative;
}
.main-content .page-content .panel-stat h1 {
  color: #121212;
}
.main-content .page-content .panel-stat .stat-title {
  color: #121212;
  opacity: 0.7;
  text-transform: uppercase;
}
.main-content .page-content .panel-stat .icon {
  bottom: 45px;
  color: rgba(0, 0, 0, 0.1);
  font-size: 100px;
  line-height: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 5px;
  vertical-align: bottom;
  z-index: 1;
}
.main-content .page-content .panel-stat .bg-dark .icon {
  color: rgba(255, 255, 255, 0.1);
}
.main-content .page-content .panel-stat .big-icon i {
  display: inline-block;
  font-size: 53px;
  margin-top: 8px;
  opacity: 0.3;
}
.main-content .page-content .panel-stat .col-xs-6:last-child {
  text-align: right;
}
.main-content .page-content .panel-stat > [class*="bg-"]h1 {
  color: #ffffff;
}
.main-content .page-content .panel-stat > [class*="bg-"]h3 {
  color: #ffffff;
}
.main-content .page-content .panel-stat[class*="bg-"].stat-title {
  color: #ffffff;
}
.main-content .page-content .panel-stat-chart {
  margin-bottom: -10px;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -20px;
  position: relative;
}
.main-content .page-content #visitors-chart {
  bottom: 0;
  left: 0;
  position: absolute;
}
.main-content .page-content.page-thin {
  padding: 25px 25px 0 25px;
}
.main-content .page-content.page-thin .row {
  margin-left: -5px;
  margin-right: -5px;
}
.main-content .page-content.page-thin .row > [class^="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}
.main-content .page-content.page-thin.page-builder {
  padding-left: 50px;
  padding-right: 50px;
}
.main-content .page-content .breadcrumb-wrapper {
  position: absolute;
  right: 0;
  top: 23px;
}
.main-content .page-content .breadcrumb-wrapper .label {
  color: #999999;
  display: inline-block;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
}
.main-content .page-content .breadcrumb {
  background: none;
  display: inline-block;
  padding: 0;
}
.main-content .page-content .breadcrumb li {
  font-family: 'Lato', arial, sans-serif;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
}
.main-content .page-content .breadcrumb li a {
  color: #319DB5;
}
.main-content .page-content .breadcrumb li.active {
  color: #333333;
}
.main-content .page-content .breadcrumb > li + li:before {
  color: #cccccc;
  content: "\2022";
  padding: 0 8px 0 5px;
}
.main-content .page-content > .header {
  padding: 15px 0;
  position: relative;
}
.main-content .page-content > .header h2 {
  color: #1D2939;
  font-family: 'Lato';
  font-size: 28px;
  letter-spacing: -0.5px;
  margin: 0;
  padding-bottom: 7px;
  font-weight: 100;
}
.main-content .page-content > .header h2 span {
  color: #999999;
  font-size: 13px;
  font-style: italic;
  letter-spacing: 0;
  text-transform: none;
  vertical-align: middle;
}
.main-content .page-content > .header h2 span::before {
  color: #cccccc;
  content: '/';
  margin: 0 10px 0 5px;
}
.main-content .page-content > .header .glyphicon {
  font-size: 24px;
  margin-right: 5px;
  opacity: 0.5;
  padding: 6px 7px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
}
.main-content .page-content > .header i {
  font-size: 24px;
  margin-right: 5px;
  opacity: 0.5;
  padding: 6px 7px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
}
.main-content .page-content > .header icon-logoutbefore {
  bottom: -4px;
  display: inline-block;
  font-size: 28px;
  position: relative;
}
.main-content .page-content > .footer {
  bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 20px;
  padding-bottom: 15px;
}
.main-content .page-content > .footer .copyright {
  border-top: 1px solid rgba(12, 12, 12, 0.07);
  padding: 20px 0;
  z-index: 15;
}
.main-content .page-content > .footer .copyright p {
  margin-bottom: 0;
}
.main-content .page-content > .footer .copyright a {
  color: #2C8CA0;
}
.main-content .page-content > .footer .copyright span {
  font-size: 88%;
  opacity: 0.8;
}
.main-content .page-content > .footer .copyright span.copyright {
  font-family: 'Open Sans';
}
.main-content .page-content > .footer.footer-absolute {
  left: 50px;
  margin-left: 0;
  margin-right: 0;
  position: absolute;
  right: 50px;
}
.main-content .page-content[class*='bg-'].panel-header .control-btn .btn-group > a {
  color: #ffffff !important;
  opacity: 0.8;
}
.main-content .page-content[class*='bg-'].panel .panel-header:hover .control-btn > a {
  opacity: 1;
}
.main-content .page-content[class^='bg-'].panel h1,
.main-content .page-content[class*=' bg-'].panel h1,
.main-content .page-content[class^='bg-'].panel h2,
.main-content .page-content[class*=' bg-'].panel h2,
.main-content .page-content[class^='bg-'].panel h3,
.main-content .page-content[class*=' bg-'].panel h3,
.main-content .page-content .panel[class^='bg-']h2,
.main-content .page-content .panel[class*=' bg-']h2,
.main-content .page-content .panel[class^='bg-']h3,
.main-content .page-content .panel[class*=' bg-']h3 {
  color: #ffffff !important;
}
.main-content .page-content .panel.bg-aero h1,
.main-content .page-content .panel.bg-aero h2,
.main-content .page-content .panel.bg-aero h3,
.main-content .page-content .panel .bg-aero h2,
.main-content .page-content .panel .bg-aero h3,
.main-content .page-content .panel .bg-gray-light h2,
.main-content .page-content .panel .bg-gray-light h3,
.main-content .page-content .panel .bg-blue-light h2,
.main-content .page-content .panel .bg-blue-light h3 {
  color: #5b5b5b !important;
}
.panel:hover .control-btn > a.nevershow {
  display: none !important;
}
.panel .panel-toggle {
  font-size: 15px;
}
.main-content .page-content .panel-header h3,
.main-content .page-content .panel-footer h2,
.main-content .page-content .panel-footer h3 {
  margin: 0;
}
.panel-stat h3 {
  color: #121212;
}
.sidebar-top .main-content .page-content .panel.maximized {
  left: 0;
  right: 0;
  top: 115px;
}
.sidebar-collapsed .main-content .page-content .panel.maximized {
  left: 50px;
}
body:not(.fixed-topbar) .main-content .page-content {
  margin-top: 0;
}
.sidebar-top .main-content .page-content {
  top: 0;
}
.sidebar-top.fixed-topbar .main-content .page-content {
  top: 66px;
}
@media screen and (max-width: 991px) {
  .main-content .page-content {
    background: #F1F2F3;
    overflow: hidden;
    padding: 20px;
  }
  .main-content .page-content > .header h2 {
    font-size: 20px;
  }
  .main-content .page-content > .header h2 span {
    display: none;
  }
  .main-content .page-content > .header .breadcrumb-wrapper {
    display: none;
  }
}
@media (max-width: 500px) {
  .main-content .page-content .panel .control-btn {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .main-content .page-content .panel-stat h1 {
    font-size: 24px;
  }
  .main-content .page-content .panel-stat h3 {
    font-size: 18px;
  }
}
.widget {
  margin-bottom: 20px;
  padding: 0 10px 10px;
}
/* TODO LIST */
.todo-list {
  margin: 0;
  padding: 0;
}
.todo-list li {
  background: #f8f8f8;
  border-left: 2px solid rgba(0, 0, 0, 0.04);
  list-style: none;
  margin-bottom: 4px;
  padding: 10px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.todo-list li:hover {
  background: #F5F5F5;
}
.todo-list li:hover .todo-tags {
  display: none;
}
.todo-list li:hover .todo-options {
  display: inline;
  position: absolute;
  right: 14px;
  top: 10px;
}
.todo-list li .editable-buttons {
  display: inline-block;
  margin-left: 7px !important;
}
.todo-list li .editable-buttons i {
  font-size: 18px;
}
.todo-list li .span-check {
  height: 22px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  width: 26px;
}
.todo-list li .todo-task {
  border-bottom: 1px dashed rgba(0, 0, 0, 0) !important;
  font-size: 12px;
}
.todo-list li .todo-task:hover {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2) !important;
}
.todo-list li .due-date span {
  border-bottom: 1px dashed rgba(0, 0, 0, 0) !important;
  font-size: 12px;
}
.todo-list li .todo-date {
  display: block;
  font-size: 12px;
  line-height: 1.5;
  margin-left: 40px;
}
.todo-list li .todo-date .due-date {
  float: none;
}
.todo-list li .todo-date .completed-date {
  float: left;
  margin-right: 20px;
}
.todo-list li .done .todo-date .due-date {
  float: right;
}
.todo-list li .todo-options {
  display: none;
  font-size: 16px;
}
.todo-list li .todo-options a {
  color: #888888;
}
.todo-list li .todo-options .todo-delete:hover {
  color: #C75757;
}
.todo-list li .todo-tags {
  display: inline;
  font-family: "Open Sans";
  left: auto;
  position: absolute;
  right: 9px;
  top: 6px;
}
.todo-list li .editable-container.editable-inline {
  margin-top: -4px;
  overflow: visible;
}
.todo-list li .editable-unsaved {
  font-weight: normal !important;
}
.todo-list li .editableform .btn.btn-sm {
  margin-right: 0 !important;
  margin-top: 0px;
  padding: 7px 12px !important;
}
.todo-list li .editableform .btn.btn-sm i {
  font-size: 12px !important;
}
.todo-list li.done {
  opacity: .5;
}
.todo-list li.done .todo-task {
  text-decoration: line-through;
}
.todo-list li.done .todo-tags {
  display: inline!important;
}
.todo-list li.done .todo-options {
  display: none!important;
}
.todo-list li > span {
  display: inline-block;
  height: 24px;
  line-height: 26px;
  margin-top: 5px;
  max-width: 72%;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.todo-list li > span.todo-task {
  overflow: hidden;
}
.todo-list li.high {
  border-left: 2px solid #C75757;
  border-right: none;
}
.todo-list li.medium {
  border-left: 2px solid #FF9122;
  border-right: none;
}
.todo-list li.low {
  border-left: 2px solid #F3D261;
  border-right: none;
}
.todo-list li.new-task input[type=checkbox] + label::before {
  left: 3px;
}
.todo-list li.new-task input[type="checkbox"].checkbox-square:checked + label::after {
  left: 4px;
}
/* WEATHER WIDGET  */
.widget-weather {
  border: none !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.widget-weather .panel-header {
  border: none !important;
}
.widget-weather .panel-header h3 {
  color: #ffffff !important;
}
.widget-weather .panel-header i {
  display: inline-block;
  margin-top: -5px;
}
.widget-weather .panel-header i:before {
  font-size: 35px;
}
.widget-weather .panel-content {
  border: none !important;
}
.widget-weather .weather {
  background-color: #319DB5;
  margin-bottom: -151px;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}
.widget-weather .weather .weather-forecast .icon-0:before {
  content: ":";
}
.widget-weather .weather .weather-forecast .icon-1:before {
  content: "p";
}
.widget-weather .weather .weather-forecast .icon-2:before {
  content: "S";
}
.widget-weather .weather .weather-forecast .icon-3:before {
  content: "Q";
}
.widget-weather .weather .weather-forecast .icon-4:before {
  content: "S";
}
.widget-weather .weather .weather-forecast .icon-5:before {
  content: "W";
}
.widget-weather .weather .weather-forecast .icon-6:before {
  content: "W";
}
.widget-weather .weather .weather-forecast .icon-7:before {
  content: "W";
}
.widget-weather .weather .weather-forecast .icon-8:before {
  content: "W";
}
.widget-weather .weather .weather-forecast .icon-9:before {
  content: "I";
}
.widget-weather .weather .weather-forecast .icon-10:before {
  content: "W";
}
.widget-weather .weather .weather-forecast .icon-11:before {
  content: "I";
}
.widget-weather .weather .weather-forecast .icon-12:before {
  content: "I";
}
.widget-weather .weather .weather-forecast .icon-13:before {
  content: "I";
}
.widget-weather .weather .weather-forecast .icon-14:before {
  content: "I";
}
.widget-weather .weather .weather-forecast .icon-15:before {
  content: "W";
}
.widget-weather .weather .weather-forecast .icon-16:before {
  content: "I";
}
.widget-weather .weather .weather-forecast .icon-17:before {
  content: "W";
}
.widget-weather .weather .weather-forecast .icon-18:before {
  content: "U";
}
.widget-weather .weather .weather-forecast .icon-19:before {
  content: "Z";
}
.widget-weather .weather .weather-forecast .icon-20:before {
  content: "Z";
}
.widget-weather .weather .weather-forecast .icon-21:before {
  content: "Z";
}
.widget-weather .weather .weather-forecast .icon-22:before {
  content: "Z";
}
.widget-weather .weather .weather-forecast .icon-23:before {
  content: "Z";
}
.widget-weather .weather .weather-forecast .icon-24:before {
  content: "E";
}
.widget-weather .weather .weather-forecast .icon-25:before {
  content: "E";
}
.widget-weather .weather .weather-forecast .icon-26:before {
  content: "3";
}
.widget-weather .weather .weather-forecast .icon-27:before {
  content: "a";
}
.widget-weather .weather .weather-forecast .icon-28:before {
  content: "A";
}
.widget-weather .weather .weather-forecast .icon-29:before {
  content: "a";
}
.widget-weather .weather .weather-forecast .icon-30:before {
  content: "A";
}
.widget-weather .weather .weather-forecast .icon-31:before {
  content: "6";
}
.widget-weather .weather .weather-forecast .icon-32:before {
  content: "1";
}
.widget-weather .weather .weather-forecast .icon-33:before {
  content: "6";
}
.widget-weather .weather .weather-forecast .icon-34:before {
  content: "1";
}
.widget-weather .weather .weather-forecast .icon-35:before {
  content: "W";
}
.widget-weather .weather .weather-forecast .icon-36:before {
  content: "1";
}
.widget-weather .weather .weather-forecast .icon-37:before {
  content: "S";
}
.widget-weather .weather .weather-forecast .icon-38:before {
  content: "S";
}
.widget-weather .weather .weather-forecast .icon-39:before {
  content: "S";
}
.widget-weather .weather .weather-forecast .icon-40:before {
  content: "M";
}
.widget-weather .weather .weather-forecast .icon-41:before {
  content: "W";
}
.widget-weather .weather .weather-forecast .icon-42:before {
  content: "I";
}
.widget-weather .weather .weather-forecast .icon-43:before {
  content: "W";
}
.widget-weather .weather .weather-forecast .icon-44:before {
  content: "a";
}
.widget-weather .weather .weather-forecast .icon-45:before {
  content: "S";
}
.widget-weather .weather .weather-forecast .icon-46:before {
  content: "U";
}
.widget-weather .weather .weather-forecast .icon-47:before {
  content: "S";
}
.widget-weather .weather .weather-forecast li {
  border-left: 1px solid #f2fbfb;
  border-left: 1px solid rgba(242, 251, 251, 0.2);
  float: left;
  list-style-type: none;
  width: 20%;
}
.widget-weather .weather .weather-forecast li a {
  color: #ffffff;
  display: block;
  font-size: 13px;
  font-weight: 400;
  height: 100%;
  padding: 10px 3px 0;
  text-align: center;
  text-transform: uppercase;
}
.widget-weather .weather .weather-forecast li a:hover {
  background: #60c9cb;
  background: rgba(242, 251, 251, 0.2);
}
.widget-weather .weather .weather-forecast li a.active {
  background: #60c9cb;
  background: rgba(242, 251, 251, 0.2);
}
.widget-weather .weather .weather-forecast li:first-child a {
  border-left: 0 none;
}
.widget-weather .weather .weather-forecast strong {
  display: block;
  margin-bottom: 5px;
  text-align: center !important;
}
.widget-weather .weather .weather-forecast .icon-weather {
  background: url(images/icons/weather-icons.png) 0 0 no-repeat;
  height: 24px;
  margin: 0;
  width: 24px;
}
.widget-weather .weather .weather-forecast .icon-weather-2 {
  background-position: 0 -24px;
}
.widget-weather .weather .weather-forecast .icon-weather-3 {
  background-position: 0 -48px;
}
.widget-weather .weather .weather-forecast .icon-weather-4 {
  background-position: 0 -72px;
}
.widget-weather .weather .weather-forecast .icon-weather-5 {
  background-position: 0 -96px;
}
.widget-weather .weather .weather-highlighted {
  height: 105px;
  padding: 10px 25px 12px 35px;
  text-align: left;
}
.widget-weather .weather .weather-highlighted span.today-temp {
  color: #ffffff;
}
.widget-weather .weather .weather-highlighted .icon-weather {
  background: url(images/icons/weather-main.png) no-repeat;
  float: left;
  height: 57px;
  margin-top: 10px;
  width: 76px;
}
.widget-weather .weather .weather-highlighted .inner {
  float: right;
  margin-top: -10px;
  width: 100px;
}
.widget-weather .weather .weather-highlighted strong {
  color: #ffffff;
  display: block;
  font-size: 12px;
  font-size: 46px;
  font-weight: 300;
  line-height: 1.2em;
}
.widget-weather .weather .weather-highlighted .weather-item {
  display: none;
}
.widget-weather .weather .weather-highlighted .weather-item.active {
  display: block;
  margin-top: 10px;
}
.widget-weather .weather .weather-location {
  border-bottom: 1px solid #f2fbfb;
  border-bottom: 1px solid rgba(242, 251, 251, 0.2);
  border-top: 1px solid #f2fbfb;
  border-top: 1px solid rgba(242, 251, 251, 0.2);
  padding: 14px 17px 16px 21px;
  text-align: left;
}
.widget-weather .weather .weather-location .weather-search-form {
  float: right;
  position: relative;
  width: 130px;
}
.widget-weather .weather .weather-location .weather-search-form:before {
  color: #ffffff;
  content: "\e090";
  font-family: 'Simple-Line-Icons' !important;
  left: auto;
  opacity: 0.5;
  position: absolute;
  right: 7px;
  top: 5px;
}
.widget-weather .weather .weather-location .weather-search-form #city-form::-webkit-input-placeholder {
  color: #ffffff;
}
.widget-weather .weather .weather-location .weather-search-form #city-form:-moz-placeholder {
  color: #ffffff;
}
.widget-weather .weather .weather-location .weather-search-form #city-form::-moz-placeholder {
  color: #ffffff;
}
.widget-weather .weather .weather-location .weather-search-form #city-form:-ms-input-placeholder {
  color: #ffffff;
}
.widget-weather .weather .weather-location input.weather-search-field {
  -webkit-box-shadow: none;
  background: #319ba1;
  background: rgba(96, 96, 96, 0.4);
  border: 0 none;
  box-shadow: none;
  color: #ffffff;
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  height: 24px;
  padding: 0 15px 0 5px;
  width: 100%;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.widget-weather .weather .weather-location input.weather-search-field:focus {
  border: 0 none;
}
.widget-weather .weather .weather-location .ie8 input.weather-search-field {
  line-height: 17px;
}
.widget-weather .weather .weather-location input.weather-search-submit {
  background: none;
  height: 19px;
  left: 0;
  padding: 2px 4px;
  position: absolute;
  top: 0;
  width: 19px;
}
.widget-weather .weather .weather-location strong {
  color: #ffffff;
  display: inline-block;
  font-family: 'Lato';
  font-size: 18px;
  font-weight: 100;
  height: 21px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  width: 140px;
}
.widget-weather .weather.panel-content {
  padding: 0 !important;
}
.widget-weather [class^="icon-"]:before {
  color: #ffffff;
  font-family: weather;
  font-size: 40px;
  font-style: normal;
  font-weight: normal;
  line-height: .9;
  margin-left: 0;
}
.widget-weather [class*="icon-"]:before {
  color: #ffffff;
  font-family: weather;
  font-size: 40px;
  font-style: normal;
  font-weight: normal;
  line-height: .9;
  margin-left: 0;
}
.widget-weather .weather .weather-highlighted span.weather-currently,
.widget-weather .weather .weather-highlighted span.one-days-text,
.widget-weather .weather .weather-highlighted span.two-days-text,
.widget-weather .weather .weather-highlighted span.three-days-text,
.widget-weather .weather .weather-highlighted span.four-days-text {
  color: #ffffff;
  font-size: 11px !important;
}
.weather-highlighted span {
  color: #ffffff;
  display: block;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.2em;
}
.weather-forecast span {
  display: block;
  margin-bottom: 5px;
  text-align: center !important;
}
/* CALENDAR WIDGET */
.widget_calendar .multidatepicker .ui-widget-content {
  -moz-box-shadow: none !important;
  -o-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  display: none;
  margin-top: 0;
  padding: 0;
}
.widget_calendar .multidatepicker .ui-widget-content .ie8 #date_departure {
  display: none!important;
}
.widget_calendar .multidatepicker .ui-widget-content .ui-widget:active {
  outline: none;
}
.widget_calendar .multidatepicker .ui-widget-content .ui-widget-header {
  background: none !important;
  border-bottom: 1px solid #ea8164;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin: 0 -10px;
  padding: 0;
  position: relative;
}
.widget_calendar .multidatepicker .ui-widget-content .ui-widget-header .ui-icon {
  display: block;
  overflow: hidden;
  text-indent: -99999px;
}
.widget_calendar .multidatepicker .ui-widget-content .ui-widget-header .ui-state-hover {
  background: #eeeeee;
  color: #88361f;
}
.widget_calendar .multidatepicker .ui-widget-content .ui-widget-header .ui-state-focus {
  background: #eeeeee;
  color: #88361f;
}
.widget_calendar .multidatepicker .ui-widget-content .ui-widget-header .ui-datepicker-prev {
  background-color: transparent!important;
  background-image: url(../images/widgets/pagination.png) !important;
  background-position: 5px 5px!important;
  background-repeat: no-repeat !important;
  border-radius: 50%;
  border: 2px solid #ffffff;
  cursor: pointer;
  height: 24px;
  left: 19px;
  position: absolute;
  top: 25px;
  width: 24px;
}
.widget_calendar .multidatepicker .ui-widget-content .ui-widget-header .ui-datepicker-next {
  background-color: transparent!important;
  background-image: url(../images/widgets/pagination.png) !important;
  background-position: -45px 5px !important;
  background-repeat: no-repeat !important;
  border-radius: 50%;
  border: 2px solid #ffffff;
  cursor: pointer;
  height: 24px;
  left: 19px;
  left: auto;
  position: absolute;
  right: 19px;
  top: 25px;
  width: 24px;
}
.widget_calendar .multidatepicker .ui-widget-content .ui-widget-header .ui-datepicker-title {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  height: 74px;
  line-height: 74px;
  text-align: center;
  text-transform: uppercase;
}
.widget_calendar .multidatepicker .ui-widget-content table {
  border-collapse: collapse;
  width: 100%;
}
.widget_calendar .multidatepicker .ui-widget-content table th {
  padding: .7em .3em;
  text-align: center;
}
.widget_calendar .multidatepicker .ui-widget-content table td {
  padding: 0;
}
.widget_calendar .multidatepicker .ui-widget-content table td .ui-state-focus {
  padding: 0;
}
.widget_calendar .multidatepicker .ui-widget-content table td span {
  border-radius: 50%;
  color: #ffffff;
  display: block;
  font-size: 12px;
  font-weight: 400;
  height: 24px;
  line-height: 24px;
  margin: 2px auto;
  padding: 0;
  text-align: center;
  text-decoration: none;
  width: 24px;
}
.widget_calendar .multidatepicker .ui-widget-content table td span:hover {
  background: #f5f5f5;
  color: #3C3C3C;
}
.widget_calendar .multidatepicker .ui-widget-content table td a {
  border-radius: 50%;
  color: #ffffff;
  display: block;
  font-size: 12px;
  font-weight: 400;
  height: 24px;
  line-height: 24px;
  margin: 2px auto;
  padding: 0;
  text-align: center;
  text-decoration: none;
  width: 24px;
}
.widget_calendar .multidatepicker .ui-widget-content table td a:hover {
  background: #f5f5f5;
  color: #3C3C3C;
}
.widget_calendar .multidatepicker .ui-widget-content table td .ui-state-hover a {
  color: #5a5c5c;
  text-decoration: none;
}
.widget_calendar .multidatepicker .ui-widget-content table td .ui-state-hover a:hover {
  color: #5a5c5c;
  text-decoration: none;
}
.widget_calendar .multidatepicker .ui-widget-content table td .ui-state-disabled {
  cursor: default!important;
}
.widget_calendar .multidatepicker .ui-widget-content table td .ui-state-active a {
  color: #5a5c5c;
  text-decoration: none;
}
.widget_calendar .multidatepicker .ui-widget-content table td .ui-state-active a:link {
  color: #5a5c5c;
  text-decoration: none;
}
.widget_calendar .multidatepicker .ui-widget-content table td .ui-state-active a:visited {
  color: #5a5c5c;
  text-decoration: none;
}
.widget_calendar .multidatepicker .ui-widget-content table td.ui-datepicker-today a {
  background: #ffffff !important;
  color: #5a5c5c;
}
.widget_calendar .multidatepicker .ui-widget-content table td.ui-state-highlight a {
  background: #ffffff !important;
  color: #5a5c5c;
  font-weight: 700;
}
.widget_calendar .multidatepicker .ui-widget-content table td.ui-datepicker-today.ui-state-highlight a {
  background: #ffffff !important;
  color: #5a5c5c;
  font-weight: 700;
}
.widget_calendar .multidatepicker .ui-widget-content table td.ui-datepicker-other-month span {
  color: #5a5c5c;
}
.widget_calendar .multidatepicker .ui-widget-content table thead th {
  color: #ffffff;
  font-size: 10px;
  font-weight: 700;
  padding: 30px 0 10px;
  text-transform: uppercase;
}
.widget_calendar .multidatepicker .ui-widget-content table tbody td {
  padding: 0;
  text-align: center;
}
.widget_calendar .multidatepicker .ui-widget-content table tbody td.ui-state-disabled {
  padding: 0;
  text-align: center;
}
/* WIDGET SLIDER */
.widget_slider {
  padding: 20px 40px;
  text-align: center;
  position: relative;
}
.widget_slider p {
  text-align: center;
}
/* WIDGET SLIDER IMAGE */
.widget-slider-img {
  overflow: hidden;
  padding: 0;
  position: relative;
}
.widget-slider-img .text {
  bottom: 0;
  color: #ffffff;
  font-size: 16px;
  position: absolute;
  right: 12px;
  z-index: 4;
}
.widget-slider-img .slick-dots {
  bottom: 5px;
}
.widget-slider-img .slick-slider {
  margin-bottom: 0;
}
.widget-slider-img .slide:before {
  background-image: url('../images/gradient-big.png');
  background-repeat: repeat-x;
  bottom: 0;
  content: " ";
  height: 70px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 2;
}
.widget-slider-img .slide p {
  font-family: 'Lato', arial;
  font-weight: 100;
}
.widget-slider-img .text-top .text {
  bottom: auto;
  top: 0;
}
.widget-slider-img .text-top .slide:before {
  background-image: url('../images/gradient-big.png');
  bottom: auto;
  filter: FlipV;
  height: 30px;
  top: 0;
  -ms-filter: "FlipV";
  -moz-transform: scale(-1);
  -o-transform: scale(-1);
  -webkit-transform: scale(-1);
  transform: scale(-1);
}
/* WiIDGET PROGRESS BAR */
.widget-progress-bar .title {
  color: rgba(0, 0, 0, 0.6);
  float: left;
  font-family: 'Open Sans';
  font-size: 13px;
  margin-bottom: 5px;
  margin-top: 0;
  opacity: 1;
  text-transform: none;
}
.widget-progress-bar .number {
  color: rgba(0, 0, 0, 0.5);
  float: right;
  font-family: 'Open Sans';
  font-size: 11px;
  font-size: 13px;
  margin-bottom: 5px;
  margin-top: 0;
  margin-top: 2px;
  opacity: 1;
  text-transform: none;
}
.widget-progress-bar .progress {
  background: rgba(0, 0, 0, 0.1);
  height: 6px;
}
/* WIDGET NEWS */
.widget-news.panel-content {
  padding: 0 !important;
}
.widget-news .message-item {
  border-bottom: 1px solid #ECEBEB !important;
  border: none;
  display: block;
  margin: 0;
  padding: 10px 15px 0;
}
.widget-news .pull-left i {
  font-size: 24px;
  display: block;
  margin-top: 7px;
}
.media-body p {
  height: 27px;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* WIDGET TABLE */
.widget-table {
  padding: 0 !important;
}
.widget-table table {
  margin-bottom: 0;
}
.widget-table table thead {
  border-top: 1px solid #ededed;
}
.widget-table table thead th {
  text-align: left;
  text-transform: none;
}
.widget-table table tbody th {
  text-align: left;
}
/* WIDGET FULL WIDTH */
.panel-content.widget-full {
  overflow: hidden;
  padding: 0 !important;
}
.widget-full .tabs-linetriangle .nav.nav-tabs > li > a {
  font-weight: 400;
  text-transform: none;
}
.widget-full .tabs-linetriangle .nav-tabs a span {
  text-align: center;
}
.widget-full .tabs-linetriangle .nav-tabs a span.title {
  font-weight: 600;
  text-transform: uppercase;
}
/* WIDGET STOCK */
.stock1 .tab-content {
  min-height: 316px;
}
.widget-stock .tab_right .nav-tabs li a {
  opacity: 0.5;
  padding: 15px 25px;
  text-align: left;
  width: 200px;
}
.widget-stock .tab_right .nav-tabs li a span {
  display: block;
}
.widget-stock .tab_right .nav-tabs li:hover a {
  opacity: 0.7;
}
.widget-stock .tab_right .nav-tabs li.active a {
  opacity: 1;
}
.widget-stock .tab_right .nav-tabs a span.title {
  font-weight: 600;
  text-transform: uppercase;
}
.widget-stock .title-stock h1 {
  float: left;
  padding: 10px;
}
.widget-stock .title-stock span {
  float: right;
  font-size: 18px;
  padding: 10px;
}
.widget-stock .company-info {
  color: #8D8D8D;
  display: block;
  padding-top: 15px;
}
.stock2 .tab-content {
  min-height: 442px;
}
/* WIDGET SMALL */
.widget-small {
  border: none !important;
  max-height: 160px;
  overflow: hidden;
  padding: 0 !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.widget-small .title {
  padding: 10px;
}
.widget-small .title h1 {
  font-size: 20px !important;
  margin-bottom: 0;
}
.widget-small .title p {
  margin-bottom: 0;
  opacity: 0.8;
  padding-top: 5px;
}
.widget-small .title span {
  font-family: 'Lato', arial, sans-serif;
  font-weight: 900;
  opacity: 0.7;
}
.widget-small .highcharts-container {
  margin-top: -98px;
}
/* WIDGET CASH IN HAND */
.widget-cash-in-hand {
  float: left;
}
.widget-cash-in-hand .cash {
  border-right: 2px dashed rgba(0, 0, 0, 0.1);
  float: left;
  padding: 5px 20px;
  text-align: center;
}
.widget-cash-in-hand .cash:last-child {
  border-right: none;
}
.widget-cash-in-hand .cash .number {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.widget-cash-in-hand .cash .txt {
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
}
@media and (max-width: 800px) {
  .widget-cash-in-hand .row .cash {
    width: 33.3333%;
  }
  .widget-cash-in-hand .row .cash:nth-child(3) {
    border-right: none;
  }
  .widget-cash-in-hand .row .cash .number {
    font-size: 14px;
  }
  .widget-cash-in-hand .row .cash .txt {
    font-size: 11px;
  }
}
/* WIDGET CLOCK */
.jquery-clock {
  -webkit-background-size: cover;
  background-image: url(../images/clock/clock.png);
  background-size: cover;
  height: 600px;
  list-style: none;
  margin: auto;
  padding: 0;
  position: relative;
  width: 600px;
}
.jquery-clock .jquery-clock-sec {
  -webkit-background-size: contain;
  background-image: url(../images/clock/sechand.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 45%;
  position: absolute;
  top: 0;
  width: 10%;
  z-index: 3;
}
.jquery-clock .jquery-clock-min {
  -webkit-background-size: contain;
  background-image: url(../images/clock/minhand.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 45%;
  position: absolute;
  top: 0;
  width: 10%;
  z-index: 2;
}
.jquery-clock .jquery-clock-hour {
  -webkit-background-size: contain;
  background-image: url(../images/clock/hourhand.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 45%;
  position: absolute;
  top: 0;
  width: 10%;
  z-index: 1;
}
.jquery-clock .jquery-clock-pin {
  background: #fff;
  border-radius: 50%;
  height: 3%;
  left: 50%;
  margin: -1.5%;
  position: absolute;
  top: 50%;
  width: 3%;
  z-index: 10;
}
.jquery-clock .jquery-clock-transitions {
  -webkit-transition: all 1s cubic-bezier(0.01, 1.81, 0, 0.88);
  -moz-transition: all 1s cubic-bezier(0.01, 1.81, 0, 0.88);
  -o-transition: all 1s cubic-bezier(0.01, 1.81, 0, 0.88);
  -ms-transition: all 1s cubic-bezier(0.01, 1.81, 0, 0.88);
  transition: all 1s cubic-bezier(0.01, 1.81, 0, 0.88);
}
.jquery-clock.medium {
  height: 300px;
  width: 300px;
}
.jquery-clock.small {
  height: 200px;
  width: 200px;
}
.jquery-clock.white {
  background-image: url(../images/clock/clock-white.png);
}
.jquery-clock.white .jquery-clock-sec {
  background-image: url(../images/clock/sechand-white.png);
  z-index: 3;
}
.jquery-clock.white .jquery-clock-min {
  background-image: url(../images/clock/minhand-white.png);
  z-index: 2;
}
.jquery-clock.white .jquery-clock-hour {
  background-image: url(../images/clock/hourhand-white.png);
  z-index: 1;
}
.jquery-clock.white .jquery-clock-pin {
  background-color: #000;
}
/* WIDGET INFOBOX */
.widget-infobox {
  float: left;
  margin-top: -8px;
}
.widget-infobox .infobox {
  background: #ffffff;
  float: left;
  margin-right: 10px;
  min-width: 165px;
  padding: 8px;
  text-align: center;
  margin-bottom: 10px;
}
.widget-infobox .left {
  float: left;
  padding-left: 0;
  padding-right: 10px;
}
.widget-infobox .left i {
  color: #ffffff;
  font-size: 23px;
  text-align: center;
}
.widget-infobox .left i::before {
  display: inline-block;
  height: 48px;
  margin-top: 0;
  padding: 13px;
  width: 48px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.widget-infobox .right {
  float: left;
  padding-left: 0;
  padding-right: 10px;
}
.widget-infobox .right .pull-left {
  font-family: 'Lato', 'Open Sans', Arial;
  font-weight: 900;
  font-size: 22px;
}
.widget-infobox .right .pull-right {
  font-family: 'Lato', 'Open Sans', Arial;
  font-weight: 100;
  font-size: 14px;
  margin-top: 7px;
}
.widget-infobox .left[class*="icon-"] {
  font-size: 21px;
}
.widget-infobox .txt {
  float: left;
  min-width: 80px;
}
/* WIDGET INFO */
.widget-info {
  padding-top: 20px;
}
.widget-info .left {
  float: left;
  padding-left: 10px;
  width: 60px;
}
.widget-info .left i {
  color: #ffffff;
  font-size: 23px;
  text-align: center;
}
.widget-info .left i::before {
  display: inline-block;
  height: 48px;
  margin-top: 0;
  padding: 13px;
  width: 48px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.widget-info .left i[class*="icon-"] {
  font-size: 22px;
}
.widget-info .right {
  float: left;
  padding-left: 10px;
}
.widget-info .right .number {
  font-family: 'Lato', 'Open Sans', Arial;
  font-weight: 900;
  font-size: 22px;
  margin-bottom: 2px;
}
.widget-info .right .text {
  color: #A3A3A3;
  font-size: 12px;
  text-align: left;
  text-transform: uppercase;
}
/* WIDGET QUICKLINK */
.quick-link {
  float: left;
  padding: 5px 15px;
  width: 80px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.quick-link:hover {
  background: #F7F7F7;
  cursor: pointer;
}
.quick-link:hover .text {
  color: #646464;
}
.quick-link .icon {
  margin: auto;
  text-align: center;
  width: 60px;
}
.quick-link .icon i {
  color: #ffffff;
  font-size: 27px;
  padding-right: 0 !important;
  text-align: center;
}
.quick-link .icon i::before {
  display: inline-block;
  height: 55px;
  margin-top: 0;
  padding: 13px;
  width: 55px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.quick-link .text {
  color: #A3A3A3;
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
}
/* WIDGET USER */
.widget-member {
  border: 1px solid #EBEBEB;
  margin-bottom: 10px;
  margin-top: 15px;
  padding: 15px 15px 5px 15px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.widget-member img {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.widget-member .member-name {
  margin-bottom: 5px !important;
}
.widget-member .member-job {
  color: #ADADAD;
  margin-bottom: 6px;
  margin-top: 0;
}
.widget-member .col-xlg-6 {
  font-size: 12px;
}
.widget-member .col-xlg-6 p {
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.widget-member2 {
  border: 1px solid #EBEBEB;
  margin-bottom: 10px;
  margin-top: 15px;
  padding: 15px 15px 5px 15px;
  padding: 15px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.widget-member2 img {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.widget-member2 .member-name {
  margin-bottom: 15px !important;
  margin-bottom: 5px !important;
}
.widget-member2 .member-name span {
  padding-left: 8px;
  text-transform: none;
}
.widget-member2 .member-job {
  color: #ADADAD;
  margin-bottom: 6px;
  margin-top: 0;
}
.widget-member2 .col-xlg-6 p {
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.widget-member2 .col-lg-3 {
  padding-right: 0;
}
.widget-member2 .col-xlg-4 p {
  font-size: 12px;
}
.widget-member3 {
  border: 1px solid #EBEBEB;
  margin-bottom: 10px;
  margin-top: 15px;
  padding: 15px 15px 5px 15px;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.widget-member3 img {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.widget-member3 .member-name {
  margin-bottom: 5px !important;
}
.widget-member3 .member-job {
  color: #ADADAD;
  margin-bottom: 6px;
  margin-top: 0;
}
.widget-member3 .col-xlg-12 {
  font-size: 12px;
}
.widget-member3 .col-xlg-12 p {
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.profil-name-heading {
  color: #ffffff;
  position: absolute;
  top: 40px;
  width: 100%;
}
.profil-name-heading div {
  text-align: center;
  float: none;
}
#modal-icons i {
  cursor: pointer !important;
}
.widget-profil-img-center {
  border: 10px solid #ffffff;
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.3);
  margin-top: -40px;
}
.hover-effect {
  overflow: hidden;
}
.hover-effect .hover-img {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.hover-effect:hover .hover-img {
  -webkit-filter: contrast(120%);
  filter: contrast(120%);
}
/* WIDGET MAP */
.widget-map {
  height: 440px;
  overflow: hidden;
}
.widget-map .col-md-3 {
  background-color: rgba(32, 34, 38, 0.5);
  color: #CDCDCD !important;
  height: 440px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  position: absolute;
  right: 0;
  top: 0;
}
.widget-map .panel-header {
  border: none !important;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.widget-map .panel-content {
  border: none !important;
  height: 440px;
  overflow: hidden;
}
.widget-map .amcharts-chart-div a {
  display: none !important;
}
.widget-map .ammapObjectList ul {
  list-style-type: none;
}
.widget-map .ammapObjectList ul li:not(.paginate_button) {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.widget-map .ammapObjectList li {
  list-style-type: none;
}
.widget-map .listdiv {
  height: 250px;
  overflow: hidden;
}
.maximized.widget-map {
  height: 100%;
}
.maximized.widget-map .panel-content {
  height: 100%;
}
.maximized #map {
  height: 100%;
  width: 100%;
}
.bg-dark .ammapObjectList a {
  color: #D0D0D0;
  padding: 2px 15px;
}
.bg-dark .ammapObjectList a:hover {
  background: rgba(32, 34, 38, 0.5);
  color: #319DB5;
}
.map-marker {
  margin-left: -8px;
  margin-top: -8px;
}
.map-marker.map-clickable {
  cursor: pointer;
}
.pulse-map {
  background-color: #716f42;
  border: 5px solid #f7f14c;
  height: 20px;
  position: absolute;
  width: 20px;
  z-index: 10;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
.dot-map {
  -moz-animation-iteration-count: infinite;
  -moz-animation: pulsemap 3s ease-out;
  -moz-border-radius: 60px;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation: pulsemap 3s ease-out;
  -webkit-border-radius: 60px;
  background: transparent;
  border-radius: 60px;
  border: 10px solid #fff601;
  height: 50px;
  left: -15px;
  opacity: 0;
  position: absolute;
  top: -15px;
  width: 50px;
  z-index: 1;
}
@-moz-keyframes pulsemap {
  0% {
    -moz-transform: scale(0);
    opacity: 0;
  }
  25% {
    -moz-transform: scale(0);
    opacity: 0.1;
  }
  50% {
    -moz-transform: scale(0.1);
    opacity: 0.3;
  }
  75% {
    -moz-transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    -moz-transform: scale(1);
    opacity: 0;
  }
}
@-webkit-keyframes pulsemap {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale(0);
    opacity: 0.1;
  }
  50% {
    -webkit-transform: scale(0.1);
    opacity: 0.3;
  }
  75% {
    -webkit-transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
/*------------------------------------------------------------------------------------*/
/*------------------------------------- PAGES ----------------------------------------*/
/**** Page Animations *****/
#animationSandbox {
  display: block;
  overflow: hidden;
}
#animationSandbox h1 {
  font-size: 46px;
  text-align: center;
}
.font-animation {
  padding: 10px 0;
}
.font-animation a {
  color: #121212;
  font-size: 16px;
}
.font-animation a i {
  color: #319DB5;
}
.font-animation a:hover {
  text-decoration: none;
}
.animation_title {
  font-family: 'Open Sans', verdana, arial;
  font-size: 6rem;
}
/****  404 and 500 Error Page  ****/
.error-page {
  background: -moz-radial-gradient(center, ellipse cover, #dfe5e9 2%, #dfe5e9 2%, #b2c0ca 100%);
  background: -ms-radial-gradient(center, ellipse cover, #dfe5e9 2%, #dfe5e9 2%, #b2c0ca 100%);
  background: -o-radial-gradient(center, ellipse cover, #dfe5e9 2%, #dfe5e9 2%, #b2c0ca 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(2%, #dfe5e9), color-stop(2%, #dfe5e9), color-stop(100%, #b2c0ca));
  background: -webkit-radial-gradient(center, ellipse cover, #dfe5e9 2%, #dfe5e9 2%, #b2c0ca 100%);
  background: radial-gradient(ellipse at center, #dfe5e9 2%, #dfe5e9 2%, #b2c0ca 100%);
  background: #dfe5e9;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#dfe5e9', endColorstr='#b2c0ca', GradientType=1);
  overflow: hidden;
  padding: 0;
}
.error-page h1 {
  font-family: 'Open Sans';
  font-size: 120px;
  font-weight: 600;
  height: 132px;
  min-width: 30px;
  text-align: center;
}
.error-page a {
  color: #2B2E33;
}
.error-page a:hover {
  color: #616873;
  text-decoration: none;
}
.error-page .footer {
  bottom: 30px;
  position: absolute;
  width: 100%;
}
.error-page .copyright {
  font-size: 12px;
  text-align: center;
}
.error-page .btn {
  padding: 8px 50px;
}
.error-page .col-md-12 {
  text-align: center !important;
}
.error-page .col-md-6 {
  text-align: center !important;
}
.error-page p {
  text-align: center !important;
}
.error-page .btn-group .btn {
  padding: 8px 10px;
}
.error-main {
  color: #2B2E33;
  margin-top: 20%;
  text-align: center;
}
.404-txt {
  height: 20px;
}
.500-txt {
  height: 20px;
}
#content-404 {
  display: none;
}
#content-500 {
  display: none;
}
@media (max-width: 1505px) {
  .btn.btn-home {
    display: none;
  }
}
@media (max-width: 450px) {
  .btn.btn-message {
    display: none;
  }
}
/****  Login Page, Sign Up Page, Password Recover Page, Lockscreen Page  ****/
.account {
  background: #1f3649;
  background: -moz-radial-gradient(center, ellipse cover, #1f3649 0%, #17253d 44%, #040d11 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, #1f3649), color-stop(44%, #17253d), color-stop(100%, #040d11));
  background: -webkit-radial-gradient(center, ellipse cover, #1f3649 0%, #17253d 44%, #040d11 100%);
  background: -o-radial-gradient(center, ellipse cover, #1f3649 0%, #17253d 44%, #040d11 100%);
  background: -ms-radial-gradient(center, ellipse cover, #1f3649 0%, #17253d 44%, #040d11 100%);
  background: radial-gradient(ellipse at center, #1f3649 0%, #17253d 44%, #040d11 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@account-color-dark', endColorstr='#040d11', GradientType=1);
}
.account a {
  color: #D1D1D1;
  font-size: 14px;
}
.account a:hover {
  color: #ffffff;
  text-decoration: none;
}
.account .backstretch:before {
  background-color: rgba(15, 15, 15, 0.6);
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
}
.account .form-signin {
  margin: 0 auto;
  max-width: 330px;
}
.account .form-signin .form-control:focus {
  z-index: 2;
}
.account .form-signin input[type="text"] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: -1px;
}
.account .form-signin input[type="password"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-bottom: 10px;
}
.account .account-wall {
  margin-top: 160px;
  padding: 20px 20px 10px;
  position: relative;
}
.account .login-title {
  color: #555555;
  display: block;
  font-size: 22px;
  font-weight: 400;
}
.account .profile-img {
  display: block;
  height: 96px;
  margin: 0 auto 10px;
  width: 96px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.account .select-img {
  display: block;
  height: 75px;
  margin: 0 30px 10px;
  width: 75px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.account .select-name {
  display: block;
  margin: 30px 10px 10px;
}
.account .logo-img {
  display: block;
  height: 96px;
  margin: 0 auto 10px;
  width: 96px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.account .btn.btn-lg {
  font-size: 15px;
  padding: 8px 48px 6px;
}
.account .btn.btn-lg.btn-fb {
  font-size: 15px;
  padding: 8px 48px 0px;
}
.account .user-img {
  color: #ffffff;
  display: block;
  font-size: 75px;
  left: 0;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: -80px;
}
.account .social-btn {
  padding-top: 50px;
}
.account .btn-block i {
  margin-top: .1em;
}
.account .form-signup {
  margin: 0 auto;
  max-width: none;
}
.account .form-signup .form-control:focus {
  z-index: 2;
}
.account .form-signup input[type="text"] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: -1px;
}
.account .form-signup input[type="password"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-bottom: 10px;
}
.account .form-signup #submit-form {
  display: block;
  margin: auto;
}
.account .form-signup .social-btn .btn {
  font-size: 15px;
  height: 35px;
  padding: 8px 20px;
}
.account .form-signup .terms {
  color: #ffffff;
  margin-bottom: 10px;
  overflow: hidden;
  padding-top: 5px;
}
.account .form-password {
  display: none;
}
.account #lockscreen-block .account-wall {
  margin-top: 240px;
  width: 1100px;
}
.account #lockscreen-block .user-image {
  float: left;
  position: relative;
  width: 25%;
}
.account #lockscreen-block .user-image img {
  border: 3px solid rgba(255, 255, 255, 0.2);
  display: block;
  float: right;
  margin-top: -10px;
  max-width: 132px;
}
.account #lockscreen-block .form-signin {
  float: left;
  padding-left: 30px;
  position: relative;
  width: 75%;
}
.account #lockscreen-block h2 {
  color: #ffffff;
  font-family: 'Lato', arial;
  font-weight: 100;
  margin-top: 0;
}
.account #lockscreen-block p {
  color: #CFCFCF;
  color: #ffffff;
}
.account #lockscreen-block .input-group .form-control {
  -webkit-border-radius: 17px 0 0 17px;
  -moz-border-radius: 17px 0 0 17px;
  border-radius: 17px 0 0 17px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.account #lockscreen-block .input-group .btn {
  -webkit-border-radius: 0 17px 17px 0;
  -moz-border-radius: 0 17px 17px 0;
  border-radius: 0 17px 17px 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.account #lockscreen-block #loader {
  left: auto;
  margin: auto;
  position: absolute;
  right: -1px;
  top: -11px;
  width: 133px;
}
.account2 {
  background: #1f3649;
  background: -moz-radial-gradient(center, ellipse cover, #1f3649 0%, #17253d 44%, #040d11 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, #1f3649), color-stop(44%, #17253d), color-stop(100%, #040d11));
  background: -webkit-radial-gradient(center, ellipse cover, #1f3649 0%, #17253d 44%, #040d11 100%);
  background: -o-radial-gradient(center, ellipse cover, #1f3649 0%, #17253d 44%, #040d11 100%);
  background: -ms-radial-gradient(center, ellipse cover, #1f3649 0%, #17253d 44%, #040d11 100%);
  background: radial-gradient(ellipse at center, #1f3649 0%, #17253d 44%, #040d11 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@account-color-dark', endColorstr='#040d11', GradientType=1);
}
.account2 .form-password {
  display: none;
}
.account2 .form-password h3 {
  font-family: 'Lato';
  font-weight: 100;
  font-size: 15px;
  margin-bottom: 20px;
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;
}
.account2 .container {
  width: 100%;
}
.account2 .user-img {
  color: #ffffff;
  display: block;
  font-size: 75px;
  margin-bottom: -15px;
  margin-top: 50px;
  position: relative;
  text-align: center;
  top: 0;
}
.account2 .account-info {
  display: none;
}
.account2 .account-form {
  background: #ffffff;
  display: block;
  padding: 30px;
  position: relative;
  padding-bottom: 20px;
  overflow: hidden;
}
.account2 .account-form a {
  color: #A7A7A7;
}
.account2 .account-form a:hover {
  color: #838383;
}
.account2 .account-form .forgot-password {
  padding-top: 4px;
}
.account2 .form-signin input[type="password"] {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.account2 .form-signin h3 {
  font-family: 'Lato';
  font-weight: 100;
  font-size: 15px;
  margin-bottom: 20px;
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;
}
.account2 .form-signin h3 strong {
  font-family: 'Lato', arial, sans-serif;
  font-weight: 900;
}
.account2 .form-signup input[type="password"] {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.account2 .form-signup h3 {
  font-family: 'Lato';
  font-weight: 100;
  font-size: 15px;
  margin-bottom: 20px;
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;
}
.account2 .form-signup h3 strong {
  font-family: 'Lato', arial, sans-serif;
  font-weight: 900;
}
.account2 .form-footer {
  background: #F4F4F4;
  border-top: 1px solid #dddddd;
  margin: 30px -30px -30px -30px;
  padding: 20px 30px;
}
.account2 .social-btn {
  padding-top: 0;
}
.account2 .btn.btn-lg {
  font-size: 15px;
  padding: 8px 48px 6px;
}
.account2 .btn.btn-lg.btn-fb {
  font-size: 15px;
  padding: 8px 48px 6px;
}
.account2 .forgot-password {
  float: right;
  margin-top: 5px;
}
.account2 .new-here {
  float: right;
  margin-bottom: 0;
  margin-top: 10px;
}
.account.separate-inputs .form-signin input[type="text"] {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-bottom: 8px;
}
.account.separate-inputs .form-signin input[type="password"] {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.account.separate-inputs .form-signup input[type="text"] {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-bottom: 8px;
}
.account.separate-inputs .form-signup input[type="email"] {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-bottom: 8px;
}
.account.separate-inputs .form-signup input[type="password"] {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.account.no-social .social-btn {
  display: none;
}
.account.boxed .account-wall {
  background-color: #ffffff;
  opacity: 1;
}
.account.boxed a {
  color: #838383;
}
.account.boxed a:hover {
  color: #4D4D4D;
}
.account.boxed .form-signup .terms {
  color: #454545;
}
.account.no-user-image .user-img {
  display: none;
}
.account-copyright {
  bottom: 10px;
  color: #BDBDBD;
  font-family: 'Open Sans';
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
}
.account.no-terms .form-signup .terms {
  display: none;
}
.account2 .form-signin input[type="text"],
.account2 .form-signin input[type="email"],
.account2 .form-signup input[type="text"],
.account2 .form-signup input[type="email"] {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-bottom: 8px;
}
#lockscreen-block #account-builder {
  bottom: 60px;
  left: 50%;
  margin-left: -125px;
  margin-top: 20%;
  position: absolute;
}
.separate-inputs.account #lockscreen-block .input-group {
  border-spacing: 10px 0;
  margin-left: -10px;
}
.separate-inputs.account #lockscreen-block .input-group .form-control {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.separate-inputs.account #lockscreen-block .input-group .btn {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
#account-builder {
  -moz-border-bottom-left-radius: 0;
  -moz-border-top-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  background: #F6F8F8!important;
  border-bottom-left-radius: 0;
  border-color: #DEE5E7;
  border-radius: 20px;
  border-top-right-radius: 0;
  height: 44px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  max-width: 500px;
  overflow: hidden;
  padding: 0;
  padding: 10px 20px 10px 10px;
  width: 250px;
}
#account-builder i {
  -moz-animation: fa-spin 3s infinite linear;
  -o-animation: fa-spin 3s infinite linear;
  -webkit-animation: fa-spin 3s infinite linear;
  animation: fa-spin 3s infinite linear;
  float: left;
  font-size: 24px;
  padding-right: 0;
}
#account-builder:hover i {
  -moz-animation: none;
  -o-animation: none;
  -webkit-animation: none;
  animation: none;
}
#account-builder h3 {
  float: left;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  margin-top: 4px;
  padding-left: 8px;
  text-transform: uppercase;
}
#account-builder .form-horizontal .row .col-md-12 {
  cursor: pointer;
  margin-left: 0;
}
#account-builder .form-group {
  margin-bottom: 8px;
}
#account-builder .form-group label:not(.switch) {
  margin-top: 1px;
}
@media (min-width: 768px) {
  .account2 .container {
    height: 100%;
    margin: auto;
    position: relative;
    width: 670px;
  }
  .account2 .account-info {
    background: #319DB5;
    color: #ffffff;
    display: block;
    display: table-cell;
    padding: 30px;
    text-align: left;
    vertical-align: top;
    width: 43%;
  }
  .account2 .account-info .logo {
    color: #ffffff;
    font-family: 'Lato', arial, sans-serif;
    font-weight: 900;
    font-size: 25px;
    background: url(../images/logo/logo-white.png) no-repeat;
    height: 31px;
    margin-bottom: 15px;
    display: block;
  }
  .account2 .account-info h3 {
    font-family: 'Open Sans';
    font-size: 17px;
    margin-top: 5px;
  }
  .account2 .account-info ul {
    margin: 30px 0 0;
    padding: 0;
  }
  .account2 .account-info ul li {
    font-size: 15px;
    list-style: none;
    margin: 0;
    padding: 6px 0;
  }
  .account2 .account-info ul li i {
    padding-right: 8px;
  }
  .account2 .account-form {
    display: table-cell;
    vertical-align: top;
    width: 57%;
  }
}
@media (max-width: 800px) {
  .account #lockscreen-block .user-image {
    float: none;
    margin: auto;
    margin-bottom: 20px;
    width: auto;
  }
  .account #lockscreen-block .user-image img {
    display: block;
    float: none;
    margin: auto;
    text-align: center;
  }
  .account #lockscreen-block .form-signin {
    float: none;
    margin: auto;
    margin-bottom: 20px;
    padding-left: 0;
    text-align: center;
    width: 100%;
  }
  .account #lockscreen-block .form-signin p {
    float: none;
    margin-bottom: 20px;
    padding-left: 0;
    text-align: center;
    width: 100%;
  }
  .account #lockscreen-block .account-wall {
    margin-top: 20%;
    width: 100%;
  }
  .account #lockscreen-block #loader {
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0px;
    width: 133px;
  }
}
/**** Page Layout ****/
.layouts .toggle-layout:hover,
.layouts .toggle-layout,
.layouts .toggle-layout:hover,
.layouts .toggle-layout:focus {
  text-decoration: none !important;
}
.layouts .toggle-layout > div {
  color: #4A4A4A;
  font-family: 'Open Sans';
  font-size: 14px;
  margin-top: 5px;
  text-decoration: none !important;
}
.layouts .toggle-layout > div strong {
  font-family: 'Open Sans';
  font-weight: 600;
}
.layout-options a {
  display: block;
  position: relative;
}
.layout-options img {
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
  border: 2px solid #ffffff;
  float: left;
  text-align: left;
  transition: all 200ms ease-in-out;
}
.layout-options img:hover {
  border: 2px dashed #BBCACE;
}
.layout-txt {
  bottom: 10px;
  font-size: 20px;
  left: 10px;
  margin: auto;
  position: absolute;
  width: 100%;
}
body:not(.rtl) .layout-options .img-sidebar-left {
  display: block;
}
body:not(.rtl) .layout-options .img-sidebar-right {
  display: none;
}
/**** Page Contact ****/
.page-contact .map-contact {
  height: 330px;
  margin-left: -50px;
  margin-right: -50px;
  margin-top: -20px;
  overflow: hidden;
}
.page-contact .btn {
  display: block;
  margin: auto;
}
.page-contact h3 {
  font-family: 'Lato', arial;
  font-weight: 900;
  font-weight: 600;
}
@media (max-width: 1500px) {
  .page-contact h1 {
    font-size: 28px;
  }
}
/**** Page Gallery Sortable ****/
.portfolioFilter {
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 15px;
}
.portfolioFilter a {
  color: #666666;
  margin-right: 10px;
  text-decoration: none;
}
.portfolioFilter a.current {
  font-weight: bold;
}
.portfolioContainer {
  list-style: none;
  margin-left: -10px;
  margin: 0;
  overflow: hidden;
  padding: 5px 0 0 0;
  text-align: center;
  width: 100%;
}
.portfolioContainer p {
  color: #ffffff;
}
.portfolioContainer figure {
  background: #3085a3;
  cursor: pointer;
  display: inline-block;
  height: 330px;
  margin: 0.5%;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 32%;
}
.portfolioContainer figure img {
  display: block;
  min-height: 100%;
  opacity: .8;
  position: relative;
}
.portfolioContainer figure figcaption {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  color: #ffffff;
  font-size: 1.25em;
  height: 100%;
  left: 0;
  padding: 2em;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  width: 100%;
}
.portfolioContainer figure figcaption::before {
  pointer-events: none;
}
.portfolioContainer figure figcaption::after {
  pointer-events: none;
}
.portfolioContainer figure a {
  font-size: 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  text-indent: 200%;
  top: 0;
  white-space: nowrap;
  width: 100%;
  z-index: 1000;
}
.portfolioContainer figure h2 {
  font-weight: 300;
  margin: 0;
  word-spacing: -0.15em;
}
.portfolioContainer figure h2 span {
  font-weight: 800;
}
.portfolioContainer figure p {
  font-size: 68.5%;
  letter-spacing: 1px;
  margin: 0;
}
/**** Page Google Maps ****/
.map-panel .panel-body {
  padding: 0;
}
.map-panel #instructions li {
  color: #6175A0;
  display: block;
  height: 21px;
  padding-left: 15px;
}
.map {
  display: block;
  height: 350px;
  margin: 0 auto;
  width: 100%;
}
.overlay_arrow {
  height: 0;
  left: 50%;
  margin-left: -16px;
  position: absolute;
  width: 0;
}
.overlay_arrow.above {
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #336699;
  bottom: -15px;
}
.overlay_arrow.below {
  border-bottom: 16px solid #336699;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  top: -15px;
}
.map.large {
  height: 500px;
}
.map_geocoding {
  background-color: #ffffff;
  left: 20%;
  padding: 20px;
  position: absolute;
  top: 5px;
  width: 50%;
}
/**** Page Vector Maps ****/
#map {
  height: 460px;
  width: 100%;
}
#world-map {
  height: 460px;
  width: 100%;
}
#usa-map {
  color: #ffffff;
  height: 500px;
  width: 100%;
}
#flight-map {
  height: 460px;
  width: 100%;
}
#pattern-map {
  height: 460px;
  width: 100%;
}
.amcharts-map-line {
  -webkit-animation: am-moving-dashes 3s linear infinite;
  animation: am-moving-dashes 3s linear infinite;
  stroke-dasharray: 2px 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.amcharts-map-image-london {
  -webkit-animation: am-pulsating 1s ease-out infinite;
  animation: am-pulsating 1s ease-out infinite;
}
.amcharts-map-area {
  -webkit-transition: all 0.3s 0.3s ease-out;
  transition: all 0.3s 0.3s ease-out;
}
.amcharts-map-area:hover {
  -webkit-transition: all 0.3s ease-out;
  fill: #93c765;
  stroke: #93c765;
  transition: all 0.3s ease-out;
}
/**** Page Icons ****/
.page-icons [class*="fa-"],
.page-icons [class*="icons-"],
.page-icons [class*="glyphicon-"],
.page-icons [class*="wi-"] {
  font-size: 20px;
}
.page-icons .col-xs-2 {
  height: 30px;
}
.page-icons .col-xs-2 span {
  display: none;
}
.page-icons #finder {
  margin-bottom: 60px;
}
.page-icons #results .col-sm-4 {
  min-height: 40px;
}
.page-icons #results .col-sm-4 i {
  padding-right: 10px;
}
.page-icons .slick-slide i {
  color: #C0C0C0;
  font-size: 20px;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  -ms-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.page-icons .slick-slide.slick-center i {
  color: #383838;
  font-size: 40px;
}
.page-icons [class*="icon-rounded-"] {
  font-size: 30px;
}
/**** Page Helper Classes ****/
.page-helper table thead th {
  font-size: 12px;
  text-align: left;
}
.page-helper table tbody > tr > td > div {
  border: 1px solid #ededed;
  height: 80px;
  line-height: 80px;
  position: relative;
  text-align: center;
  width: 80px;
}
.page-helper table tbody > tr > td > div > div:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 1px;
}
.page-helper table.helper-margin tbody > tr > td > div > div {
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  line-height: normal;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  vertical-align: middle;
}
.page-helper table.helper-margin tbody > tr > td > div {
  background-color: #319DB5;
}
.page-helper table.helper-padding tbody > tr > td > div > div {
  background-color: #ffffff;
  height: 100%;
  line-height: normal;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}
.page-helper table.helper-padding tbody > tr > td > div {
  background-color: #B066FF;
}
.page-helper table.helper-borders tbody > tr > td > div {
  background-color: #EFEFEF;
  border-color: #444444 !important;
  border: none;
}
.page-helper table.helper-text .preview {
  max-width: 300px;
}
.page-helper table.helper-text tbody > tr > td {
  max-width: 300px;
}
.page-helper table.helper-text tbody > tr > td > div {
  background-color: #F2F2F2;
  border: 1px solid #ededed;
  height: auto;
  line-height: normal;
  max-width: 300px;
  min-height: 40px;
  padding: 10px;
  width: auto;
}
.page-helper table.helper-text tbody > tr > td > div p {
  margin-bottom: 0;
  text-align: center;
}
.page-helper table.helper-text-color tbody > tr > td > div {
  background-color: #ffffff;
  border: 1px solid #ededed;
  height: auto;
  line-height: normal;
  max-width: 300px;
  min-height: 40px;
  padding: 10px;
  width: auto;
}
.page-helper table.helper-text-color tbody > tr > td > div p {
  margin-bottom: 0;
  text-align: center;
}
.page-helper table.helper-background tbody > tr > td > div {
  border: 1px solid #ededed;
}
.page-helper table.helper-border-radius tbody > tr > td > div {
  border: 1px solid #ededed;
}
/**** Page Editors ****/
.main-content .page-content.page-editors > .header h2 span {
  color: #1D2939;
  font-size: 28px;
  font-style: normal;
  letter-spacing: -0.5px;
  padding-bottom: 7px;
}
.main-content .page-content.page-editors > .header h2 span::before {
  content: none;
}
.main-content .page-content.page-editors > .header p {
  font-size: 16px;
  height: 20px;
}
.main-content .page-content.page-editors #cke-inline p {
  font-size: 17px;
  line-height: 30px;
}
/**** Page Form Wizard ****/
.form-wizard-style {
  margin-bottom: 30px;
}
.form-wizard-style a {
  color: #CD6A6A;
}
.form-wizard-style a.current {
  color: #5B5B5B;
  cursor: default;
}
.form-wizard-nav {
  margin-bottom: 30px;
}
.form-wizard-nav a {
  color: #CD6A6A;
}
.form-wizard-nav a.current {
  color: #5B5B5B;
  cursor: default;
}
.form-wizard-style a,
.form-wizard-style span,
.form-wizard-nav a,
.form-wizard-nav span {
  font-family: 'Lato', arial, sans-serif;
  font-weight: 900;
  margin-right: 30px;
  text-transform: uppercase;
}
.wizard-div {
  height: 0;
  overflow: hidden;
}
.wizard-div.current {
  height: auto;
}
.page-wizard .tab-content {
  min-height: 620px;
}
.main-content .page-wizard.page-content {
  background: #F5F5F5 !important;
}
.sf-wizard .sf-step legend {
  display: none;
  padding-top: 5px;
}
/***** Page Profil ****/
.page-profil.page-content {
  position: relative;
}
.page-profil .profil-header {
  height: 332px;
  overflow: hidden;
  -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}
.page-profil .profil-header .col-lg-3 {
  padding: 0;
}
.page-profil .profil-header .col-lg-9 {
  padding: 0;
}
.page-profil .profil-header .col-sm-4 {
  padding: 0;
}
.page-profil .profil-header .col-sm-4:not(.profil-img) {
  border: 1px solid #ffffff;
  height: 111px;
  overflow: hidden;
}
.page-profil .profil-header .col-sm-4:not(.profil-img) img {
  width: 100%;
}
.page-profil .profil-header .profil-img {
  border-right: 1px solid #ffffff;
  height: 235px;
  overflow: hidden;
}
.page-profil .profil-header .profil-img img {
  height: 250px;
}
.page-profil .profil-header .col-md-9 > .row:not(.header-name) {
  height: 235px;
}
.page-profil .profil-header .header-name {
  background: #ffffff;
  height: 100px;
  padding: 15px 30px 15px 60px;
}
.page-profil .profil-header .name {
  font-family: 'Lato', arial, sans-serif;
  font-weight: 900;
  font-size: 24px;
  padding-bottom: 8px;
}
.page-profil .profil-header .name i {
  color: #39B77B;
  font-size: 22px;
  padding-left: 6px;
}
.page-profil .profil-header .more-friends {
  font-size: 22px;
  padding: 20px !important;
  text-align: center;
}
.page-profil .profil-header .more-friends p {
  font-family: 'Lato', arial;
  font-weight: 100;
  text-align: center;
}
.page-profil .profil-header .more-friends .num-friends {
  font-size: 22px;
  font-weight: 600;
}
.page-profil .profil-header .profil-info {
  color: #A4A4A4;
  float: left;
  font-family: 'Lato';
  font-size: 16px;
  padding-right: 20px;
}
.page-profil .profil-header .profil-info i {
  padding-right: 8px;
}
.page-profil .profil-header .moments {
  background: #E9ECED;
  color: #A6A6A6;
  font-size: 22px;
  height: 100px;
  margin-top: -15px;
  padding: 20px;
  text-align: center;
}
.page-profil .profil-header .moments .num-moments {
  font-family: 'Lato', arial, sans-serif;
  font-weight: 900;
  font-size: 24px;
  text-align: center;
}
.page-profil .map#profil-map {
  height: 370px;
}
.page-profil .col-map {
  height: 235px;
  overflow: hidden;
}
.page-profil .profil-right {
  background: #ffffff;
  bottom: 0;
  height: 100%;
  padding: 0 25px;
  position: absolute;
  right: 0;
  z-index: 3;
  -webkit-box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.1);
}
.page-profil .profil-right .stars {
  float: left;
}
.page-profil .profil-right .stars .fa {
  font-size: 15px;
  padding-right: 5px;
}
.page-profil .profil-right .icon-user {
  padding-right: 8px;
}
.page-profil .fa-comments-o {
  font-size: 15px;
  padding-right: 5px;
}
.page-profil .profil-content {
  height: 100%;
  margin-top: 20px;
  padding-right: 20px;
}
.page-profil .profil-content .item {
  background: #ffffff;
  margin-bottom: 30px;
  margin-top: 0;
  margin: 10px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
  z-index: 2;
  -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.page-profil .profil-content .item .user {
  max-height: 180px;
  overflow: hidden;
  position: relative;
  text-align: center;
}
.page-profil .profil-content .item .user:before {
  background-image: url('../images/gradient-big.png');
  background-repeat: repeat-x;
  bottom: 0;
  content: " ";
  height: 70px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 1;
}
.page-profil .profil-content .item .user .img-bg {
  border: 0;
  left: 0;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.page-profil .profil-content .item .user .img-user {
  left: 50%;
  margin-left: -35px;
  position: absolute;
  top: 25px;
  width: 70px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 35px;
}
.page-profil .profil-content .item .user .name {
  font-family: 'Lato', arial, sans-serif;
  font-weight: 900;
  font-size: 18px;
  margin-bottom: 10px;
}
.page-profil .profil-content .item .user .info {
  color: #ffffff;
  position: absolute;
  top: 105px;
  width: 100%;
  z-index: 2;
}
.page-profil .profil-content .item .user .info div {
  text-align: center;
}
.page-profil .profil-content .item .user .location {
  color: #CFCFCF;
  display: inline-block;
  font-family: 'Open Sans', 'Lato', arial;
  font-size: 15px;
  font-weight: 600;
  padding-right: 15px;
}
.page-profil .profil-content .item .user .date i {
  padding-right: 5px;
}
.page-profil .profil-content .item .comment {
  font-family: 'Merriweather', 'Open Sans', arial, serif;
  font-size: 15px;
  font-weight: 500;
  padding: 25px;
}
.page-profil .profil-content .item .more {
  text-align: center;
}
.page-profil .profil-content .item .more .col-sm-4 {
  background: #F7F7F7;
  color: #828282;
  cursor: pointer;
  font-size: 14px;
  padding: 15px 0 5px 0;
  position: relative;
  text-align: center;
}
.page-profil .profil-content .item .more .col-sm-4:first-child {
  padding-left: 15px;
}
.page-profil .profil-content .item .more .col-sm-4:last-child {
  padding-right: 15px;
}
.page-profil .profil-content .item .more .col-sm-4:hover {
  background: #EEEEF0;
}
.page-profil .profil-content .item .more .col-sm-4.active {
  background: #ffffff;
}
.page-profil .profil-content .item .more i {
  display: block;
  font-size: 20px;
  padding-right: 0;
}
.page-profil .profil-content .item .more .like.liked i {
  color: #C9625F;
}
.page-profil .profil-content .item .more .badge {
  position: absolute;
  right: -5px;
  top: -5px;
}
.page-profil .profil-content .item .more .comments {
  display: none;
}
.page-profil .profil-content .item .more .comments .pull-left {
  padding-left: 10px;
  padding-right: 10px;
}
.page-profil .profil-content .item .more .comments img {
  max-width: 50px;
  width: 100%;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}
.page-profil .profil-content .item .more .comments .info .name {
  font-size: 16px;
  line-height: 20px;
}
.page-profil .profil-content .item .more .comments .info .date {
  color: #B4B4B4;
  font-family: 'Lato', 'Open Sans', Arial;
  font-weight: 900;
  font-size: 13px;
}
.page-profil .profil-content .item .more .comments p {
  font-family: 'Merriweather', 'Open Sans', arial, serif;
  font-size: 14px;
  margin-left: 70px;
}
.page-profil .profil-content .item .more .comment-number {
  margin: auto;
  position: relative;
  text-align: center;
  width: 60px;
}
.page-profil .profil-content .item .more .share {
  display: none;
  padding-left: 15px;
  padding-right: 15px;
  padding: 0;
}
.page-profil .profil-content .item .more .share span {
  padding-left: 5px;
  padding-right: 5px;
}
.page-profil .profil-content .item .send {
  color: #AEAEAE;
  font-size: 12px;
}
.page-profil .profil-content .item .item-footer {
  background: #F4F4F4;
  padding: 15px;
}
.page-profil .profil-content .item .item-footer i {
  color: #9B9B9B;
  display: inline-block;
  font-size: 28px;
  margin-top: 0;
  padding-left: 7px;
  padding-right: 20px;
  padding-top: 8px;
}
.page-profil .profil-content .item .item-footer .btn {
  margin-bottom: 0;
}
.page-profil .profil-content .item .item-textarea {
  min-height: 50px;
  width: 100%;
}
.page-profil .profil-content .item .item-textarea textarea {
  border: none;
  font-size: 15px;
  padding: 15px;
}
.page-profil .profil-content .item .item-textarea textarea:hover {
  border: none;
}
.page-profil .profil-content .item .item-textarea textarea:focus {
  border: none;
}
.page-profil .profil-content .item .item-textarea textarea:active {
  border: none;
}
.page-profil .profil-content .item .share-facebook {
  background: #3b5998;
  color: #ffffff;
  cursor: pointer;
  float: left;
  font-size: 20px;
  margin: auto;
  opacity: 0.6;
  padding: 15px;
  text-align: center;
  width: 33.3333333%;
  -webkit-transition: opacity 0.15s linear;
  -moz-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  -ms-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.page-profil .profil-content .item .share-facebook:hover {
  opacity: 1;
}
.page-profil .profil-content .item .share-twitter {
  background: #00aced;
  color: #ffffff;
  cursor: pointer;
  float: left;
  font-size: 20px;
  margin: auto;
  opacity: 0.6;
  padding: 15px;
  text-align: center;
  width: 33.3333333%;
  -webkit-transition: opacity 0.15s linear;
  -moz-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  -ms-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.page-profil .profil-content .item .share-twitter:hover {
  opacity: 1;
}
.page-profil .profil-content .item .share-google {
  background: #d34836;
  color: #ffffff;
  cursor: pointer;
  float: left;
  font-size: 20px;
  margin: auto;
  opacity: 0.6;
  padding: 15px;
  text-align: center;
  width: 33.3333333%;
  -webkit-transition: opacity 0.15s linear;
  -moz-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  -ms-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.page-profil .profil-content .item .share-google:hover {
  opacity: 1;
}
.page-profil .profil-content .item.item-comment .user {
  padding: 10px 10px 0 10px;
  text-align: left;
}
.page-profil .profil-content .item.item-comment .user:before {
  background: none;
}
.page-profil .profil-content .item.item-comment .user img {
  float: left;
  padding-right: 7px;
  width: 50px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}
.page-profil .profil-content .item.item-comment .user .place {
  color: #AEAEAE;
  float: left;
  font-weight: 500;
}
.page-profil .profil-content .item.item-comment .user .name {
  font-family: 'Lato', arial, sans-serif;
  font-weight: 900;
  font-size: 16px;
  margin-bottom: 3px;
  margin-top: 0;
}
.page-profil .profil-content .item.item-comment .comment {
  font-size: 15px;
  padding: 5px 0;
}
.page-profil .profil-content .item.item-comment .time {
  color: #AEAEAE;
  float: right;
}
.page-profil .profil-content .item.item-comment .time-icon {
  color: #6C6C6C;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 7px;
}
.page-profil .profil-content .item.item-comment .item-event-detail p {
  margin-bottom: 0;
  margin-top: 0;
}
.page-profil .profil-content .item.item-comment .item-event-detail .separator {
  color: #CFCFCF;
  padding-left: 15px;
  padding-right: 15px;
}
.page-profil .profil-content .item.item-comment .item-map {
  height: 270px;
  overflow: hidden;
}
.page-profil .profil-content .item.item-comment #item-map {
  height: 300px;
  margin-top: 15px;
  width: 100%;
}
.page-profil .profil-content .item.new-item {
  margin-bottom: 0;
  opacity: 0;
  visibility: hidden;
}
.page-profil .profil-header > .row,
.page-profil .profil-header .col-lg-9,
.page-profil .profil-header.col-md-8,
.page-profil .profil-header .col-lg-3 {
  height: 100%;
}
.item .user .date {
  color: #CFCFCF;
  display: inline-block;
  font-family: 'Open Sans', 'Lato', arial;
  font-size: 15px;
  font-weight: 600;
}
@media (max-width: 1600px) {
  .page-profil .profil-header .col-sm-4:not(.profil-img) img {
    width: 120px;
    max-width: 300px;
  }
}
@media (max-width: 1500px) {
  .page-profil .profil-header {
    height: 300px;
  }
  .page-profil .profil-header .col-sm-4:not(.profil-img) {
    height: 102px;
  }
  .page-profil .profil-header .col-sm-4:not(.profil-img) img {
    max-width: 300px;
    width: 100px;
  }
  .page-profil .profil-header .profil-img img {
    height: 205px;
  }
  .page-profil .profil-header .col-map {
    height: 205px;
  }
  .page-profil .profil-header .col-md-9 > .row:not(.header-name) {
    height: 205px;
  }
  .page-profil .profil-header .map#profil-map {
    height: 350px;
  }
  .page-profil .profil-header .header-name {
    height: 95px;
  }
}
@media (max-width: 1500px) {
  .page-profil .profil-img {
    height: auto;
  }
  .page-profil .profil-header {
    height: 290px;
  }
  .page-profil .profil-header .col-sm-4:not(.profil-img) {
    height: 97px;
  }
  .page-profil .profil-header .col-sm-4:not(.profil-img) img {
    max-width: 150px;
    width: 100px;
  }
  .page-profil .profil-header .profil-img img {
    height: 195px;
  }
  .page-profil .profil-header .col-map {
    height: 195px;
  }
  .page-profil .profil-header .col-md-9 > .row:not(.header-name) {
    height: 195px;
  }
  .page-profil .profil-header .map#profil-map {
    height: 340px;
  }
  .page-profil .profil-header .header-name {
    height: 95px;
  }
}
@media (max-width: 1500px) {
  .page-profil .profil-header {
    height: 290px;
  }
  .page-profil .profil-header .col-sm-4:not(.profil-img) {
    height: 97px;
  }
  .page-profil .profil-header .col-sm-4:not(.profil-img) img {
    max-width: 120px;
    width: 102px;
  }
  .page-profil .profil-header .profil-img {
    height: 195px;
  }
  .page-profil .profil-header .profil-img img {
    height: 195px;
  }
  .page-profil .profil-header .col-map {
    height: 195px;
  }
  .page-profil .profil-header .col-md-9 > .row:not(.header-name) {
    height: 195px;
  }
  .page-profil .profil-header .map#profil-map {
    height: 340px;
  }
  .page-profil .profil-header .header-name {
    height: 95px;
  }
  .page-profil .profil-header .more-friends {
    font-size: 18px;
    padding: 15px !important;
    text-align: center;
  }
}
@media (max-width: 1200px) {
  .page-profil .profil-header {
    height: 290px;
  }
  .page-profil .profil-header .col-sm-4:not(.profil-img) img {
    max-width: 120px;
    width: 107px;
  }
  .page-profil .profil-header .profil-img {
    height: 195px;
  }
  .page-profil .profil-header .profil-img img {
    height: 220px;
  }
  .page-profil .profil-header .col-md-9 > .row:not(.header-name) {
    height: 195px;
  }
  .page-profil .profil-header .map#profil-map {
    height: 340px;
  }
  .page-profil .profil-header .header-name {
    height: 95px;
    padding-right: 0;
  }
  .page-profil .profil-header .more-friends {
    font-size: 18px;
    padding: 15px !important;
    text-align: center;
  }
  .page-profil .profil-header .col-lg-3 {
    height: 100px;
  }
  .page-profil .profil-header .user-friends {
    display: none;
  }
  .page-profil .profil-header.col-sm-4:not(.profil-img) {
    height: 97px;
  }
  .col-map {
    height: 195px;
  }
}
@media (max-width: 768px) {
  .page-profil .profil-header {
    height: 210px;
  }
  .page-profil .profil-header .profil-img {
    height: 130px;
  }
  .page-profil .profil-header .profil-img img {
    height: 150px;
  }
  .page-profil .profil-header .col-map {
    height: 130px;
  }
  .page-profil .profil-header .col-md-9 > .row:not(.header-name) {
    height: 130px;
  }
  .page-profil .profil-header .header-name {
    height: 80px;
    overflow: hidden;
    padding: 10px;
  }
  .page-profil .profil-header .name {
    font-size: 18px;
  }
  .page-profil .profil-header .moments {
    font-size: 18px;
    height: 100px;
    padding: 10px;
  }
  .col-map {
    height: 130px;
  }
}
/**** Page Shopping Cart ****/
.shopping-cart h3 {
  font-family: 'Lato', 'Open Sans', Arial;
  font-weight: 900;
  font-weight: 600;
}
.shopping-cart .wizard-validation .col-md-8 {
  border-left: 2px dashed #E9E9E9;
}
.shopping-cart .well {
  margin-bottom: 0;
}
.shopping-cart .icons-office-52:hover {
  color: #C9625F;
  cursor: pointer;
}
.cart-summary .cart-total {
  border: 2px solid #E4E4E4;
  margin-right: 8px;
  margin-top: 40px;
}
.cart-total div {
  float: left;
  font-size: 18px;
  line-height: 25px;
  padding: 0 15px 0 15px;
  width: 50%;
}
.shopping-cart-table img {
  margin: auto;
  max-width: 100px;
}
.cart-expiration .parsley-errors-list {
  display: none;
}
/**** Applications ****/
.page-app {
  padding: 0 !important;
}
.page-app .footer {
  background: #F7F7F7;
  display: none;
  padding: 13px;
}
.page-app .footer .btn {
  margin-bottom: 0;
}
.page-app .header {
  background: #F7F7F7;
  padding: 13px;
}
.page-app .header .btn {
  margin-bottom: 0;
}
.app {
  border-spacing: 0;
  display: table;
  height: 100%;
  table-layout: fixed;
  width: 100%;
}
@media (min-width: 800px) {
  .app > aside {
    display: table-cell;
    float: none;
    height: 100%;
    vertical-align: top;
    width: 100%;
  }
  .app > section {
    display: table-cell;
    float: none;
    height: 100%;
    vertical-align: top;
    width: 100%;
  }
  .app > aside.aside-md {
    background: inherit;
    width: 450px;
  }
  .app > aside.aside-sm {
    background: inherit;
    width: 300px;
  }
}
@media (min-width: 1300px) {
  .app > aside {
    display: table-cell;
    float: none;
    height: 100%;
    vertical-align: top;
    width: 100%;
  }
  .app > section {
    display: table-cell;
    float: none;
    height: 100%;
    vertical-align: top;
    width: 100%;
  }
  .app > aside.aside-md {
    background: inherit;
    width: 620px;
  }
  .app > aside.aside-sm {
    background: inherit;
    width: 380px;
  }
  .app > aside > section {
    border-spacing: 0;
    display: table;
    height: 100%;
    position: relative;
    width: 100%;
  }
  .app > aside > section .scrollable {
    height: 100%;
    margin-top: -1px;
  }
}
/**** Page Timeline ****/
.timeline-btn-day .btn {
  margin-bottom: 0;
  margin-left: -10px;
  margin-right: 0;
}
#timeline {
  margin-bottom: 2em;
  margin-top: 0;
  padding: 2em 0;
  position: relative;
}
#timeline::before {
  background: -moz-linear-gradient(top, #319db5 0, #dddddd 55%);
  background: -ms-linear-gradient(top, #319db5 0, #dddddd 55%);
  background: -o-linear-gradient(top, #319db5 0, #dddddd 55%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #00a2d9), color-stop(55%, #dddddd));
  background: -webkit-linear-gradient(top, #319db5 0, #dddddd 55%);
  background: #319db5;
  background: linear-gradient(to bottom, #319db5 0, #dddddd 55%);
  content: '';
  height: 100%;
  left: 18px;
  position: absolute;
  top: 0;
  width: 4px;
}
#timeline .timeline-content img {
  width: 100%;
}
#timeline .timeline-block {
  margin: 2em 0;
  position: relative;
}
#timeline .timeline-block i {
  color: #fff;
  display: inline-block;
  font-size: 18px;
  padding-top: 11px;
}
#timeline .timeline-block .timeline-content {
  background: #fff;
  border-radius: 4px;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
  margin-left: 60px;
  padding: 20px;
  position: relative;
}
#timeline .timeline-block .timeline-content i {
  color: #121212;
}
#timeline .timeline-block .timeline-content i.fa-quote-left {
  color: #319db5;
}
#timeline .timeline-block .timeline-content i.fa-quote-right {
  color: #319db5;
}
#timeline .timeline-block .timeline-content:after {
  clear: both;
  content: "";
  display: table;
}
#timeline .timeline-block .timeline-content h2 {
  color: #4B4F52;
  font-size: 22px;
  margin-bottom: 0;
  margin-top: 0;
}
#timeline .timeline-block .timeline-content .timeline-heading h2 {
  font-size: 28px;
  margin-top: 8px;
}
#timeline .timeline-block .timeline-content p {
  line-height: 1.6;
  margin: 1em 0;
}
#timeline .timeline-block .timeline-content .text p {
  margin-bottom: 5px;
}
#timeline .timeline-block .timeline-content::before {
  border-right: 7px solid #fff;
  border: 7px solid transparent;
  content: '';
  height: 0;
  position: absolute;
  right: 100%;
  top: 16px;
  width: 0;
}
#timeline .timeline-block .timeline-content .article-extract {
  font-family: 'Merriweather', 'Open Sans', arial, serif;
  font-size: 18px;
}
#timeline .timeline-block .timeline-content .video-player .video-js {
  width: 100% !important;
}
#timeline .timeline-block .timeline-content .video-player .vjs-default-skin .vjs-big-play-button {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  background: #000000;
  background: rgba(0, 0, 0, 0.85);
  border: none;
  box-shadow: none;
  display: block;
  font-size: auto;
  height: 44px;
  height: 45px;
  left: 50%;
  margin-left: -22px;
  opacity: 1;
  position: absolute;
  top: 40%;
  width: 44px;
  width: 45px;
  -webkit-transition: linear 0.4s linear;
  -moz-transition: linear 0.4s linear;
  -o-transition: linear 0.4s linear;
  -ms-transition: linear 0.4s linear;
  transition: linear 0.4s linear;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  z-index: 2;
}
#timeline .timeline-block .timeline-content .video-player .vjs-default-skin .vjs-big-play-button:before {
  top: -15px;
}
#timeline .timeline-block:after {
  clear: both;
  content: "";
  display: table;
}
#timeline .timeline-block:first-child {
  margin-top: 0;
}
#timeline .timeline-block:last-child {
  margin-bottom: 0;
}
#timeline .timeline-block .timeline-heading .timeline-day {
  font-size: 1.1em;
  font-weight: 700;
}
#timeline .timeline-block .timeline-heading .timeline-day-number {
  float: left;
  font-family: arial;
  font-size: 44px;
  font-weight: 700;
  height: 40px;
  margin-top: -10px;
  padding-right: 8px;
}
#timeline .timeline-block .timeline-heading .timeline-month {
  font-weight: 600;
}
#timeline .timeline-block .timeline-icon {
  border-radius: 50%;
  box-shadow: 0 0 0 4px #ffffff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
  height: 40px;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 40px;
}
#timeline .timeline-block .timeline-icon.cd-movie {
  background: #c03b44;
}
#timeline .timeline-block .timeline-icon.cd-location {
  background: #f0ca45;
}
@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -moz-transform: scale(1.2);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -moz-transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@media only screen and (min-width: 1170px) {
  .timeline-btn-day {
    text-align: center;
  }
  .timeline-btn-day .btn {
    margin-left: 0;
  }
  #timeline {
    margin-bottom: 3em;
    margin-top: 0;
  }
  #timeline::before {
    left: 50%;
    margin-left: -2px;
  }
  #timeline .timeline-block {
    margin-top: -50px;
    margin: 4em 0;
  }
  #timeline .timeline-block:first-child {
    margin-top: 0;
  }
  #timeline .timeline-block:last-child {
    margin-bottom: 0;
  }
  #timeline .timeline-block .timeline-icon {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    height: 60px;
    left: 50%;
    margin-left: -30px;
    text-align: center;
    width: 60px;
  }
  #timeline .timeline-block i {
    color: #ffffff;
    display: inline-block;
    font-size: 25px;
    padding-top: 17px;
  }
  #timeline .timeline-block .timeline-content {
    margin-left: 0;
    padding: 20px;
    width: 45%;
  }
  #timeline .timeline-block .timeline-content::before {
    border-color: transparent;
    border-left-color: #ffffff;
    left: 100%;
    top: 24px;
  }
  #timeline .timeline-block .timeline-content .read-more {
    float: left;
  }
  #timeline .timeline-block:nth-child(even) .timeline-content {
    float: right;
  }
  #timeline .timeline-block:nth-child(even) .timeline-content::before {
    border-color: transparent;
    border-right-color: #ffffff;
    left: auto;
    right: 100%;
    top: 24px;
  }
  #timeline .timeline-block:nth-child(even) .timeline-content .read-more {
    float: right;
  }
  .cssanimations .timeline-icon.is-hidden {
    visibility: hidden;
  }
  .cssanimations .timeline-icon.bounce-in {
    -moz-animation: cd-bounce-1 0.6s;
    -webkit-animation: cd-bounce-1 0.6s;
    animation: cd-bounce-1 0.6s;
    visibility: visible;
  }
  .cssanimations .timeline-content.is-hidden {
    opacity: 0;
    visibility: hidden;
  }
  .cssanimations .timeline-content.bounce-in {
    -moz-animation: cd-bounce-2 0.6s;
    -webkit-animation: cd-bounce-2 0.6s;
    animation: cd-bounce-2 0.6s;
    opacity: 1;
    visibility: visible;
  }
  .cssanimations #timeline .timeline-block:nth-child(even) .timeline-content.bounce-in {
    -moz-animation: cd-bounce-2-inverse 0.6s;
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}
@media only screen and (max-width: 600px) {
  #timeline .timeline-block .timeline-content .timeline-heading h2 {
    font-size: 22px;
    margin-top: 4px;
  }
}
/**** Pricing Table Builder ****/
.page-pricing-table .context-menu .dropdown-menu {
  padding: 0;
}
.page-pricing-table .context-menu .dropdown-menu li {
  border-bottom: 1px solid #ededed;
}
.page-pricing-table .context-menu .dropdown-menu li:last-child {
  border-bottom: none;
}
.page-pricing-table .context-menu .dropdown-menu .dropdown-title {
  background: #319DB5;
  color: #ffffff;
  font-family: 'Lato', arial, sans-serif;
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 1.5px;
  padding: 4px 15px;
  text-transform: uppercase;
}
.page-pricing-table .context-menu .dropdown-menu i {
  float: none;
}
.page-pricing-table #modal-icons .modal-body .col-sm-2 {
  min-height: 40px;
  opacity: 0.8;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-pricing-table #modal-icons .modal-body .col-sm-2:hover {
  color: #319DB5;
  opacity: 1;
}
.page-pricing-table #modal-icons .modal-body .col-sm-2 i {
  cursor: pointer;
  padding-right: 10px;
}
.page-pricing-table .builder .pricing-color {
  cursor: pointer;
  display: inline-block;
  height: 22px;
  margin-right: 3px;
  position: relative;
  width: 22px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.page-pricing-table .builder .pricing-color.active:after {
  color: #ffffff;
  content: "\e125";
  display: inline-block;
  font-family: "line-icons" !important;
  font-size: 11px;
  left: 5px;
  position: absolute;
  text-align: center;
  top: 5px;
}
.page-pricing-table #export-textarea {
  white-space: pre;
}
#modal-icons .modal-body .col-sm-2 i.active {
  color: #319DB5;
  opacity: 1;
}
.page-pricing-table #modal-icons .modal-body[class*="fa-"],
#modal-icons .modal-body[class*="icons-"],
#modal-icons .modal-body[class*="glyphicon-"],
#modal-icons .modal-body[class*="wi-"] {
  font-size: 20px;
}
.page-content.page-pricing-table {
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.page-content.page-pricing-table.open {
  padding-right: 260px;
}
.pricing-table .plan .reorder-plan {
  cursor: move;
  left: 50%;
  margin-left: -30px;
  position: absolute;
  top: -40px;
}
.pricing-table .plan .reorder-plan i {
  color: #464646;
  font-size: 24px;
  opacity: 0.3;
  padding: 8px;
}
.pricing-table .plan .reorder-plan:hover i {
  opacity: 1;
}
.pricing-table .plan .add-item {
  cursor: pointer;
  left: 50%;
  margin-left: 10px;
  position: absolute;
  top: -32px;
}
.pricing-table .plan .add-item i {
  color: #464646;
  font-size: 24px;
  opacity: 0.3;
  padding: 8px;
}
.pricing-table .plan .add-item:hover i {
  opacity: 1;
}
.pricing-table .plan .remove-plan {
  bottom: -40px;
  cursor: pointer;
  left: 50%;
  margin-left: -20px;
  position: absolute;
}
.pricing-table .plan .remove-plan i {
  color: #464646;
  font-size: 24px;
  opacity: 0.3;
  padding: 8px;
}
.pricing-table .plan .remove-plan:hover i {
  color: #B40707;
  opacity: 1;
}
.pricing-table .plan .description .remove-item {
  cursor: pointer;
  margin-top: 18px;
  position: absolute;
  right: 0;
}
.pricing-table .plan .description .remove-item i {
  color: #464646;
  font-size: 18px;
  opacity: 0;
  padding: 8px;
}
.pricing-table .plan .description .sort-item {
  cursor: pointer;
  left: 43px;
  margin-top: 13px;
  padding-left: 10px !important;
  padding-right: 0 !important;
  position: absolute;
}
.pricing-table .plan .description .sort-item i {
  color: #595959;
  font-size: 14px;
  opacity: 0;
  padding: 8px;
}
.pricing-table .plan .description .plan-icon {
  color: rgba(0, 0, 0, 0.4);
  float: left;
  font-size: 26px;
  padding-right: 8px;
  position: absolute;
  left: 15px;
  margin-top: 5px;
}
.pricing-table .plan .description > div:hover .remove-item i {
  color: #B40707;
  opacity: 1;
}
.pricing-table .plan .description > div:hover .sort-item i {
  opacity: 1;
}
.pricing-table .plan .note-air-editor {
  font-weight: bold;
}
/**** Pricing Table ****/
.pricing-table {
  padding-bottom: 40px;
  padding-top: 40px;
}
.pricing-table .plan {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background: #ffffff;
  box-sizing: border-box;
  color: #bdc3c7;
  float: left;
  font-size: 18px;
  font-weight: 400;
  padding: 0;
  position: relative;
  text-align: center;
  -webkit-transition: background 0.2s linear;
  -moz-transition: background 0.2s linear;
  -o-transition: background 0.2s linear;
  -ms-transition: background 0.2s linear;
  transition: background 0.2s linear;
}
.pricing-table .plan:hover {
  color: #383838;
}
.pricing-table .plan .description {
  border-right: 3px solid rgba(0, 0, 0, 0.1);
  border-top: 2px solid #ebedee;
  border-top: none !important;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  text-align: left;
}
.pricing-table .plan .description div {
  padding-left: 10%;
  padding-right: 10%;
}
.pricing-table .plan .description .plan-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 1em;
  margin-bottom: 0;
  padding-bottom: 20px;
  padding-top: 15px;
  text-align: center;
}
.pricing-table .plan .description .plan-item .glyph-icon {
  width: 60px;
}
.pricing-table .plan .description b {
  font-weight: 600;
}
.pricing-table .plan .plan-header {
  border-right: 3px solid rgba(0, 0, 0, 0.1);
  color: #ffffff;
  padding: 25px;
}
.pricing-table .plan .plan-header .title {
  color: #ffffff;
  font-family: 'Lato';
  font-size: 22px;
  font-weight: 100;
  margin-bottom: 15px;
  text-align: center;
  text-transform: uppercase;
}
.pricing-table .plan .plan-header .price {
  color: #121212;
  font-size: 14px;
  text-align: center;
}
.pricing-table .plan .plan-header .price .amount {
  display: inline-block;
  font-size: 4em;
  margin-top: -22px;
}
.pricing-table .plan .plan-header .offer {
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 0.8em;
  font-weight: 500;
  padding: 8px 25px;
  text-align: center;
  text-transform: uppercase;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.pricing-table .plan .plan-header .offer:hover {
  background: #eaeaea;
  border: 2px solid rgba(0, 0, 0, 0.2);
  color: #34383F;
}
.pricing-table .plan:last-child .description {
  border-right: none;
  margin-right: 0;
}
.pricing-table .plan:last-child .plan-header {
  border-right: none;
  margin-right: 0;
}
.pricing-table .plan b {
  color: #7f8c8d;
}
.pricing-table .plan .plan-header.bg-dark .price {
  color: #ffffff;
}
.pricing-table .plan .description > div:nth-last-child(2) .plan-item {
  border-bottom: none;
}
.pricing-table .plan .btn {
  min-width: 170px;
}
.pricing-table.plan-separated .plan-header {
  border-right: none;
}
.pricing-table.plan-separated .description {
  border-right: none;
}
.pricing-table.num-plan-2 .plan {
  width: 50%;
}
.pricing-table.plan-separated.num-plan-2 .plan {
  margin-right: 2%;
  width: 46%;
}
.pricing-table.num-plan-3 .plan {
  width: 33.33%;
}
.pricing-table.plan-separated.num-plan-3 .plan {
  margin-right: 3.5%;
  width: 29.8%;
}
.pricing-table.num-plan-4 .plan {
  width: 25%;
}
.pricing-table.plan-separated.num-plan-4 .plan {
  margin-right: 2%;
  width: 23%;
}
.pricing-table.num-plan-5 .plan {
  width: 20%;
}
.pricing-table.plan-separated.num-plan-5 .plan {
  margin-right: 2%;
  width: 18%;
}
.pricing-table.plan-rounded .plan .plan-header {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  overflow: hidden;
}
.pricing-table.plan-rounded .plan:first-child .plan-header {
  overflow: hidden;
  -webkit-border-radius: 4px 0 0 0;
  -moz-border-radius: 4px 0 0 0;
  border-radius: 4px 0 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.pricing-table.plan-rounded .plan:last-child .plan-header {
  overflow: hidden;
  -webkit-border-radius: 0 4px 0 0;
  -moz-border-radius: 0 4px 0 0;
  border-radius: 0 4px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.pricing-table.plan-separated.plan-rounded .plan .plan-header {
  overflow: hidden;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.pricing-table.plan-shadow .plan {
  box-shadow: 0 3px 9px rgba(155, 155, 155, 0.55);
}
@media (max-width: 1300px) {
  .pricing-table .plan .plan-header .offer {
    font-size: 0.6em;
    padding: 8px 10px;
  }
  .pricing-table .plan .plan-header .price .amount {
    font-size: 2em;
  }
  .pricing-table .plan .description .plan-item {
    font-size: 0.8em;
  }
}
@media (max-width: 1000px) {
  .pricing-table .plan {
    width: 100%;
  }
  .pricing-table .plan-left .description {
    border-left: none;
    border-right: none;
  }
  .pricing-table .plan-right .description {
    border-left: none;
    border-right: none;
  }
}
/**** Page Mailbox  ****/
.mailbox .morphsearch {
  display: none;
}
.page-app.mailbox {
  background: #f9f9f9;
  padding: 0;
}
.page-app.mailbox strong {
  font-family: 'Lato';
  font-weight: 600;
}
.page-app.mailbox .icon-rounded {
  border: 1px solid #BDBDBD;
  color: #bdbdbd;
  display: inline-block;
  height: 40px;
  text-align: center;
  width: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.page-app.mailbox .icon-rounded:hover {
  border: 1px solid #121212;
  color: #121212;
  cursor: pointer;
}
.page-app.mailbox .icon-rounded.heart:hover {
  border: 1px solid #d9534f;
  color: #d9534f;
}
.page-app.mailbox .icon-rounded.heart-red {
  border: 1px solid #d9534f;
  color: #d9534f;
}
.page-app.mailbox .icon-rounded.gear:hover {
  border: 1px solid #3F97C9;
  color: #3F97C9;
}
.page-app.mailbox .panel-body.messages {
  padding: 0;
}
.page-app.mailbox .border-bottom {
  border-bottom: 1px solid #EFEFEF !important;
}
.page-app.mailbox .messages-list {
  padding: 0;
  position: relative;
}
.page-app.mailbox .messages-list:after {
  border-left: 3px solid #EBEBEB;
  bottom: 13px;
  content: "";
  left: 24px;
  position: absolute;
  top: 10px;
}
.page-app.mailbox .message-item {
  border-left: none;
  display: block;
  height: 65px;
  line-height: 53px;
  margin-bottom: 0;
  margin: 0;
  padding: 5px 20px 5px 10px;
  position: relative;
}
.page-app.mailbox .message-item:hover {
  background: #E4E4E4;
  cursor: pointer;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.page-app.mailbox .message-item .media {
  padding: 0;
}
.page-app.mailbox .message-item .media-body {
  margin-top: 0;
}
.page-app.mailbox .message-item .sender-img {
  left: 5px;
  position: absolute;
  top: 12px;
  z-index: 2;
}
.page-app.mailbox .message-item .sender {
  float: left;
  font-weight: 600;
  margin-left: 52px;
  overflow: hidden;
  padding-bottom: 3px;
  padding-top: 3px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100px;
}
.page-app.mailbox .message-item .email {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.page-app.mailbox .message-item .subject {
  float: left;
  overflow: hidden;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-top: 3px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 220px;
}
.page-app.mailbox .message-item .subject .label {
  font-family: 'Lato', arial, sans-serif;
  font-weight: 900;
  letter-spacing: 0.7px;
  margin-right: 6px;
  padding: 5px 8px 4px;
  text-transform: uppercase;
}
.page-app.mailbox .message-item .subject i {
  font-size: 16px;
  font-weight: bold;
  padding-right: 6px;
}
.page-app.mailbox .message-item .date {
  float: right;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-top: 3px;
}
.app > aside.emails-list > section {
  padding: 40px 20px 0 50px;
}
.sidebar-mailbox.sidebar .btn-compose {
  display: block;
  margin: 20px auto 28px auto;
  width: 80%;
}
.sidebar-mailbox.sidebar .sidebar-inner .nav-sidebar > li.active > a {
  background-color: #f9f9f9;
  color: #3C454D;
  font-size: 15px;
  opacity: 1;
  padding: 13px 20px 10px;
}
.sidebar-mailbox.sidebar .sidebar-inner .nav-sidebar > li.active > a i {
  color: #1D1F21;
  display: inline-block;
  margin-top: 0px;
  opacity: 0.8;
}
.sidebar-mailbox.sidebar .sidebar-inner .nav-sidebar > li.active > a span:not(.badge) {
  color: #1D1F21;
  opacity: 0.8;
}
.sidebar-mailbox.sidebar .sidebar-inner .nav-sidebar > li.active > a span.badge {
  background: rgba(0, 0, 0, 0.7);
  color: #ffffff;
}
.sidebar-mailbox.sidebar .sidebar-inner .nav-sidebar > li > a {
  padding: 12px 20px 8px;
}
.sidebar-mailbox.sidebar .sidebar-inner .nav-sidebar > li > a i {
  font-size: 16px;
}
.sidebar-mailbox.sidebar .sidebar-inner .nav-sidebar > li > a:hover i {
  opacity: 1;
}
.sidebar-mailbox.sidebar .sidebar-inner .nav-sidebar > li > a:hover span:not(.badge) {
  opacity: 1;
}
.sidebar-mailbox.sidebar .sidebar-inner .sidebar-widgets ul a {
  opacity: 0.9;
  padding: 8px 0;
}
.sidebar-mailbox.sidebar .sidebar-inner .sidebar-widgets ul a:hover {
  opacity: 1;
}
.sidebar-mailbox.sidebar .nav-sidebar > li.active > a:hover {
  background-color: #f9f9f9;
  color: #3C454D;
  font-size: 15px;
  opacity: 1;
  padding: 13px 20px 10px;
}
.sidebar-mailbox.sidebar .nav-sidebar > li.active > a:focus {
  background-color: #f9f9f9;
  color: #3C454D;
  font-size: 15px;
  opacity: 1;
  padding: 13px 20px 10px;
}
.sidebar-mailbox.sidebar span.badge {
  font-size: 13px;
  height: 21px;
  margin-top: -2px;
  padding-top: 4px;
  width: 21px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.sidebar-mailbox.sidebar .badge-rounded {
  line-height: 20px;
}
.sidebar-mailbox.sidebar .sidebar-charts {
  bottom: 0;
  left: -30px;
  margin-bottom: 20px;
  margin-left: 0;
  overflow: hidden;
  position: absolute;
  width: 270px;
}
.sidebar-mailbox.sidebar .sidebar-charts:hover #chart-legend {
  opacity: 0.8;
}
.sidebar-mailbox.sidebar .morris-full-content {
  min-height: 250px;
  width: 300px;
}
.sidebar-mailbox.sidebar .morris-hover {
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  border-radius: 1px;
  cursor: default;
  text-align: right;
  user-select: none;
  z-index: 1000;
}
.sidebar-mailbox.sidebar .morris-hover.morris-default-style {
  background-color: #2F353F;
  padding: 7px 15px;
}
.sidebar-mailbox.sidebar .morris-hover.morris-default-style .morris-hover-row-label {
  color: #ffffff;
  font-size: 1.2em;
  font-weight: 500;
  white-space: nowrap;
}
.sidebar-mailbox.sidebar .morris-hover.morris-default-style .morris-hover-point {
  color: #f3f3f3!important;
  font-weight: 300;
  white-space: nowrap;
}
.sidebar-mailbox.sidebar .morris-legend-items {
  display: inline-block;
  padding: 5px 10px 5px 5px;
}
.sidebar-mailbox.sidebar .morris-legend-items > span {
  display: inline-block;
  height: 10px;
  margin-right: 10px;
  width: 12px;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}
.sidebar-mailbox.sidebar #chart-legend {
  bottom: 50px;
  color: rgba(255, 255, 255, 0.8);
  margin-left: 45px;
  opacity: 0;
  position: absolute;
  z-index: 10;
  -webkit-transition: opacity 0.15s linear;
  -moz-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  -ms-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.emails-list .mailbox-page {
  margin-bottom: 15px;
}
.emails-list .mailbox-page h1 {
  font-family: 'Lato', arial, sans-serif;
  font-weight: 900;
  font-size: 32px;
}
.emails-list .mailbox-page input {
  border: 2px solid #ECEDEE;
  font-size: 18px;
  max-width: 250px;
  padding: 10px 18px;
}
.emails-list .mailbox-page input:hover {
  border: 2px solid #CACCD0;
}
.emails-list .mailbox-page input:focus {
  border: 2px solid #CACCD0;
}
.emails-list .mailbox-page input:active {
  border: 2px solid #CACCD0;
}
.emails-list .mailbox-page .append-icon i {
  font-size: 20px;
  padding-right: 6px;
  padding-top: 4px;
}
.emails-list .nav-tabs a {
  font-family: 'Lato', arial, sans-serif;
  font-weight: 900;
  font-size: 12px;
}
.emails-list .nav-tabs a::before {
  background: #667277;
  height: 2px;
  top: 37px;
}
.emails-list .nav-tabs .open > a {
  background: none;
}
.emails-list .nav-tabs .open > a:hover {
  background: none;
}
.emails-list .nav-tabs .open > a:focus {
  background: none;
}
.emails-list .nav-tabs .dropdown-menu {
  margin-top: 2px;
  min-width: 110px;
  padding: 5px 8px;
}
.emails-list .nav.nav-tabs > li > a {
  background: inherit;
}
.emails-list .nav.nav-tabs > li.active > a {
  background: inherit;
  color: #667277;
}
.emails-list .nav.nav-tabs > li:hover > a {
  background: inherit;
}
.emails-list .nav.nav-tabs > li.emails-action {
  padding-top: 7px;
}
.emails-list .nav-tabs > li i {
  color: #C3C3C3;
  font-size: 20px;
  padding-right: 12px;
}
.emails-list .nav-tabs > li i:hover {
  color: #7e7e7e;
}
.emails-list .nav-tabs > li i.icon-rounded-heart:hover {
  color: #C75757;
}
.emails-list section .tab-content {
  background: inherit;
  padding: 20px 0;
}
.emails-list .email-content {
  display: none;
}
.email-context {
  padding-top: 5px;
}
.email-context .dropdown-menu > li > a {
  padding: 4px 8px;
}
.email-details > section {
  padding: 40px 50px 0 40px;
}
.email-details .email-subject {
  border-bottom: 2px solid #dfdfdf;
  color: #4F4F4F;
  padding: 0;
}
.email-details .email-subject h1 {
  font-family: 'Lato';
  font-weight: 100;
  font-size: 50px;
  margin-bottom: 9px;
}
.email-details .email-subject p {
  color: #7e7e7e;
  float: left;
}
.email-details .email-subject i {
  color: #C3C3C3;
  font-size: 20px;
  padding-left: 7px;
}
.email-details .email-subject i:hover {
  color: #7e7e7e;
}
.email-details .email-subject .go-back-list i {
  display: none;
  font-size: 30px;
  cursor: pointer;
  color: #AEAEAE;
  float: left;
  padding-right: 11px;
  margin-top: -5px;
}
.email-details .email-subject .go-back-list i:hover {
  color: #7c7c7c;
}
.email-details .email-subject .dropdown-menu {
  min-width: 110px;
}
.email-details .email-subject .dropdown-menu > li > a {
  font-family: 'Lato', arial, sans-serif;
  font-weight: 900;
  font-size: 12px;
  padding: 5px 8px;
}
.email-details .email-content {
  padding: 30px 30px 0 0;
}
.email-details .email-content p {
  font-family: 'Merriweather', serif;
  font-size: 19px;
  line-height: 33px;
}
.email-details .email-content p strong {
  font-family: 'Merriweather', serif !important;
}
.email-details .answers {
  display: none;
  font-family: 'Merriweather', serif;
  font-size: 15px;
  margin-top: 20px;
  padding-right: 30px;
}
.email-details .answers .answer-title {
  font-family: 'Merriweather', serif;
  font-weight: 100;
}
.email-details .answers .answer-subject p {
  color: #7e7e7e;
  font-family: 'Lato', 'Open Sans', Helvetica, sans-serif;
  font-size: 14px;
}
.email-details .answers .answer-content ol {
  margin-left: 40px;
}
.email-details .answers .answer-content ul {
  margin-left: 40px;
}
.email-details .write-answer {
  padding-right: 30px;
}
.email-details .write-answer .answer-textarea {
  background: #ffffff;
  border: 1px solid #dfdfdf;
  font-family: 'Merriweather', serif;
  font-size: 16px;
  height: 150px;
  margin-top: 38px;
  padding: 20px;
  width: 100%;
}
.email-details .write-answer .note-codable {
  display: none;
}
.email-details .write-answer .note-editable {
  background: #ffffff;
  border: 1px solid #dfdfdf;
  font-family: 'Merriweather', serif;
  font-size: 16px;
  height: 150px;
  padding: 20px;
  width: 100%;
}
.email-details .write-answer .note-editable ul {
  margin-left: 40px;
}
.email-details .write-answer .note-editable ol {
  margin-left: 40px;
}
.email-details .write-answer .note-editable:focus {
  outline: none !important;
}
.email-details .write-answer .note-editable:active {
  outline: none !important;
}
.email-details .write-answer .note-editor .btn-default {
  background-color: #f9f9f9;
  border: none !important;
}
.email-details .write-answer .note-editor .btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-left: none !important;
}
.email-details .write-answer .note-editor .btn-group > .dropdown-toggle:not(:first-child) {
  border-left: none !important;
}
.email-details .write-answer .note-toolbar {
  margin-top: 10px;
}
.email-details .write-answer #save {
  float: right;
  margin-right: 0;
  margin-top: 20px;
}
.email-details .email-attachment {
  border-bottom: 1px solid #dfdfdf;
  border-top: 1px solid #dfdfdf;
  padding: 0;
  position: relative;
}
.email-details .email-attachment .attachments {
  float: left;
  padding: 20px 0;
}
.email-details .email-attachment .attachments-actions {
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.email-details .email-attachment span {
  padding-right: 18px;
}
.email-details .email-attachment span.small {
  padding-right: 0;
}
.email-details .email-attachment i.fa-file-pdf-o {
  background: #BE0303;
}
.email-details .email-attachment .pull-right i {
  background: none;
  color: #C3C3C3;
  font-size: 20px;
}
.email-details .email-attachment .pull-right i:hover {
  color: #7e7e7e;
}
.email-details .email-attachment .download-attachment {
  border-left: 1px solid #dfdfdf;
  float: right;
  height: 100%;
}
.email-details .email-attachment .download-attachment i {
  color: #D2D2D2;
  display: block;
  font-size: 51px;
  margin-left: 20px;
  margin-top: 18px;
}
.email-details .email-attachment .download-attachment i:hover {
  color: #B0B0B0;
  cursor: pointer;
}
.email-details .email-attachment .move-attachments {
  float: left;
}
.email-details .email-attachment .move-attachments i {
  color: #D2D2D2;
  display: inline-block;
  font-size: 20px;
  margin-right: 20px;
  margin-top: 34px;
}
.email-details .email-attachment .move-attachments i:hover {
  color: #B0B0B0;
  cursor: pointer;
}
.email-details .email-attachment .move-attachments i.icon-rounded-arrow-left-thin {
  margin-right: 6px;
}
.email-details .email-attachment > .attachments i {
  background: #0C0C50;
  color: #ffffff;
  font-size: 18px;
  height: 50px;
  margin-right: 5px;
  padding: 16px 0 0 17px;
  width: 50px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}
#main-content .messages a.btn {
  color: #B3B3B3;
}
.message-active {
  background-color: #00A2D9;
  color: #ffffff;
}
a.message-item.message-active:hover {
  background-color: #008fc0;
  color: #ffffff;
}
a.message-item:hover {
  background-color: #eaeaea;
  text-decoration: none;
}
.messages .message-item img {
  border-radius: 3px;
}
.messages .mCustomScrollBox {
  overflow: hidden !important;
}
.message-item-right {
  padding-left: 33px;
}
.withScroll {
  height: auto;
  overflow: auto;
  overflow: hidden;
}
.message-result .message-item {
  padding-left: 0;
}
.message-result .message-item-right {
  padding-left: 0;
}
.message-title {
  margin-top: 0;
}
.message-body {
  padding: 0 20px 20px 20px;
}
.message-attache {
  border-top: 1px solid #ECEBEB !important;
  padding-top: 10px;
}
.message-attache .media {
  float: left;
  margin-top: 0;
  padding-bottom: 30px;
  padding-right: 30px;
}
.message-between {
  background-color: #E7E7E7;
  height: 8px;
}
.footer-message {
  padding-top: 20px;
}
.send-message .form-control {
  height: 34px;
}
@media screen and (min-width: 1024px) {
  .sidebar-collapsed .sidebar-mailbox.sidebar {
    width: 70px;
  }
  .sidebar-collapsed .sidebar-mailbox.sidebar .nav-sidebar > li.nav-hover:not(.nav-active) > a span:not(.badge) {
    height: 45px;
    left: 60px;
    padding: 11px 10px 12px 5px;
  }
  .sidebar-collapsed .sidebar-mailbox.sidebar .sidebar-inner .nav-sidebar > li.active > a {
    background: none;
    color: #ffffff;
  }
  .sidebar-collapsed .sidebar-mailbox.sidebar .sidebar-inner .nav-sidebar > li.active > a span {
    color: #6DC6D8;
    margin-left: 0;
    opacity: 1;
  }
  .sidebar-collapsed .sidebar-mailbox.sidebar .sidebar-inner .nav-sidebar > li.active > a span:not(.badge) {
    height: 45px;
    left: 60px;
    padding: 11px 10px 12px 5px;
  }
  .sidebar-collapsed .sidebar-mailbox.sidebar .sidebar-inner .nav-sidebar > li.active > a i {
    color: #6DC6D8;
    margin-left: 0;
    opacity: 1;
  }
  .sidebar-collapsed .sidebar-mailbox.sidebar .sidebar-inner .nav-sidebar > li > a {
    height: 45px !important;
    padding: 12px 10px 8px;
    text-align: center;
  }
  .sidebar-collapsed .sidebar-mailbox.sidebar .sidebar-inner .nav-sidebar > li > a i {
    font-size: 20px;
    opacity: 0.6;
  }
  .sidebar-collapsed .sidebar-mailbox.sidebar .sidebar-inner .nav-sidebar > li > a .badge-rounded {
    border: none !important;
    left: auto !important;
    padding: 0 !important;
    position: absolute;
    right: 0;
    top: 5px;
  }
  .sidebar-collapsed .sidebar-mailbox.sidebar .sidebar-inner .nav-sidebar li a i {
    margin-left: 0;
  }
  .sidebar-collapsed .sidebar-mailbox.sidebar .sidebar-inner .sidebar-charts {
    display: none;
  }
  .sidebar-collapsed .sidebar-mailbox.sidebar .nav-sidebar > li.active > a:hover {
    background: none;
    color: #ffffff;
  }
  .sidebar-collapsed .sidebar-mailbox.sidebar .nav-sidebar > li.active > a:focus {
    background: none;
    color: #ffffff;
  }
  .mailbox .topbar .header-left .topnav .menutoggle {
    width: 70px;
  }
  .mailbox.sidebar-collapsed .topbar .header-left .topnav .menu__handle {
    left: 24px;
  }
  .mailbox.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li > a .badge {
    background: none !important;
    display: block !important;
    left: auto;
    padding: 0;
    position: absolute;
    right: 0;
    top: 5px;
  }
  .mailbox.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li > a .badge-rounded {
    background: none !important;
    display: block !important;
    left: auto;
    padding: 0;
    position: absolute;
    right: 0;
    top: 5px;
  }
  .mailbox.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-hover.nav-active > a i {
    color: #ffffff !important;
  }
  .mailbox.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-hover.nav-active > a span {
    color: #ffffff !important;
  }
  .mailbox.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-hover > a i {
    color: #121212 !important;
    opacity: 1;
  }
  .mailbox.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-hover > a span {
    color: #121212 !important;
    opacity: 1;
  }
  .sidebar-mailbox.sidebar .sidebar-inner .nav-sidebar > li > a span:not(.badge) {
    opacity: 0.6;
  }
}
@media (min-width: 992px) {
  .email-go-back {
    display: none;
  }
}
@media (max-width: 991px) {
  .email-hidden-sm {
    display: none;
  }
  .email-go-back {
    display: inline-block;
  }
}
@media (max-width: 1300px) {
  .app > aside.emails-list > section {
    padding: 20px 15px 0 25px;
  }
  .email-details > section {
    padding: 20px 25px 0 20px;
  }
  .email-details .email-subject h1 {
    font-size: 35px;
    margin-top: 16px;
  }
  .email-details .email-content p {
    font-size: 16px;
  }
  .email-details .email-attachment > .attachments i {
    display: none;
  }
  .page-app.mailbox .messages-list:after {
    display: none;
  }
  .page-app.mailbox .message-item .sender-img {
    display: none;
  }
  .page-app.mailbox .message-item .sender {
    margin-left: 0;
    width: 100px;
  }
  .page-app.mailbox .message-item .subject {
    width: 150px;
  }
  .move-attachments {
    display: none;
  }
  .email-attachment .download-attachment i {
    font-size: 38px;
    margin-left: 10px;
    margin-top: 11px;
  }
}
@media (max-width: 800px) {
  .page-content.mailbox:not(.mailbox-send) .email-details {
    display: none;
  }
  .page-content.mailbox:not(.mailbox-send) .email-details .email-subject .go-back-list i {
    display: block;
  }
  .page-content.mailbox-send .emails-list {
    display: none;
  }
  .page-app.mailbox .message-item .sender {
    width: 150px;
  }
  .page-app.mailbox .message-item .subject {
    width: auto;
  }
}
@media (max-width: 800px) {
  .page-app.mailbox .message-item .sender {
    width: 100px;
  }
  .page-app.mailbox .message-item .subject {
    width: 150px;
  }
}
@media (max-width: 480px) {
  .page-app.mailbox .message-item .sender {
    width: auto;
  }
  .page-app.mailbox .message-item .subject {
    display: none;
  }
}
/**** Page Coming Soon ****/
.coming-soon {
  background: -moz-linear-gradient(left, #212026 0%, #353535 98%);
  background: -ms-linear-gradient(left, #212026 0%, #353535 98%);
  background: -o-linear-gradient(left, #212026 0%, #353535 98%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #212026), color-stop(98%, #353535));
  background: -webkit-linear-gradient(left, #212026 0%, #353535 98%);
  background: linear-gradient(to right, #212026 0%, #353535 98%);
  background: #212026;
  overflow-x: hidden;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#212026', endColorstr='#353535', GradientType=1);
}
.coming-soon a {
  color: #b7b7b7;
  font-weight: 400;
  text-decoration: none;
}
.coming-soon a:hover {
  color: #319db5;
  text-decoration: none;
}
.coming-soon .coming-container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.coming-soon .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
}
.coming-soon .content {
  -webkit-font-smoothing: antialiased;
  float: left;
  margin: 40px;
  position: absolute;
  top: 200px;
  width: 600px;
  z-index: 9999;
}
.coming-soon .content h1 {
  color: #ffffff;
  font-family: 'Lato', 'Open Sans', Helvetica, sans-serif;
  font-size: 63px;
  font-weight: 100;
  line-height: 70px;
  margin: 0;
  padding: 0;
}
.coming-soon .content h2 {
  color: #ffffff;
  font-weight: normal;
}
.coming-soon .content h3 {
  color: #ffffff;
  font-weight: normal;
}
.coming-soon .content h5 {
  color: #b7b7b7;
  font-size: 28px;
  line-height: 35px;
  margin: 0;
  padding: 0;
}
.coming-soon .content p {
  color: #B1B1B1;
  font-size: 15px;
  line-height: 25px;
  padding-top: 30px;
}
.coming-soon .content.show {
  display: block;
}
.coming-soon .content.hide {
  opacity: 0;
}
.coming-soon #logo {
  bottom: 10px;
  color: #ffffff;
  font-size: 49px;
  height: auto;
  left: 40px;
  position: absolute;
  width: 20%;
  z-index: 1000;
}
.coming-soon .main-nav {
  bottom: 23px;
  left: 210px;
  position: absolute;
  z-index: 1001;
}
.coming-soon .main-nav ul li {
  border-left: 1px solid #b7b7b7;
  display: inline;
  list-style: none;
  padding-left: 10px;
  padding-right: 10px;
}
.coming-soon .main-nav ul li a.active {
  color: #319db5;
}
.coming-soon .social-nav {
  left: 40px;
  position: absolute;
  top: 0;
}
.coming-soon .social-nav ul {
  margin: 0;
  padding: 0;
}
.coming-soon .social-nav ul li {
  background: rgba(0, 0, 0, 0.3);
  display: block;
  float: left;
  list-style: none;
  margin: 1px;
  opacity: .5;
  padding: 100px 10px 5px;
}
.coming-soon .social-nav ul li:hover {
  opacity: 1;
}
.coming-soon #countdown {
  -webkit-font-smoothing: antialiased;
  color: rgba(255, 255, 255, 0.8);
  font-family: 'Lato', 'Open Sans', Helvetica, sans-serif;
  font-weight: 100;
  font-size: 48px;
  line-height: 1.4em;
  position: absolute;
  right: 30px;
  top: 200px;
  z-index: 9999;
}
.coming-soon .map-container {
  height: 100% !important;
  overflow: hidden;
}
.coming-soon .map-container #map {
  height: calc(150%) !important;
  width: 100%;
}
.coming-soon .map-container #map .pulse {
  background: #d6d4d4;
  height: 14px;
  left: 50%;
  margin: 11px 0 0 -12px;
  position: absolute;
  top: 50%;
  transform: rotateX(55deg);
  width: 14px;
  z-index: -2;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.coming-soon .map-container #map .pulse:after {
  animation-delay: 1.1s;
  animation-iteration-count: infinite;
  animation: pulsate 1s ease-out;
  border-radius: 50%;
  box-shadow: 0 0 1px 2px #00cae9;
  content: "";
  height: 40px;
  margin: -13px 0 0 -13px;
  opacity: 0;
  position: absolute;
  width: 40px;
}
.coming-soon .map-container #map .pulse-label {
  -webkit-animation: pulse 1.5s infinite;
  background: rgba(49, 157, 181, 0.5);
  border-radius: 50%;
  height: 30px;
  width: 30px;
}
.coming-soon #coming-builder {
  -moz-border-bottom-left-radius: 0;
  -moz-border-top-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-color: #DEE5E7;
  border-radius: 20px;
  background: #F6F8F8!important;
  bottom: 60px;
  height: 44px;
  left: 50%;
  margin-left: -125px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  margin-top: 80px;
  max-width: 500px;
  overflow: hidden;
  padding: 0;
  padding: 10px 20px 10px 10px;
  position: absolute;
  width: 280px;
}
.coming-soon #coming-builder i {
  -moz-animation: fa-spin 3s infinite linear;
  -o-animation: fa-spin 3s infinite linear;
  -webkit-animation: fa-spin 3s infinite linear;
  animation: fa-spin 3s infinite linear;
  float: left;
  font-size: 24px;
  padding-right: 0;
}
.coming-soon #coming-builder:hover i {
  -moz-animation: none;
  -o-animation: none;
  -webkit-animation: none;
  animation: none;
}
.coming-soon #coming-builder h3 {
  color: #2A2A2A;
  float: left;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  margin-top: 4px;
  padding-left: 8px;
  text-transform: uppercase;
}
.coming-soon #coming-builder .form-horizontal .row .col-md-12 {
  cursor: pointer;
  margin-left: 0;
}
.coming-soon #coming-builder .form-group {
  margin-bottom: 8px;
}
.coming-soon #coming-builder .form-group label:not(.switch) {
  margin-top: 1px;
}
.email-sidebar {
  min-height: 800px;
  width: 350px;
}
.email-sidebar ul li {
  font-size: 16px;
  list-style: none;
}
.email-sidebar ul li a {
  color: #696969;
  display: block;
  line-height: 32px;
  margin-bottom: 4px;
  padding-left: 10px;
  text-decoration: none !important;
}
.email-sidebar ul li a:hover {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}
.email-sidebar ul li a i {
  padding-right: 8px;
}
.email-sidebar ul li a.active {
  color: #319db5;
}
.email-content {
  -moz-box-shadow: -5px -5px 2px 0px rgba(0, 0, 0, 0.15);
  -o-box-shadow: -5px -5px 2px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: -5px -5px 2px 0px rgba(0, 0, 0, 0.15);
  box-shadow: -5px -5px 2px 0px rgba(0, 0, 0, 0.15);
  margin: auto;
  width: 100%;
  max-width: 1150px;
}
.email-template {
  display: none;
}
.email-template.active {
  display: block;
}
.email-templates iframe {
  border: 0;
  width: 100%;
}
.app aside {
  display: table-cell;
  padding-right: 15px;
  width: 320px;
}
.app-content {
  display: table-cell;
  width: 100%;
}
.responsive {
  margin-bottom: 15px;
  margin-top: 15px;
  text-align: center;
}
.responsive i {
  color: #C3C3C3;
  font-size: 20px;
}
.responsive i.icon-screen-smartphone {
  font-size: 19px;
}
.responsive i.icon-screen-tablet {
  font-size: 19px;
}
.responsive a {
  padding: 10px;
  text-decoration: none !important;
}
.responsive a:hover i {
  color: #363636;
}
.responsive a.active i {
  color: #363636;
}
@media screen and (max-width: 968px) {
  .app aside {
    clear: both;
    display: block;
    width: 100%;
  }
  .app-content {
    clear: both;
    display: block;
    padding-top: 30px;
    width: 100%;
  }
  .email-sidebar {
    min-height: 0;
    width: 100%;
  }
  .email-sidebar ul li {
    float: left;
    margin-right: 20px;
  }
  .email-sidebar ul li a {
    padding-right: 10px;
  }
}
/* Page Full Calendar */
#calendar {
  margin-top: 22px;
}
.page-calendar .add-category {
  display: inline-block;
  margin-top: 20px;
  text-decoration: none;
}
.page-calendar .add-category i {
  padding-right: 8px;
}
.fc-draggable.fc-event {
  border: none;
}
.external-event {
  cursor: move;
  margin: 10px 0;
  padding: 6px;
}
.fc-event {
  border-radius: 0;
  margin: 3px 6px;
  padding: 4px 8px;
}
.fc-state-default {
  background: #ffffff;
  box-shadow: none !important;
  color: #000000;
  margin-right: -1px;
  outline: none !important;
  text-shadow: none;
}
.fc-state-default:hover {
  background: #E4E4E4;
}
.fc-state-default.fc-corner-right {
  background-color: #ffffff;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  border: none;
  color: #111111;
}
.fc-state-default.fc-corner-right:hover {
  background: #E4E4E4;
}
.fc-toolbar {
  margin-bottom: 40px;
}
.fc-toolbar .fc-state-active {
  background: #319db5;
  box-shadow: none;
  color: #ffffff;
  cursor: default;
  margin-right: -1px;
}
.fc-toolbar .ui-state-active {
  background: #319db5;
  box-shadow: none;
  color: #ffffff;
  cursor: default;
  margin-right: -1px;
}
.fc-state-default.fc-corner-right.fc-state-active {
  background: #319db5;
  box-shadow: none;
  color: #ffffff;
  cursor: default;
  margin-right: -1px;
}
.fc-state-default.fc-state-disabled {
  background-color: #cccccc;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  color: #111111;
}
.fc .fc-toolbar > *:first-child {
  border: none;
}
.fc .fc-button-group > * {
  border: none;
}
.fc .fc-button-group .fc-prev-button {
  background: #2b2e33;
  color: #ffffff;
}
.fc .fc-button-group .fc-next-button {
  background: #2b2e33;
  color: #ffffff;
}
.fc-state-default.fc-corner-right.fc-state-disabled {
  background: #C8C8C8;
}
.fc-unthemed .fc-today {
  background: #ffffff;
}
.fc-unthemed .fc-future {
  background: #ffffff;
}
.fc-basic-view td.fc-day-number {
  padding-right: 8px;
  padding-top: 8px;
}
.fc-basic-view td.fc-day-number.fc-past {
  filter: alpha(opacity=30);
  opacity: 0.3;
}
.fc th,
.fc-unthemed thead,
.fc-unthemed .fc-row,
thead .fc-widget-header {
  border-color: transparent !important;
  border-width: 0;
  border: none;
}
.fc-head td {
  padding-bottom: 8px;
}
@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
@-webkit-keyframes pulse {
  40% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes pulse {
  40% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulse {
  40% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
  }
}
@media screen and (max-width: 1400px) {
  .coming-soon #countdown {
    left: 40px;
    position: relative;
    right: auto;
  }
  .coming-soon .content {
    float: none;
    margin: 40px;
    position: relative;
    top: 200px;
    width: 600px;
  }
}
@media (max-width: 1100px) {
  .map-container {
    margin-right: -40px;
  }
}
@media screen and (max-width: 700px) {
  .coming-soon .content {
    width: 100%;
    margin: 0;
    padding: 30px;
  }
}
/**** Search ****/
.morphsearch {
  background: #2b2e33;
  min-height: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 10000;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.morphsearch .gn-search:focus::-webkit-input-placeholder {
  color: transparent;
}
.morphsearch input[type="search"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.morphsearch .dummy-column:nth-child(2) {
  margin: 0 5%;
}
.morphsearch .dummy-column h2 {
  color: #c2c2c2;
  font-size: 1em;
  font-weight: 800;
  letter-spacing: 1px;
  padding: .5em 0;
  text-transform: uppercase;
}
.morphsearch .round {
  border-radius: 50%;
}
.morphsearch .dummy-media-object {
  background: rgba(118, 117, 128, 0.05);
  border-radius: 5px;
  cursor: pointer;
  display: block;
  margin: .3em 0;
  padding: .75em;
}
.morphsearch .dummy-media-object:hover {
  background: rgba(118, 117, 128, 0.1);
}
.morphsearch .dummy-media-object:hover h3 {
  color: #ffffff;
}
.morphsearch .dummy-media-object img {
  display: inline-block;
  margin: 0 10px 0 0;
  vertical-align: middle;
  width: 50px;
}
.morphsearch .dummy-media-object h3 {
  color: rgba(145, 145, 145, 0.7);
  display: inline-block;
  font-size: 1em;
  font-weight: 700;
  margin: 0;
  vertical-align: middle;
  width: calc(30%);
}
.morphsearch .user-column .dummy-media-object img {
  width: 35px;
}
.morphsearch.open {
  min-height: 100%;
  opacity: 1;
  right: 0;
  top: 0;
  visibility: visible;
  width: 100%;
}
.morphsearch.open .morphsearch-form {
  height: 160px;
  width: 80%;
}
.morphsearch.open .morphsearch-input {
  font-family: 'Lato', arial !important;
  font-weight: 100;
  font-size: 7em;
}
.morphsearch.open .morphsearch-submit {
  -webkit-transform: translate3d(-30px, -50%, 0) scale3d(1, 1, 1);
  -webkit-transition-delay: .5s;
  -webkit-transition: opacity 0.3s, -moz-transform 0.3s;
  -moz-transform: translate3d(-30px, -50%, 0) scale3d(1, 1, 1);
  -moz-transition-delay: .5s;
  -moz-transition: opacity 0.3s, -moz-transform 0.3s;
  transform: translate3d(-30px, -50%, 0) scale3d(1, 1, 1);
  transition-delay: .5s;
  transition: opacity 0.3s,transform .3s;
  opacity: 0.2;
  pointer-events: auto;
}
.morphsearch.open .morphsearch-close {
  -webkit-transform: scale3d(1, 1, 1);
  -moz-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
  pointer-events: auto;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.morphsearch.open .morphsearch-content {
  height: auto;
  opacity: 1;
  overflow: visible;
  pointer-events: auto;
  -webkit-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  -ms-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}
.morphsearch.open .dummy-column {
  -webkit-transform: translate3d(0, 0, 0);
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.morphsearch.open .dummy-column:first-child {
  -moz-transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.morphsearch.open .dummy-column:nth-child(2) {
  -moz-transition-delay: 0.4s;
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
.morphsearch.open .dummy-column:nth-child(3) {
  -moz-transition-delay: 0.6s;
  -webkit-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
.morphsearch-form {
  -webkit-transform: translate3d(0, 3em, 0);
  height: 40px;
  margin: 0 auto;
  position: relative;
  transform: translate3d(0, 3em, 0);
  width: 100%;
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.morphsearch-input {
  -webkit-transition: font-size 0.3s cubic-bezier(0.7, 0, 0.3, 1);
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: .8em;
  font-weight: 700;
  height: 100%;
  padding: 0 10% 0 10px;
  transition: font-size 0.3s cubic-bezier(0.7, 0, 0.3, 1);
  width: 100%;
}
.morphsearch-input::-ms-clear {
  display: none;
}
.morphsearch-input::-webkit-input-placeholder {
  color: #626262;
}
.morphsearch-input:-moz-placeholder {
  color: #626262;
}
.morphsearch-input::-moz-placeholder {
  color: #626262;
}
.morphsearch-input:-ms-input-placeholder {
  color: #626262;
}
.morphsearch-input:focus {
  outline: none;
}
.morphsearch.hideInput .morphsearch-input {
  -webkit-transition: color .3s;
  color: transparent;
  transition: color .3s;
}
.morphsearch-submit {
  -webkit-transform: translate3d(-30px, -50%, 0) scale3d(0, 0, 1);
  background-size: 100%;
  background: transparent url(../images/magnifier.svg) no-repeat center center;
  border: none;
  height: 80px;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-indent: 100px;
  top: 50%;
  transform-origin: 50% 50%;
  transform: translate3d(-30px, -50%, 0) scale3d(0, 0, 1);
  width: 80px;
}
.morphsearch-submit:focus {
  outline: none;
}
.morphsearch-close {
  -webkit-transform: scale3d(0, 0, 1);
  cursor: pointer;
  height: 36px;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 1em;
  text-indent: 100%;
  top: 1em;
  transform: scale3d(0, 0, 1);
  width: 36px;
}
.morphsearch-close::before {
  background: #ffffff;
  content: '';
  height: 100%;
  left: 50%;
  opacity: .2;
  position: absolute;
  top: 0;
  width: 2px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.morphsearch-close::after {
  background: #ffffff;
  content: '';
  height: 100%;
  left: 50%;
  opacity: .2;
  position: absolute;
  top: 0;
  width: 2px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.morphsearch-close:hover.morphsearch-close::before {
  opacity: 1;
}
.morphsearch-close:hover.morphsearch-close::after {
  opacity: 1;
}
.morphsearch-content {
  background: #2b2e33;
  color: #333333;
  height: 0;
  margin-top: 4.5em;
  opacity: 0;
  overflow: hidden;
  padding: 0 10.5%;
  pointer-events: none;
  position: absolute;
  width: 100%;
}
.dummy-column {
  -webkit-transform: translate3d(0, 100px, 0);
  float: left;
  opacity: 0;
  padding: 0 0 6em;
  transform: translateY(100px);
  width: 30%;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.dummy-media-object:focus {
  background: rgba(118, 117, 128, 0.1);
}
.overlay {
  -webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  width: 100%;
  -webkit-transition: opacity 0.5s linear;
  -moz-transition: opacity 0.5s linear;
  -o-transition: opacity 0.5s linear;
  -ms-transition: opacity 0.5s linear;
  transition: opacity 0.5s linear;
}
.morphsearch.open ~ .overlay {
  opacity: 1;
}
@media screen and (max-width: 1200px) {
  .morphsearch {
    width: 80%;
    top: 10%;
    right: 10%;
  }
}
@media screen and (max-width: 1000px) {
  .morphsearch-input {
    padding: 0 25% 0 10px;
  }
  .morphsearch.open .morphsearch-input {
    font-size: 3em;
  }
  .morphsearch.open .morphsearch-submit {
    height: 40px;
    width: 40px;
  }
  .morphsearch.open .morphsearch-form {
    height: 80px;
  }
  .morphsearch .dummy-column {
    float: none;
    padding: 0 0 2em;
    width: auto;
  }
  .morphsearch .dummy-column:nth-child(2) {
    margin: 0;
  }
}
/**** Page Builder ****/
.color-purple #page-builder .tabs-linetriangle .nav-tabs li.active a::after {
  border-bottom-color: #6E62B5;
}
.color-red #page-builder .tabs-linetriangle .nav-tabs li.active a::after {
  border-bottom-color: #C9625F;
}
.color-blue #page-builder .tabs-linetriangle .nav-tabs li.active a::after {
  border-bottom-color: #4A89DC;
}
.color-orange #page-builder .tabs-linetriangle .nav-tabs li.active a::after {
  border-bottom-color: #B66D39;
}
.color-green #page-builder .tabs-linetriangle .nav-tabs li.active a::after {
  border-bottom-color: #18A689;
}
#hidden-small-screen-message {
  display: none;
}
.main-content .page-content.page-builder {
  margin-top: 120px;
}
.builder-wrapper {
  padding-top: 70px;
}
#page-builder .layout,
#page-builder .portlet,
#page-builder .element,
#page-builder .form,
#page-builder .build-table,
#page-builder .build-widget {
  background: #F5F5F5;
  color: #121212;
  cursor: move;
  float: left;
  margin-bottom: 10px;
  margin-right: 20px;
  min-height: 60px;
  opacity: 0.8;
  padding: 10px;
  position: relative;
  text-align: center;
  width: 140px;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
#page-builder .layout:hover,
#page-builder .portlet:hover,
#page-builder .element:hover,
#page-builder .form:hover,
#page-builder .build-table:hover,
#page-builder .build-widget:hover {
  opacity: 1;
}
#page-builder {
  color: #ffffff;
  left: 240px;
  position: fixed;
  right: 0;
  top: 50px;
  z-index: 20;
}
#page-builder .tab-content {
  padding: 10px 30px 0;
}
#page-builder .tab-content p {
  margin-bottom: 0;
}
#page-builder .tabs-linetriangle .nav.nav-tabs {
  background: #E4E5E5;
  border-bottom: none;
}
#page-builder .tabs-linetriangle .nav.nav-tabs > li > a {
  color: #8D8D8D;
}
#page-builder .tabs-linetriangle .nav-tabs li:hover a {
  color: #121212;
}
#page-builder .tabs-linetriangle .nav-tabs li.active a {
  color: #121212;
}
#page-builder .tabs-linetriangle .nav-tabs li.active a::before {
  border-bottom: none;
}
#page-builder .tabs-linetriangle .nav-tabs li.active a::after {
  border-bottom-color: #319DB5;
}
.sidebar-collapsed #page-builder {
  left: 50px;
  right: 0;
}
.sidebar-condensed #page-builder {
  left: 190px;
  right: 0;
}
.sidebar-hover #page-builder {
  left: 0;
}
.page-builder .placeholder-container {
  background-color: #ffffff;
  float: left;
  margin-bottom: 5px;
  margin-top: 5px;
  opacity: 0.5;
  padding: 0;
  position: relative;
  transition: opacity 200ms ease;
  width: 100%;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.page-builder .placeholder-container:hover .placeholder-handle {
  opacity: 1;
}
.page-builder .placeholder-container:hover .placeholder-handle:hover i {
  color: rgba(255, 255, 255, 0.6);
}
.page-builder .placeholder-container:hover .placeholder-handle:hover i:hover {
  color: #ffffff;
}
.page-builder .placeholder {
  background-color: #ffffff;
  float: left;
  position: relative;
  width: 100%;
  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  -ms-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}
.page-builder .placeholder-container.active {
  opacity: 1;
}
.page-builder .placeholder-handle {
  background-color: #ffffff;
  display: block;
  height: 100%;
  left: -22px;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 22px;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  -ms-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.page-builder .placeholder-handle:hover {
  background-color: #319DB5!important;
  cursor: move;
}
.page-builder .placeholder-handle i {
  color: rgba(0, 0, 0, 0.5);
  display: inline-block;
  font-size: 11px;
}
.page-builder .placeholder-handle > div {
  text-align: center;
}
.page-builder .handle-move {
  margin-top: 5px;
  width: 100%;
}
.page-builder .handle-move:hover i {
  color: #ffffff;
}
.page-builder .handle-remove {
  cursor: pointer;
  width: 100%;
}
.page-builder .handle-remove:hover i {
  color: #ffffff;
}
.page-builder .handle-options {
  cursor: pointer;
  width: 100%;
}
.page-builder .handle-options:hover i {
  color: #ffffff;
}
.page-builder .placeholder-content {
  background: #ffffff;
  float: left;
  height: 100%;
  min-height: 55px;
  padding: 15px;
  position: relative;
}
.page-builder .placeholder-content .placeholder-content-area {
  background: #E5E5E5;
  min-height: 50px;
}
.page-builder .placeholder-content .fileinput {
  background: #ffffff;
  text-align: center;
}
.page-builder .placeholder-content-area {
  height: 100%;
  padding: 7px;
}
.page-builder .note-editable {
  color: #319DB5 !important;
}
.page-builder .placeholder-content > div.select-image {
  background: #3A3A3A;
  color: #cccccc;
  cursor: pointer;
  font-size: 20px;
  height: 90px;
  padding-top: 20px;
  text-align: center;
  width: 100%;
}
.page-builder #components {
  min-height: 500px;
}
.page-builder #target fieldset {
  border: 1px solid #cccccc;
  min-height: 200px;
  overflow: hidden;
  padding-bottom: 30px;
  padding: 5px;
  width: 100%;
}
.page-builder .component {
  cursor: pointer;
}
.page-builder input {
  cursor: pointer;
}
.page-builder textarea {
  resize: vertical;
}
.page-builder .popover {
  z-index: 9999999;
}
.page-builder .popover .control-group {
  cursor: default;
}
.page-builder .popover input {
  cursor: text;
}
.page-builder .temp {
  background: transparent;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999999;
}
.page-builder .temp form {
  -webkit-transition: -webkit-transform 0.1s ease-out;
  background: #ffffff;
  border: 1px dotted #cccccc;
  overflow: hidden;
  padding: 8px 4px;
  position: absolute;
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
}
.page-builder #render {
  font-family: monospace;
  min-height: 500px;
}
.page-builder form .popover .controls {
  margin-left: 0;
}
.page-builder form .popover .control-label {
  text-align: left;
}
.page-builder form .popover form {
  width: 224px;
}
.page-builder .popover-content form .btn {
  margin-right: 10px;
}
.placeholder-container:hover {
  opacity: 1;
}
.select-image {
  font-size: 16px;
  text-align: center;
}
.select-image i {
  color: #ffffff;
  display: block;
  font-size: 30px;
  margin-bottom: 8px;
}
.arrow_box {
  background: #2A2A2A;
  max-width: 240px !important;
  padding: 10px 20px;
  position: relative;
  text-align: center;
  -webkit-box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.arrow_box p {
  color: #ffffff;
  font-size: 18px;
  text-align: center;
}
.arrow_box:before {
  border-top-color: #2A2A2A;
  border-width: 10px;
  border: solid transparent;
  content: " ";
  height: 0;
  left: 50%;
  margin-left: -10px;
  position: absolute;
  top: 100%;
  width: 0;
}
.builder-placeholder {
  background-color: rgba(0, 0, 0, 0.1) !important;
  visibility: visible !important;
}
.placeholder-content form {
  padding: 20px;
}
.placeholder-content form h2 {
  margin-top: 5px;
}
.placeholder-content form h3 {
  margin-top: 5px;
}
.drop-hover .placeholder-content-area {
  background: #ffffff !important;
  border: 2px dashed #CBD3DA;
}
.layout.ui-sortable-placeholder {
  background: rgba(0, 0, 0, 0) !important;
  border: 2px dashed #CBD3DA !important;
  margin-bottom: 10px;
  margin-top: 10px;
}
.target:after {
  background: #f0f0f0;
  border: 1px dashed #cccccc;
  content: " ";
  float: left;
  height: 77px;
  margin-top: 3px;
  width: 100%;
}
.page-builder #target,
.page-builder #input,
.page-builder #radioscheckboxes,
.page-builder #select,
.page-builder #buttons {
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}
@media screen and (max-width: 1024px) {
  #page-builder {
    display: none;
    left: 0;
  }
  .builder-wrapper {
    display: none;
  }
  .footer {
    display: none;
  }
  #hidden-small-screen-message {
    display: block;
    margin-top: 5%;
  }
  #hidden-small-screen-message h2 {
    display: block;
    font-family: 'Lato', 'Open Sans', arial;
    font-weight: 100;
    margin-bottom: 30px;
    margin: auto;
    text-align: center;
  }
  #hidden-small-screen-message img {
    display: block;
    margin-bottom: 30px;
    margin: auto;
    max-width: 300px;
    text-align: center;
  }
  #hidden-small-screen-message p {
    display: block;
    font-size: 16px;
    margin: auto;
    text-align: center;
  }
}
/* Theme Builder */
.builder {
  -webkit-transition: -webkit-transform 600ms cubic-beziercubic-bezier(0.25, 0.1, 0.25, 1);
  background: #ffffff;
  border-left: 1px solid rgba(224, 226, 234, 0.53);
  bottom: 0;
  box-shadow: 0 0 9px rgba(155, 155, 155, 0.55);
  position: fixed;
  right: -240px;
  top: 0;
  transition: transform 600ms cubic-bezier(0.25, 0.1, 0.25, 1);
  width: 240px;
  z-index: 1000;
}
.builder .inner .builder-container {
  padding: 20px;
}
.builder h4 {
  font-family: 'Lato', 'Open Sans';
  font-size: 14px;
  font-weight: 900;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-top: 10px;
  text-transform: uppercase;
}
.builder a {
  color: #A1A1A1;
  display: block;
  position: relative;
  width: 100%;
}
.builder a:hover {
  color: #818181;
  text-decoration: none !important;
}
.builder a:active {
  color: #818181;
  text-decoration: none !important;
}
.builder a:focus {
  color: #818181;
  text-decoration: none !important;
}
.builder a.active {
  color: #2E2E2E;
}
.builder .layout-option {
  margin-bottom: 20px;
}
.builder .switch {
  margin-top: -4px;
}
.builder .theme-selector.active:before {
  right: 10px;
  top: 54px;
}
.builder .builder-close {
  padding: 7px;
  position: absolute;
  right: 36px;
  top: 20px;
  width: auto;
  z-index: 10;
}
.builder .builder-toggle {
  cursor: pointer;
  font-size: 16px;
  height: 50px;
  left: -48px;
  line-height: 50px;
  padding-top: 2px;
  position: absolute;
  text-align: center;
  top: 23%;
  width: 54px;
  z-index: 1010;
}
.builder .builder-toggle i {
  padding-right: 5px;
}
.builder:before {
  background-color: #ffffff;
  border-bottom-left-radius: 4px;
  border-left: 1px solid rgba(224, 226, 234, 0.53);
  border-top-left-radius: 4px;
  box-shadow: -3px 2px 2px rgba(189, 189, 189, 0.28);
  content: '';
  height: 50px;
  left: -47px;
  position: absolute;
  top: 23%;
  width: 48px;
}
.builder .color-options p {
  margin-bottom: 0;
}
.builder .theme-color {
  cursor: pointer;
  display: inline-block;
  height: 22px;
  margin-right: 3px;
  position: relative;
  width: 22px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.builder .theme-color.bg-clean {
  border: 2px solid #E4E4E4;
}
.builder .bg-color {
  border: 2px solid #E4E4E4;
}
.builder .theme-color.active:after {
  color: #ffffff;
  content: "\e125";
  display: inline-block;
  font-family: "line-icons" !important;
  font-size: 11px;
  left: 5px;
  position: absolute;
  text-align: center;
  top: 5px;
}
.builder .bg-color.active:after {
  color: #464646;
  content: "\e125";
  display: inline-block;
  font-family: "line-icons" !important;
  font-size: 11px;
  left: 3px;
  position: absolute;
  text-align: center;
  top: 3px;
}
.builder .background-primary {
  background-color: #319DB5 !important;
  color: #ffffff !important;
}
.builder .theme {
  border: 1px solid #ededed;
  cursor: pointer;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
}
.builder .theme div {
  display: inline-block;
  float: left;
  height: 40px;
  width: 50%;
}
.builder .theme div:nth-child(1) {
  width: 30%;
}
.builder .theme div:nth-child(3) {
  width: 30%;
}
.builder .theme div:nth-child(2) {
  width: 70%;
}
.builder .theme div:nth-child(4) {
  width: 70%;
}
.builder .theme div.header {
  height: 10px;
}
.builder .theme.active:before {
  background: rgba(0, 0, 0, 0.2);
  bottom: 0;
  content: "";
  height: 50px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 93px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.builder .theme.active:after {
  color: #ffffff;
  content: "\e125";
  display: inline-block;
  font-family: "line-icons" !important;
  font-size: 11px;
  left: 9px;
  position: absolute;
  right: auto;
  text-align: center;
  top: 32px;
  z-index: 100000;
}
.builder #reset-style {
  margin: 0;
  width: auto;
}
.builder.open {
  -ms-transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
.bg-color {
  cursor: pointer;
  display: inline-block;
  height: 22px;
  margin-right: 3px;
  position: relative;
  width: 22px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.colors span {
  display: inline-block;
  padding-top: 4px;
}
.color-blue:not(body) {
  background-color: #0A8CC3;
}
.color-red:not(body) {
  background-color: #C75757;
}
.color-green:not(body) {
  background-color: #1DA079;
}
.color-orange:not(body) {
  background-color: #D28857;
}
.color-purple:not(body) {
  background-color: #B179D7;
}
.color-pink:not(body) {
  background-color: #C47786;
}
.color-blue-light:not(body) {
  background-color: #619AA7;
}
#tab-themes .c-light {
  margin-bottom: 0;
  margin-top: 10px;
}
.export-page {
  bottom: 15px;
  left: 50%;
  margin-left: -95px;
  position: fixed;
}
/* Email Builder */
.main-content .page-content.email-builder {
  padding-right: 260px;
}
/* Frontend Builder */
.main-content .page-content.frontend-builder {
  padding-right: 260px;
}
/* Page Builder */
.main-content .page-content.page-builder {
  background: #ffffff;
}
.main-content .page-content.page-builder .header {
  position: relative;
}
.main-content .page-content.page-builder .breadcrumb-wrapper {
  position: absolute;
  right: 0;
  top: 0;
}
.user-profile-img {
  -moz-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  -webkit-transition: all 250ms ease-in-out;
  border: 2px solid #E6E6E6;
  cursor: pointer;
  opacity: 0.8;
  transition: all 250ms ease-in-out;
}
.user-profile-img:hover {
  border: 2px solid #C4C4C4;
  opacity: 1;
}
.user-profile-img.active {
  border: 2px solid #319db5;
  opacity: 1;
}
.form-sortable-btn {
  -webkit-font-smoothing: antialiased;
  color: #D6D6D6;
  content: "\e023";
  font-family: 'Simple-Line-Icons' !important;
  font-size: 20px;
  position: absolute;
  right: 0;
}
.form-sortable-btn:hover {
  -moz-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  -webkit-transition: all 250ms ease-in-out;
  color: #3B3B3B;
  cursor: pointer;
  transition: all 250ms ease-in-out;
}
.rtl {
  direction: rtl;
}
.rtl .sidebar .logopanel {
  left: auto;
  right: 0;
}
.rtl .f-left {
  float: right !important;
}
.rtl .f-right {
  float: left !important;
}
.rtl ul {
  direction: rtl;
}
.rtl li {
  direction: rtl;
}
.rtl em {
  direction: ltr;
  float: left;
}
.rtl .m-r-20 {
  margin-left: 20px !important;
  margin-right: 0 !important;
}
.rtl div {
  text-align: right;
}
.rtl p {
  text-align: right;
}
.rtl span {
  text-align: right;
}
.rtl .widget-small .title {
  text-align: left;
}
body.rtl {
  text-align: right;
}
/**** TOPBAR ****/
.rtl .topbar {
  left: 0;
}
.rtl .topbar .header-right {
  float: left;
}
.rtl .topbar .header-right .header-menu > li > a > i {
  float: right;
  padding-left: 5px;
  padding-right: 0;
}
.rtl .topbar .header-right .header-menu .badge-header {
  left: 11px;
  right: auto;
}
.rtl .topbar .header-left {
  float: right;
}
.rtl .topbar .header-left .topnav > ul > li > a .badge {
  margin-left: 0;
  margin-right: 10px;
}
.rtl .topbar .header-left .topnav > ul > li > a i {
  margin-left: 0;
  margin-right: 5px;
}
.rtl .topbar .header-left .topnav > ul > li > a i.icons-arrows-06 {
  padding-left: 0;
  padding-right: 7px;
}
.rtl .topbar .header-left .topnav .menutoggle {
  border-right: none;
  float: right;
  left: auto;
  right: 0;
}
.rtl .topbar .header-left .topnav .menutoggle i {
  margin: 14px 15px 0 0;
}
.rtl .topbar .header-left .topnav .menutoggle i:before {
  content: "s";
}
.rtl .topbar .searchform {
  float: right;
}
.rtl .sidebar-collapsed .topbar .header-left {
  margin-right: 0;
}
.rtl .sidebar-collapsed .topbar .header-left .topnav .menutoggle {
  border-right: none;
}
.menutoggle .glyphicon {
  margin: 14px 15px 0 0;
}
/**** SIDEBAR *****/
.rtl .sidebar-collapsed .mmenu .dropdown.mmenu-fw .dropdown-menu {
  left: 0;
  right: 50px;
}
.rtl .sidebar-collapsed .nav-sidebar .children li:before {
  left: 172px;
}
.rtl .sidebar-collapsed .nav-sidebar li.nav-hover ul {
  left: auto;
  right: 45px;
  -webkit-border-radius: 0 0 0 2px;
  -moz-border-radius: 0 0 0 2px;
  border-radius: 0 0 0 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.rtl .sidebar-collapsed .nav-sidebar ul a {
  padding-right: 0;
  padding: 6px 10px;
  text-align: right;
}
.rtl .sidebar-collapsed .topbar {
  margin-left: 0;
  margin-right: -50px;
}
.rtl .sidebar-collapsed .topbar .header-left .topnav .menutoggle i:before {
  content: "r";
}
.rtl .sidebar-collapsed .main-content {
  margin-left: 0;
  margin-right: 50px;
}
.rtl .sidebar-collapsed .nav-sidebar > li > a > span:last-child {
  left: auto;
  padding: 10px 5px 10px 10px;
  right: 40px;
  text-align: right;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.rtl .sidebar-collapsed .nav-sidebar > li > a:hover {
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.rtl .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-hover > a {
  -moz-border-bottom-left-radius: 0 !important;
  -moz-border-bottom-right-radius: 2px !important;
  -moz-border-top-left-radius: 0 !important;
  -moz-border-top-right-radius: 2px !important;
  -webkit-border-bottom-left-radius: 0 !important;
  -webkit-border-bottom-right-radius: 2px !important;
  -webkit-border-top-left-radius: 0 !important;
  -webkit-border-top-right-radius: 2px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 2px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 2px !important;
}
.rtl .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-hover > a i {
  -moz-border-bottom-left-radius: 2px !important;
  -moz-border-top-left-radius: 2px !important;
  -webkit-border-bottom-left-radius: 2px !important;
  -webkit-border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
  border-radius: 0;
  border-top-left-radius: 2px !important;
}
.rtl .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-hover > a span:not(.badge) {
  -moz-border-bottom-left-radius: 2px !important;
  -moz-border-top-left-radius: 2px !important;
  -webkit-border-bottom-left-radius: 2px !important;
  -webkit-border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
  border-radius: 0;
  border-top-left-radius: 2px !important;
}
.rtl .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li > a > i {
  padding-top: 3px;
}
.rtl .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar li.nav-hover ul {
  -webkit-border-radius: 0 0 0 2px;
  -moz-border-radius: 0 0 0 2px;
  border-radius: 0 0 0 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.rtl .sidebar {
  left: auto !important;
  right: 0;
}
.rtl .sidebar .sidebar-inner .sidebar-top .userlogged > i {
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  float: right;
  transform: scale(-1, 1);
}
.rtl .sidebar .sidebar-inner .sidebar-top .searchform input {
  background-position: 15px center;
}
.rtl .sidebar .sidebar-inner .nav-sidebar .pull-right {
  float: left!important;
}
.rtl .sidebar .sidebar-inner .nav-sidebar li i {
  float: right;
  margin-left: 10px;
  margin-right: 0;
  text-align: right;
}
.rtl .sidebar .sidebar-inner .nav-sidebar .children > li > a {
  padding: 7px 49px 7px 20px;
  padding: 5px 27px 5px 0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.rtl .sidebar .sidebar-inner .nav-sidebar .children:after {
  left: auto;
  right: 27px;
}
.rtl .sidebar .sidebar-inner .nav-sidebar .children li:before {
  left: auto;
  right: 24px;
}
.rtl .sidebar .sidebar-inner .nav-sidebar .arrow {
  left: 20px;
  margin-right: 0 !important;
  margin-top: 3px;
  right: auto;
}
.rtl .sidebar .sidebar-inner .nav-sidebar .arrow:before {
  content: "\f104";
}
.rtl .sidebar .sidebar-inner .nav-sidebar li > a .fa.arrow {
  padding-left: 0;
  padding-right: 2px;
  width: 10px;
}
.rtl .sidebar .sidebar-inner .nav-sidebar li.active > a .fa.arrow:before {
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.rtl .sidebar .sidebar-inner .nav-sidebar > li.nav-parent > a {
  background-position: 10px center;
}
.rtl .sidebar .sidebar-inner .sidebar-footer {
  left: auto;
  right: 0;
}
.rtl .sidebar .sidebar-inner .sidebar-footer .datainfo {
  float: right;
}
.rtl .sidebar .sidebar-inner .sidebar-footer .chart {
  float: left;
}
.rtl .sidebar .sidebar-inner .nav-sidebar > li > a {
  text-align: right;
}
.rtl .sidebar .sidebar-inner .nav-sidebar > li > a i {
  padding-top: 3px;
}
.rtl .sidebar .sidebar-inner .menu-title {
  text-align: right;
}
.rtl .sidebar-collapsed.fixed-topbar .topbar .header-left .topnav > ul {
  margin-right: 50px;
}
.rtl .nav-sidebar li .glyphicon {
  float: right;
  margin-left: 10px;
  margin-right: 0;
}
.rtl .rtl:not(.sidebar-top) .sidebar .sidebar-inner .nav-sidebar > li > a {
  height: auto;
}
.rtl .sidebar-collapsed.rtl:not(.sidebar-top) .sidebar .sidebar-inner .nav-sidebar > li > a {
  height: 41px;
  text-align: center;
}
.rtl body:not(.sidebar-top) .sidebar .sidebar-inner .nav-sidebar li i {
  float: right;
}
.rtl.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar li i {
  float: none;
  margin-left: 0;
}
/**** SIDEBAR TOP USER IMAGE ****/
.rtl .sidebar .sidebar-top.small-img .user-image {
  float: right;
  width: 32%;
}
.rtl .sidebar .sidebar-top.small-img h4 {
  text-align: right;
}
.rtl .sidebar .sidebar-top.small-img .user-details {
  float: right;
  padding-left: 0;
  padding-right: 15px;
  text-align: right;
}
.rtl .sidebar .sidebar-top.small-img .user-details .user-login .btn {
  margin-right: -7px;
}
.rtl .sidebar-condensed .sidebar .sidebar-top.small-img .user-login {
  margin-left: 0;
  margin-right: -5px;
}
.rtl .sidebar-light .sidebar .sidebar-top.small-img .user-login {
  margin-left: 0;
  margin-right: -5px;
}
/**** SIDEBAR SUBMENU ON HOVER *****/
.rtl .submenu-hover .sidebar .sidebar-inner .nav-sidebar .nav-parent .children {
  left: auto;
  right: 240px;
}
.rtl .submenu-hover .sidebar .sidebar-inner .nav-sidebar .children > li > a {
  padding-left: 0;
  padding-right: 20px;
}
.rtl .submenu-hover .sidebar .sidebar-inner .nav-sidebar .children > li > a .badge {
  margin-left: 10px;
  margin-right: 0;
}
.rtl .submenu-hover .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #202226;
  border-right: none;
  left: 0;
  right: auto;
}
.rtl .submenu-hover .sidebar-light.sidebar .sidebar-inner .nav-sidebar .nav-parent .children {
  margin-left: 0;
  margin-right: 220px;
}
.rtl .submenu-hover .sidebar-condensed.sidebar .sidebar-inner .nav-sidebar .nav-parent .children {
  margin-left: 0;
  margin-right: 210px;
}
.rtl .sidebar-collapsed.submenu-hover .sidebar .sidebar-inner .nav-sidebar .nav-parent .children {
  margin-right: 0;
}
.rtl .submenu-hover.sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar .children {
  left: 0;
  right: 45px;
}
.rtl .submenu-hover.sidebar-condensed .sidebar .sidebar-inner .nav-sidebar .nav-parent .children {
  margin-left: 0;
  margin-right: 190px;
}
.rtl .submenu-hover.sidebar-light .sidebar .sidebar-inner .nav-sidebar .nav-parent .children {
  margin-left: 0;
  margin-right: 220px;
}
/**** SIDEBAR SHOW ON HOVER ****/
.rtl .sidebar-hover .sidebar {
  margin-left: auto;
  margin-right: -220px;
  opacity: 0;
  z-index: 500 !important;
}
.rtl .sidebar-hover .main-content {
  margin-left: 0;
  margin-right: 0;
}
.rtl .sidebar-hover.fixed-topbar .topbar {
  left: 0;
  right: 0;
}
/**** BOXED LAYOUT ****/
.rtl .boxed.fixed-sidebar.sidebar-collapsed .topbar .header-left .topnav .menutoggle {
  left: auto;
  margin-left: 0;
  margin-right: auto;
  opacity: 0.9;
  right: 0;
  right: auto;
}
.rtl .boxed.fixed-sidebar.sidebar-collapsed .topbar .header-left .topnav .menutoggle:hover {
  opacity: 1;
}
.rtl .boxed.sidebar-collapsed .topbar .header-left {
  margin-right: 0;
}
.rtl .boxed.sidebar-collapsed .topbar .header-left .topnav .menutoggle {
  background: #319DB5;
}
.rtl .main-content .footer.footer-absolute {
  left: 40px;
  right: 300px;
}
.rtl .sidebar-collapsed .main-content .footer.footer-absolute {
  left: 40px;
  right: 100px;
}
.rtl.boxed .logopanel {
  float: right;
  right: auto;
}
.rtl.boxed .sidebar .sidebar-inner .sidebar-footer {
  right: auto;
}
.rtl.boxed.sidebar-top.fixed-topbar .sidebar .logopanel {
  left: auto;
  right: auto;
}
.rtl.boxed.sidebar-top .topbar {
  margin-left: auto;
  margin-right: 140px;
}
.rtl.boxed.sidebar-collapsed .topbar {
  margin-left: auto;
  margin-right: -50px;
}
.rtl.boxed.sidebar-collapsed.fixed-topbar .topbar {
  margin-left: auto;
  margin-right: -50px;
}
.rtl.boxed.fixed-topbar.sidebar-collapsed .topbar .header-left .topnav .menutoggle {
  left: auto;
  margin-left: auto;
  margin-right: 0;
  position: relative;
  right: 0;
}
.rtl.boxed.fixed-sidebar.sidebar-collapsed .topbar .header-left .topnav .menutoggle {
  left: auto;
  margin-left: auto;
  margin-right: 0;
  position: relative;
  right: 0;
}
/* UI */
.rtl .pull-left {
  float: right!important;
}
.rtl .pull-right {
  float: left!important;
}
.rtl .media > .pull-left {
  margin-left: 10px;
  margin-right: 0;
}
.rtl .panel-btns a {
  float: left;
  margin-left: 0;
  margin-right: 8px;
}
.rtl .input-group-btn .dropdown-menu {
  right: auto;
}
.rtl .form-inline .checkbox {
  margin-left: 20px;
  margin-right: 0;
}
.rtl .btn-group > .btn {
  float: right;
}
.rtl .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.rtl .btn-group > .btn:last-child:not(:first-child) {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.rtl .btn-group > .btn:last-child:not(:first-child):not(.dropdown-toggle) {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.rtl .btn-group > .btn-group:last-child > .btn:first-child {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.rtl .btn-group > .dropdown-toggle:not(:first-child) {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.rtl blockquote {
  border-left: 0;
  border-left: 5px solid #eeeeee;
  border-right: 0;
  border-right: 5px solid #eeeeee;
}
.rtl blockquote .glyphicon {
  float: right;
  margin-left: 10px;
  margin-right: 0;
}
.rtl .dropdown-menu.pull-right {
  left: 0;
  right: auto;
}
.rtl .text-right {
  text-align: left;
}
.rtl .radio label {
  padding-left: 0;
}
.rtl .btn-group {
  float: none;
}
.rtl .btn-group button {
  float: none;
}
.rtl .btn-group .btn + .btn-group {
  float: left;
  margin-left: 0;
  margin-right: -1px;
}
.rtl .btn-group.btn-group-vertical > .btn {
  float: none;
}
.rtl .dropdown-toggle .caret {
  float: left;
  margin-left: 0;
  margin-right: 8px;
  margin-top: 10px;
}
.rtl .btn-group > button ~ .dropdown-toggle > .caret {
  margin-top: 0 !important;
}
.rtl .topbar .header-right .nav > li {
  float: right;
}
.rtl .topbar .header-right .navbar-nav > li > a {
  line-height: 50px;
}
.rtl .topbar .header-right .header-menu #user-header {
  margin-left: 10px;
  margin-right: 0;
}
.rtl .topbar .header-right .header-menu #user-header .dropdown-menu li a {
  text-align: right;
}
.rtl .topbar .header-right .header-menu #user-header .dropdown-menu li a i {
  float: right;
  padding-left: 5px;
  padding-right: 0;
  right: 15px;
}
.rtl .topbar .header-right .header-menu #user-header .dropdown-menu li a span {
  padding-left: 0;
  padding-right: 35px;
}
.rtl .topbar .header-right .header-menu .dropdown-menu {
  left: 0;
  right: auto;
}
.rtl .topbar .header-right .header-menu .dropdown-menu:after {
  left: 17px;
  right: auto !important;
}
.rtl .topbar .large-dropdown-menu ul li a .badge {
  float: left;
  margin-left: 0;
  margin-right: 6px;
}
.rtl .nav-pills > li {
  float: none;
}
.rtl .header-menu .dropdown-menu-usermenu {
  left: 0;
  right: auto;
}
.rtl .header-menu .dropdown-menu li i {
  margin-left: 5px;
  margin-right: 0;
}
.rtl .header-menu .dropdown-menu-head {
  left: 0;
  right: auto;
}
.rtl .header-menu .tp-icon + .dropdown-menu:after {
  left: 17px;
  right: auto;
}
.rtl .dropdown-list .thumb {
  float: right;
}
.rtl .dropdown-list .desc {
  margin-left: 0;
  margin-right: 45px;
}
.rtl .dropdown-list li .badge {
  float: left;
}
.rtl .main-content {
  margin-left: 0;
  margin-right: 240px;
}
.rtl .main-content .page-content .panel-header i {
  float: right;
  padding-left: 6px;
  padding-right: 0;
}
.rtl .main-content .page-content .panel-header i.icons-documents-bookmarks-01 {
  margin-top: 2px;
}
.rtl .main-content .page-content .header .breadcrumb-wrapper {
  left: 25px;
  right: auto;
}
.rtl .main-content .page-content .panel .panel-content ul.nav-tabs li {
  float: right;
}
.rtl .main-content .page-content .panel .panel-content .tab_left ul.nav-tabs li {
  float: none;
}
.rtl .main-content .page-content .panel .panel-content .tab_right ul.nav-tabs li {
  float: none;
}
.rtl .main-content .page-content .panel .panel-content i {
  float: right;
  padding-left: 6px;
  padding-right: 0;
}
.rtl .main-content .panel .control-btn {
  left: 13px;
  right: auto;
}
.rtl .main-content .panel .control-btn a {
  float: right;
}
.rtl .ckbox input[type="checkbox"]:checked + label after {
  left: auto;
  right: 3.1px;
}
.rtl .dataTable thead > tr > th {
  background-position: 10px center;
}
.rtl .nav-email > li > a > i {
  margin-left: 10px;
  margin-right: 0;
}
.rtl .chosen-container .chosen-drop {
  display: none;
  left: 0;
}
.rtl .chosen-container.chosen-with-drop .chosen-drop {
  display: block;
}
.rtl .chosen-container-single .chosen-single div {
  border-left: 0;
  border-right: 1px solid #cccccc;
}
.rtl .chosen-container-active.chosen-with-drop .chosen-single div {
  border-right: 0;
}
.rtl .chosen-rtl .chosen-choices li.search-choice {
  padding: 8px 5px 8px 19px;
}
.rtl .fm-sidebar {
  padding-left: 0;
  padding-right: 10px;
}
.rtl .mr5 {
  margin-left: 5px;
  margin-right: 0;
}
.rtl .mr10 {
  margin-left: 10px;
  margin-right: 0;
}
.rtl .mr30 {
  margin-left: 30px;
  margin-right: 0;
}
.rtl .fixed-menu .topbar {
  left: 0;
  position: fixed;
  right: 240px;
  z-index: 2;
}
.rtl .fixed-menu.sidebar-collapsed .topbar {
  left: 0;
  right: 50px;
}
.rtl .fixed-menu.sidebar-collapsed .sidebar {
  float: right;
}
.rtl .sidebar-collapsed .menutoggle {
  border-left: none;
  border-right: none;
  margin-left: -50px;
  margin-left: 0;
}
.rtl .sidebar-collapsed .nav-pills > li > a {
  -moz-border-radius: 2px !important;
  -webkit-border-radius: 2px !important;
  border-radius: 2px !important;
}
.rtl .sidebar-collapsed.fixed-topbar .menutoggle {
  margin-left: -50px;
}
.rtl .sidebar-collapsed.fixed-topbar .topbar {
  left: 0;
  right: 50px;
}
.rtl .sidebar-collapsed.fixed-sidebar .menutoggle {
  margin-left: -50px;
}
.rtl .nav-pills > li > a {
  -moz-border-radius: 2px !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  height: auto;
  text-align: right;
}
.rtl .sidebar .sidebar-inner .nav-sidebar .children > li > a {
  padding: 7px 49px 7px 20px;
  text-align: right;
}
.rtl .sidebar .sidebar-inner .sidebar-widgets ul i {
  float: right;
  padding-left: 10px;
  padding-right: 0;
}
.rtl .sidebar .ui-resizable-handle {
  left: 0;
  right: auto;
}
.rtl .mCustomScrollBox > .mCSB_scrollTools {
  left: 0;
  right: auto;
}
.rtl .fixed-sidebar .topbar .header-left .topnav > ul {
  margin-left: 0;
}
.rtl .fixed-sidebar:not(.boxed) .topbar .header-left .topnav > ul {
  margin-right: 50px;
}
.rtl .fixed-sidebar:not(.fixed-topbar) .topbar .header-left .topnav > ul {
  margin-right: 0;
  margin-right: 50px;
}
.rtl .fixed-sidebar.fixed-topbar .topbar .header-left .topnav > ul {
  margin-right: 0;
}
.rtl .fixed-sidebar.fixed-topbar.sidebar-collapsed .topbar .header-left .topnav > ul {
  margin-right: 50px;
}
.rtl .fixed-topbar .topbar {
  left: 0;
  position: fixed;
  right: 0;
  right: 240px;
  z-index: 100;
}
.rtl .prepend-icon input {
  padding-left: 10px;
  padding-right: 36px;
}
.rtl .prepend-icon span {
  padding-left: 10px;
  padding-right: 36px;
}
.rtl .prepend-icon i {
  right: 0;
}
.rtl .form-horizontal .prepend-icon i {
  left: auto;
  right: 15px;
}
.rtl .form-horizontal .append-icon i {
  left: 15px;
  right: auto;
}
.rtl .append-icon input {
  padding-left: 36px;
  padding-right: 12px;
}
.rtl .btn {
  margin-left: 10px;
  margin-right: 0;
}
.rtl .btn.btn-transparent {
  margin-left: 20px;
  margin-right: 0;
}
.rtl .close {
  float: left;
}
.rtl .progress-bar {
  float: right;
}
.rtl .nav-tabs > li > a {
  margin-left: 2px;
  margin-right: 0;
}
.rtl .fa-li {
  left: auto;
  right: -2.14285714em;
}
.rtl .fa-ul {
  list-style-type: none;
  margin-left: 0;
  margin-right: 2.14285714em;
}
.rtl .panel-content li:not(.paginate_button) {
  margin-left: 0;
  margin-right: 20px;
}
.rtl .panel-content .nav-tabs li:not(.paginate_button) {
  margin-left: 0;
  margin-right: 0;
}
.rtl .bd-green {
  border-left: none;
  border-right: 3px solid #18a689;
  padding-left: 0;
  padding-right: 20px;
}
.rtl .bd-red {
  border-left: none;
  border-right: 3px solid #C9625F;
  padding-left: 0;
  padding-right: 20px;
}
.rtl .bd-blue {
  border-left: none;
  border-right: 3px solid #319DB5;
  padding-left: 0;
  padding-right: 20px;
}
.rtl .bd-l-red {
  border-left: none;
  border-right: 4px solid #C9625F;
  padding-left: 0;
  padding-right: 20px;
}
.rtl .bd-l-green {
  border-left: none;
  border-right: 4px solid #18a689;
  padding-left: 0;
  padding-right: 20px;
}
.rtl .bd-l-blue {
  border-left: none;
  border-right: 4px solid #1D86A9;
  padding-left: 0;
  padding-right: 20px;
}
.rtl .bd-l-dark {
  border-left: none;
  border-right: 4px solid #2B2E33;
  padding-left: 0;
  padding-right: 20px;
}
.rtl .bd-l-purple {
  border-left: none;
  border-right: 4px solid #A58BD3;
  padding-left: 0;
  padding-right: 20px;
}
.rtl .dd {
  display: inline-block;
  max-width: 600px;
  width: 70%;
}
.rtl .jstree-default .jstree-ocl {
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: transform 0.15s linear;
  -moz-transition: transform 0.15s linear;
  -o-transition: transform 0.15s linear;
  -ms-transition: transform 0.15s linear;
  transition: transform 0.15s linear;
}
.rtl .sidebar-hover .topnav .logopanel2 {
  float: right;
}
blockquote i {
  float: right;
  margin-left: 10px;
  margin-right: 0;
}
.checkbox label {
  padding-left: 0;
}
.rtl .radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  margin-right: -20px;
}
.rtl .radio input[type="radio"],
.rtl .radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.rtl .checkbox-inline input[type="checkbox"] {
  margin-right: -20px;
}
.rtl i.online,
.rtl i.busy,
.rtl i.away,
.rtl i.offline,
.rtl i.turquoise {
  float: right;
  margin-left: 8px;
  margin-right: 0;
}
@media screen and (max-width: 1024px) {
  .rtl .topbar {
    left: 0 !important;
    right: 0 !important;
  }
  .rtl .topbar .header-left .topnav .menutoggle i:before {
    content: "r" !important;
  }
  .rtl .fixed-topbar .topbar {
    left: 0 !important;
    right: 0 !important;
  }
  .rtl .main-content {
    margin-right: 0;
    width: 100%;
  }
  .rtl .sidebar-collapsed .sidebar {
    display: none;
  }
  .rtl .sidebar-collapsed .main-content {
    margin-left: 0;
  }
  .rtl .sidebar-collapsed .topbar {
    margin-left: 0;
  }
  .rtl .sidebar-show section {
    overflow-x: hidden;
  }
  .rtl .sidebar-show .sidebar {
    display: block;
  }
  .rtl .sidebar-show .main-content {
    margin-left: 0 !important;
    position: relative;
    right: 240px !important;
  }
  .rtl .sidebar-show .topbar .header-left .topnav .menutoggle i:before {
    content: "s" !important;
  }
  .rtl .sidebar-show.fixed-topbar .topbar {
    left: 0 !important;
    right: 240px !important;
  }
  .rtl .nav-horizontal {
    position: relative;
  }
  .rtl .nav-horizontal .caret {
    display: none;
  }
  .rtl .sidebar-top .menutoggle {
    display: none;
  }
  .rtl .sidebar-top:not(.fixed-topbar) .sidebar .logopanel {
    background: #ffffff;
    left: 0;
    left: auto;
    position: absolute;
    right: 0;
    right: auto;
    top: -50px;
    top: -50px !important;
    width: 140px;
  }
  .rtl .sidebar-top .sidebar {
    bottom: auto!important;
    float: none;
    height: 66px;
    overflow: visible!important;
    position: fixed;
    position: relative;
    top: 50px;
    top: 0;
    width: 100%;
  }
  .rtl .sidebar-top .sidebar .logopanel h1 a {
    background: url(../images/logo/logo-dark.png) no-repeat;
  }
  .rtl .sidebar-top .sidebar:before {
    display: none;
  }
  .rtl .sidebar-top .sidebar .sidebar-inner {
    padding: 0;
  }
  .rtl .sidebar-top .sidebar .sidebar-inner .nav-sidebar .nav-parent.active .children {
    display: none !important;
  }
  .rtl .sidebar-top .sidebar .sidebar-inner .nav-sidebar .nav-parent.nav-hover .children {
    display: block !important;
    margin: 0;
    padding: 0;
    position: absolute;
  }
  .rtl .sidebar-top .sidebar .sidebar-inner .nav-sidebar .children > li > a {
    padding-left: 15px;
    padding-right: 15px;
  }
  .rtl .sidebar-top .sidebar .sidebar-inner .nav-sidebar .children:after {
    display: none;
  }
  .rtl .sidebar-top .sidebar .sidebar-inner .nav-sidebar .children li:before {
    display: none;
  }
  .rtl .sidebar-top .sidebar .sidebar-inner .arrow {
    display: none;
  }
  .rtl .sidebar-top .sidebar .sidebar-inner .nav-sidebar > li > a i {
    text-align: center;
  }
  .rtl .sidebar-top .sidebar .sidebar-inner .nav-sidebar > li > a span:not(.badge) {
    text-align: center;
  }
  .rtl .sidebar-top .sidebar nav > .nav {
    float: left;
  }
  .rtl .sidebar-top .sidebar .nav > li {
    display: inline-block;
    float: right;
    position: relative;
  }
  .rtl .sidebar-top .sidebar .nav > li .children {
    display: none;
    height: auto!important;
    left: 0;
    left: auto;
    position: absolute !important;
    right: 0;
    top: auto!important;
    width: 200px;
    z-index: 1050;
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  .rtl .sidebar-top .sidebar .nav > li > a {
    height: 66px;
    padding: 10px 15px 12px;
    text-align: center;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .rtl .sidebar-top .sidebar .nav > li > a:hover {
    background: #202226;
  }
  .rtl .sidebar-top .sidebar .nav > li > a > i {
    display: block;
    float: none;
    font-size: 14px;
    line-height: 40px;
    margin: -10px auto -7px;
    width: 40px;
  }
  .rtl .sidebar-top .sidebar .nav > li > a > span {
    display: block;
    font-weight: 400;
  }
  .rtl .sidebar-top .sidebar .nav li li a {
    padding-left: 15px;
    padding-right: 15px;
  }
  .rtl .sidebar-top .sidebar .nav li:hover > .children {
    display: block;
    height: auto!important;
    margin-left: 0;
    opacity: 1;
    overflow: auto;
  }
  .rtl .sidebar-top .sidebar .nav li:focus > .children {
    display: block;
    height: auto!important;
    margin-left: 0;
    opacity: 1;
    overflow: auto;
  }
  .rtl .sidebar-top .sidebar .nav li:active > .children {
    display: block;
    height: auto!important;
    margin-left: 0;
    opacity: 1;
    overflow: auto;
  }
  .rtl .sidebar-top .topbar {
    left: 0;
  }
  .rtl .sidebar-top .topbar .header-left .topnav .menutoggle {
    height: 50px;
  }
  .rtl .sidebar-top .sidebar-footer ~ div {
    padding-bottom: 0;
  }
  .rtl .sidebar-top .aside-wrap {
    float: none;
    overflow: visible!important;
    position: relative;
    top: 0;
    width: 100%;
  }
  .rtl .sidebar-top .nav-wrap {
    float: none;
    overflow: visible!important;
    position: relative;
    top: 0;
    width: 100%;
  }
  .rtl .sidebar-top:not(.fixed-sidebar) .sidebar {
    position: absolute;
  }
  .rtl .sidebar-top .nav-sidebar > li.nav-hover > a {
    background: #202226 !important;
  }
  .rtl .sidebar-top .nav-sidebar > li + li {
    margin-top: 0;
  }
  .rtl .sidebar-top.fixed-topbar .topbar {
    left: 0;
    right: 140px;
  }
  .rtl .sidebar-top.fixed-topbar .sidebar .logopanel {
    left: auto;
    right: 0;
    width: 140px;
  }
  .rtl .sidebar-top.sidebar-fixed.app-header-fixed {
    padding-top: 115px;
  }
  .rtl .sidebar-top.sidebar-fixed .sidebar {
    position: fixed;
    top: 50px;
    width: 100%;
    z-index: 1000;
  }
  .rtl .fixed-sidebar.sidebar-top .sidebar {
    bottom: auto!important;
    height: 66px;
    position: fixed;
    top: 50px;
  }
  .rtl.sidebar-top .main-content {
    margin-left: 0;
    margin-right: 0;
  }
  .rtl .sidebar-top .sidebar-top,
  .rtl .sidebar-top .menu-title,
  .rtl .sidebar-top .sidebar .sidebar-footer,
  .rtl .sidebar-top .sidebar .sidebar-widgets {
    display: none!important;
  }
}
/**** WIDGETS ****/
.rtl .todo-list .span-check {
  float: right;
}
.rtl .todo-list li:hover .todo-options {
  left: 25px;
  max-width: 0;
  right: auto;
}
.rtl .todo-list .todo-date {
  margin-left: 0;
  margin-right: 40px;
}
.rtl .todo-list .editable-buttons {
  float: left;
  margin-left: 0 !important;
  margin-right: 15px !important;
}
.rtl .todo-list li.high {
  border-left: none;
  border-right: 2px solid #C75757;
}
.rtl .todo-list li.medium {
  border-left: none;
  border-right: 2px solid #FF9122;
}
.rtl .todo-list li.low {
  border-left: none;
  border-right: 2px solid #F3D261;
}
.rtl .todo-list .todo-tags {
  left: 9px;
  right: auto;
}
.rtl .editableform .btn.btn-sm {
  float: right;
}
.rtl .editableform .form-control {
  padding-left: 26px !important;
  padding-right: 5px !important;
}
.rtl .editable-clear-x {
  left: 6px;
  right: auto;
}
.rtl .panel-content .todo-list li {
  margin-left: 20px !important;
  margin-right: 0 !important;
}
.rtl .weather-highlighted .inner {
  float: left;
}
.rtl .weather-search-form {
  float: left;
}
.rtl .weather-search-form:before {
  left: 7px;
  right: auto;
}
.rtl .weather.panel-content li {
  margin-right: 0 !important;
}
.rtl .main-content .page-content .panel .weather.panel-content i {
  float: none !important;
}
.rtl .widget-infobox .left {
  float: left;
  padding-left: 10px;
  padding-right: 0;
}
.rtl .widget-map .col-md-3 {
  left: 0;
  right: auto;
}
.rtl .layout-options img {
  float: right;
  text-align: right;
}
.rtl .context-menu .dropdown-menu i {
  float: right;
}
.rtl .builder {
  border-right: 1px solid rgba(224, 226, 234, 0.53);
  box-shadow: 0 0 9px rgba(189, 189, 189, 0.38);
  left: -240px;
  right: auto;
}
.rtl .builder .theme div {
  float: right;
}
.rtl .builder .theme.active:after {
  left: auto;
  right: 9px;
}
.rtl .builder .theme-selector.active:before {
  left: 10px;
  right: auto;
}
.rtl .builder .nav-tabs ~ .tab-content .tab-pane {
  text-align: right;
}
.rtl .builder .builder-close {
  left: 36px;
  right: auto;
}
.rtl .builder .builder-toggle {
  left: auto;
  right: -48px;
}
.rtl .builder:before {
  border-left: 0;
  border-radius: 0 4px 4px 0;
  border-right: 1px solid rgba(224, 226, 234, 0.53);
  box-shadow: 3px 2px 2px rgba(189, 189, 189, 0.28);
  left: auto;
  right: -47px;
}
.rtl .builder .nav-tabs a::before {
  left: auto;
  right: 0;
}
.rtl .builder #reset-style {
  left: 10px;
  right: auto;
}
.rtl .builder.open {
  -ms-transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.rtl #page-builder {
  left: 0;
  right: 240px;
}
body.rtl .layout-options .img-sidebar-left {
  display: none;
}
body.rtl .layout-options .img-sidebar-right {
  display: block;
}
.rtl.sidebar-collapsed #page-builder {
  left: 0;
  right: 50px;
}
.rtl.sidebar-hover #page-builder {
  right: 0;
}
.rtl.sidebar-condensed #page-builder {
  left: 0;
  right: 190px;
}
.rtl.sidebar-top .sidebar .nav > li {
  float: right;
}
.rtl .sidebar-top .topbar {
  left: 0 !important;
  left: auto;
  right: 140px;
}
.rtl .sidebar-top:not(.fixed-topbar) .sidebar .logopanel {
  left: auto;
  right: 0;
}
.rtl .fixed-sidebar:not(.fixed-topbar) .topbar .header-left .topnav > ul {
  margin-right: 0;
}
.rtl .boxed.sidebar-top section .main-content .topbar {
  margin-left: 0;
  margin-right: 140px;
}
.rtl .boxed.sidebar-collapsed.fixed-topbar section .main-content .topbar {
  margin-left: auto;
  margin-right: -50px;
}
.rtl .boxed.sidebar-collapsed.fixed-topbar .topbar .header-left .topnav > ul {
  margin-left: auto;
  margin-right: 0;
}
.rtl .topbar .header-left .topnav > ul > li {
  float: right;
}
.rtl #quickview-sidebar {
  left: -280px;
  right: auto;
}
.rtl #quickview-sidebar .quickview .tab-content #chat .chat-body.current {
  -ms-transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.rtl #quickview-sidebar .quickview .tab-content #chat .chat-body {
  left: -280px;
  right: auto;
}
.rtl #quickview-sidebar .quickview .tab-content #chat .chat-conversation {
  left: auto;
  right: -300px;
}
.rtl #quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-header .chat-back {
  left: auto;
  right: 25px;
}
.rtl #quickview-sidebar .quickview .tab-content #chat .chat-conversation .conversation-header .chat-back .icon-action-undo:before {
  content: "\e051";
}
.rtl #quickview-sidebar .quickview .tab-content #chat .chat-conversation.current {
  -ms-transform: translate(-100%, 0);
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
.rtl #quickview-sidebar .quickview .tab-content #notes .detail-note #note-detail .note-write textarea {
  padding: 0 35px 0 0;
}
.rtl #quickview-sidebar .quickview .tab-content #notes .detail-note #note-detail .note-write:before {
  left: auto;
  right: 20px;
}
.rtl #quickview-sidebar .quickview .tab-content #notes .detail-note #note-detail .note-write:after {
  left: auto;
  right: 24px;
}
.rtl #quickview-sidebar .quickview .tab-content #notes .detail-note .note-header .note-back {
  left: auto;
  right: 20px;
}
.rtl #quickview-sidebar .quickview .tab-content #notes .detail-note .note-header .note-back .icon-action-undo:before {
  content: "\e051";
}
.rtl #quickview-sidebar.open {
  -ms-transform: translate(100%, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.rtl .sidebar-collapsed .topbar .header-left .topnav .mmenu .dropdown.mmenu-fw .dropdown-menu {
  left: 0;
  right: 50px;
}
.rtl a.scrollup {
  left: 10px;
  right: auto;
}
.rtl #map .amcharts-chart-div svg {
  left: auto;
  right: 0;
}
.rtl .sf-left {
  float: right;
}
.rtl .sf-right {
  float: left;
}
.rtl.submenu-hover.sidebar-condensed .sidebar .sidebar-inner .nav-sidebar .nav-parent .children {
  left: auto;
  margin-right: 0;
  right: 190px;
}
.rtl .sidebar-condensed.fixed-topbar .topbar {
  right: 190px;
}
.rtl .sidebar-condensed.fixed-topbar.sidebar-collapsed .topbar {
  right: 50px;
}
.rtl .theme-sltl .sidebar .sidebar-inner .sidebar-top.small-img h4 {
  color: #414141 !important;
}
.rtl .step-final p {
  text-align: center !important;
}
.rtl .submenu-hover.sidebar-light .sidebar .sidebar-inner .nav-sidebar .nav-parent .children {
  left: auto;
  margin-right: 0;
  right: 220px;
}
.rtl .main-content .footer.footer-absolute {
  left: 40px;
  right: 40px;
}
.rtl .topbar .header-right .header-menu #language-header .dropdown-menu a span {
  padding-right: 8px;
}
.rtl .topbar .header-left .topnav > ul > li > a i {
  margin-left: 5px;
  margin-right: 5px;
}
.rtl .sidebar .sidebar-inner .sidebar-widgets .charts-sidebar .sidebar-charts-inner .sidebar-charts-left .sidebar-chart-title {
  text-align: left;
}
.rtl body:not(.sidebar-top).sidebar-condensed .sidebar .sidebar-inner .nav-sidebar li i {
  display: block;
  float: none;
  text-align: center;
}
.rtl .sidebar-light:not(.sidebar-collapsed) .sidebar .sidebar-inner .nav-sidebar .arrow {
  left: 10px;
  right: auto;
}
.rtl .theme-sltd.submenu-hover .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #f2f2f2;
}
.rtl .theme-sltl.submenu-hover .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #f2f2f2;
}
.rtl .theme-sltd.submenu-hover.color-primary .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #d1dde0;
}
.rtl .theme-sltl.submenu-hover.color-primary .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #d1dde0;
}
.rtl .theme-sltd.submenu-hover.color-red .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #e3d9d7;
}
.rtl .theme-sltl.submenu-hover.color-red .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #e3d9d7;
}
.rtl .theme-sltd.submenu-hover.color-blue .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #d7dce3;
}
.rtl .theme-sltl.submenu-hover.color-blue .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #d7dce3;
}
.rtl .theme-sltd.submenu-hover.color-purple .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #dbd6e4;
}
.rtl .theme-sltl.submenu-hover.color-purple .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #dbd6e4;
}
.rtl .theme-sltd.submenu-hover.color-orange .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #e4ddd6;
}
.rtl .theme-sltl.submenu-hover.color-orange .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #e4ddd6;
}
.rtl .theme-sltd.submenu-hover.color-green .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #D7E4D6;
}
.rtl .theme-sltl.submenu-hover.color-green .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #D7E4D6;
}
.rtl .theme-sdtd.submenu-hover.color-primary .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #133c45;
}
.rtl .theme-sdtl.submenu-hover.color-primary .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #133c45;
}
.rtl .theme-sdtd.submenu-hover.color-red .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #2e100f;
}
.rtl .theme-sdtl.submenu-hover.color-red .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #2e100f;
}
.rtl .theme-sdtd.submenu-hover.color-blue .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #182e3a;
}
.rtl .theme-sdtl.submenu-hover.color-blue .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #182e3a;
}
.rtl .theme-sdtd.submenu-hover.color-purple .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #2e3342;
}
.rtl .theme-sdtl.submenu-hover.color-purple .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #2e3342;
}
.rtl .theme-sdtd.submenu-hover.color-orange .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #412715;
}
.rtl .theme-sdtl.submenu-hover.color-orange .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #412715;
}
.rtl .theme-sdtd.submenu-hover.color-green .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #05201b;
}
.rtl .theme-sdtl.submenu-hover.color-green .sidebar .sidebar-inner .nav-sidebar > li.nav-parent.nav-hover:before {
  border-left: 10px solid #05201b;
}
.rtl .submenu-hover .sidebar .sidebar-inner .nav-sidebar .nav-hover .children {
  -moz-box-shadow: -3px 3px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: -3px 3px 1px rgba(0, 0, 0, 0.2);
  box-shadow: -3px 3px 1px rgba(0, 0, 0, 0.2);
  display: block;
}
.rtl .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li.nav-hover > a span:not(.arrow) {
  display: inline-block;
  text-align: right;
}
.rtl .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar > li > a > span:not(.arrow) {
  left: auto;
  right: 40px;
}
.rtl .sidebar-collapsed .sidebar .sidebar-inner .nav-sidebar li a i {
  float: none;
}
.rtl .widget-weather .weather .weather-location .weather-search-form:before {
  left: 7px;
  right: auto;
  top: 3px;
}
