.ladda-button{position:relative}
.ladda-button .ladda-spinner{font-size:11px; position:absolute;z-index:2;display:inline-block;width:22px;height:22px;top:50%;margin-top:-11px;opacity:0;pointer-events:none}
.ladda-button .ladda-label{ position:relative;z-index:3}
.ladda-button .ladda-progress{font-size:11px; position:absolute;width:0;height:100%;left:0;top:0;background:rgba(0,0,0,.2);visibility:hidden;opacity:0;-webkit-transition:.1s linear all!important;-moz-transition:.1s linear all!important;-ms-transition:.1s linear all!important;-o-transition:.1s linear all!important;transition:.1s linear all!important}
.ladda-button[data-loading] .ladda-progress{opacity:1;visibility:visible}
.ladda-button,.ladda-button .ladda-label,.ladda-button .ladda-spinner{-webkit-transition:.3s cubic-bezier(0.175,.885,.32,1.275) all!important;-moz-transition:.3s cubic-bezier(0.175,.885,.32,1.275) all!important;-ms-transition:.3s cubic-bezier(0.175,.885,.32,1.275) all!important;-o-transition:2.3s cubic-bezier(0.175,.885,.32,1.275) all!important;transition:.3s cubic-bezier(0.175,.885,.32,1.275) all!important}
.ladda-button[data-style=zoom-in],.ladda-button[data-style=zoom-in] .ladda-label,.ladda-button[data-style=zoom-in] .ladda-spinner,.ladda-button[data-style=zoom-out],.ladda-button[data-style=zoom-out] .ladda-label,.ladda-button[data-style=zoom-out] .ladda-spinner{-webkit-transition:.3s ease all!important;-moz-transition:.3s ease all!important;-ms-transition:.3s ease all!important;-o-transition:.3s ease all!important;transition:.3s ease all!important}
.ladda-button[data-style=expand-right] .ladda-spinner{right:14px}
.ladda-button[data-style=expand-right][data-size="s"] .ladda-spinner,.ladda-button[data-style=expand-right][data-size=xs] .ladda-spinner{right:4px}
.ladda-button[data-style=expand-right][data-loading]{padding-right:56px}
.ladda-button[data-style=expand-right][data-loading] .ladda-spinner{opacity:1}
.ladda-button[data-style=expand-right][data-loading][data-size="s"],.ladda-button[data-style=expand-right][data-loading][data-size=xs]{padding-right:40px}
.ladda-button[data-style=expand-left] .ladda-spinner{left:14px}

.rtl .ladda-button[data-style=expand-left] .ladda-spinner{right:14px;left:auto;}
.ladda-button[data-style=expand-left][data-size="s"] .ladda-spinner,.ladda-button[data-style=expand-left][data-size=xs] .ladda-spinner{left:4px}
.ladda-button[data-style=expand-left][data-loading]{padding-left:56px}
.rtl .ladda-button[data-style=expand-left][data-loading]{padding-left:10px;padding-right: 46px;}
.ladda-button[data-style=expand-left][data-loading] .ladda-spinner{opacity:1}
.ladda-button[data-style=expand-left][data-loading][data-size="s"],.ladda-button[data-style=expand-left][data-loading][data-size=xs]{padding-left:40px}
.ladda-button[data-style=expand-up]{overflow:hidden}
.ladda-button[data-style=expand-up] .ladda-spinner{top:-32px;left:50%;margin-left:-16px}
.ladda-button[data-style=expand-up][data-loading]{padding-top:54px}
.ladda-button[data-style=expand-up][data-loading] .ladda-spinner{opacity:1;top:14px;margin-top:0}
.ladda-button[data-style=expand-up][data-loading][data-size="s"],.ladda-button[data-style=expand-up][data-loading][data-size=xs]{padding-top:32px}
.ladda-button[data-style=expand-up][data-loading][data-size="s"] .ladda-spinner,.ladda-button[data-style=expand-up][data-loading][data-size=xs] .ladda-spinner{top:4px}
.ladda-button[data-style=expand-down]{overflow:hidden}
.ladda-button[data-style=expand-down] .ladda-spinner{top:62px;left:50%;margin-left:-16px}
.ladda-button[data-style=expand-down][data-size="s"] .ladda-spinner,.ladda-button[data-style=expand-down][data-size=xs] .ladda-spinner{top:40px}
.ladda-button[data-style=expand-down][data-loading]{padding-bottom:54px}
.ladda-button[data-style=expand-down][data-loading] .ladda-spinner{opacity:1}
.ladda-button[data-style=expand-down][data-loading][data-size="s"],.ladda-button[data-style=expand-down][data-loading][data-size=xs]{padding-bottom:32px}
.ladda-button[data-style=slide-left]{overflow:hidden}
.ladda-button[data-style=slide-left] .ladda-label{position:relative}
.ladda-button[data-style=slide-left] .ladda-spinner{left:100%;margin-left:-16px}
.ladda-button[data-style=slide-left][data-loading] .ladda-label{opacity:0;left:-100%}
.ladda-button[data-style=slide-left][data-loading] .ladda-spinner{opacity:1;left:50%}
.ladda-button[data-style=slide-right]{overflow:hidden}
.ladda-button[data-style=slide-right] .ladda-label{position:relative}
.ladda-button[data-style=slide-right] .ladda-spinner{right:100%;margin-left:-16px}
.ladda-button[data-style=slide-right][data-loading] .ladda-label{opacity:0;left:100%}
.ladda-button[data-style=slide-right][data-loading] .ladda-spinner{opacity:1;left:50%}
.ladda-button[data-style=slide-up]{overflow:hidden}
.ladda-button[data-style=slide-up] .ladda-label{position:relative}
.ladda-button[data-style=slide-up] .ladda-spinner{left:50%;margin-left:-16px;margin-top:1em}
.ladda-button[data-style=slide-up][data-loading] .ladda-label{opacity:0;top:-1em}
.ladda-button[data-style=slide-up][data-loading] .ladda-spinner{opacity:1;margin-top:-16px}
.ladda-button[data-style=slide-down]{overflow:hidden}
.ladda-button[data-style=slide-down] .ladda-label{position:relative}
.ladda-button[data-style=slide-down] .ladda-spinner{left:50%;margin-left:-16px;margin-top:-2em}
.ladda-button[data-style=slide-down][data-loading] .ladda-label{opacity:0;top:1em}
.ladda-button[data-style=slide-down][data-loading] .ladda-spinner{opacity:1;margin-top:-16px}
.ladda-button[data-style=zoom-out]{overflow:hidden}
.ladda-button[data-style=zoom-out] .ladda-spinner{left:50%;margin-left:-16px;-webkit-transform:scale(2.5);-moz-transform:scale(2.5);-ms-transform:scale(2.5);-o-transform:scale(2.5);transform:scale(2.5)}
.ladda-button[data-style=zoom-out] .ladda-label{position:relative;display:inline-block}
.ladda-button[data-style=zoom-out][data-loading] .ladda-label{opacity:0;-webkit-transform:scale(0.5);-moz-transform:scale(0.5);-ms-transform:scale(0.5);-o-transform:scale(0.5);transform:scale(0.5)}
.ladda-button[data-style=zoom-out][data-loading] .ladda-spinner{opacity:1;-webkit-transform:none;-moz-transform:none;-ms-transform:none;-o-transform:none;transform:none}
.ladda-button[data-style=zoom-in]{overflow:hidden}
.ladda-button[data-style=zoom-in] .ladda-spinner{left:50%;margin-left:-16px;-webkit-transform:scale(0.2);-moz-transform:scale(0.2);-ms-transform:scale(0.2);-o-transform:scale(0.2);transform:scale(0.2)}
.ladda-button[data-style=zoom-in] .ladda-label{position:relative;display:inline-block}
.ladda-button[data-style=zoom-in][data-loading] .ladda-label{opacity:0;-webkit-transform:scale(2.2);-moz-transform:scale(2.2);-ms-transform:scale(2.2);-o-transform:scale(2.2);transform:scale(2.2)}
.ladda-button[data-style=zoom-in][data-loading] .ladda-spinner{opacity:1;-webkit-transform:none;-moz-transform:none;-ms-transform:none;-o-transform:none;transform:none}
.ladda-button[data-style=contract]{overflow:hidden;width:100px}
.ladda-button[data-style=contract] .ladda-spinner{left:50%;margin-left:-16px}
.ladda-button[data-style=contract][data-loading]{border-radius:50%;width:52px}
.ladda-button[data-style=contract][data-loading] .ladda-label{opacity:0}
.ladda-button[data-style=contract][data-loading] .ladda-spinner{opacity:1}
.ladda-button[data-style=contract-overlay]{overflow:hidden;width:100px;box-shadow:0 0 0 3000px rgba(0,0,0,0)}
.ladda-button[data-style=contract-overlay] .ladda-spinner{left:50%;margin-left:-16px}
.ladda-button[data-style=contract-overlay][data-loading]{border-radius:50%;width:52px;box-shadow:0 0 0 3000px rgba(0,0,0,.8)}
.ladda-button[data-style=contract-overlay][data-loading] .ladda-label{opacity:0}
.ladda-button[data-style=contract-overlay][data-loading] .ladda-spinner{opacity:1}