.select2-container{margin:0;position:relative;display:inline-block;vertical-align:middle}
.select2-container,.select2-drop,.select2-search,.select2-search input{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box}
.select2-container .select2-choice{display:block;height:26px;padding:0 0 0 8px;overflow:hidden;position:relative;border:1px solid #aaa;white-space:nowrap;line-height:26px;color:#444;text-decoration:none;border-radius:4px;background-clip:padding-box;-webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;background-color:#fff;background-image:-webkit-gradient(linear,left bottom,left top,color-stop(0,#eee),color-stop(0.5,#fff));background-image:-webkit-linear-gradient(center bottom,#eee 0%,#fff 50%);background-image:-moz-linear-gradient(center bottom,#eee 0%,#fff 50%);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr = '#ffffff',endColorstr = '#eeeeee',GradientType = 0);background-image:linear-gradient(to top,#eee 0%,#fff 50%)}
html[dir="rtl"] .select2-container .select2-choice, .rtl .select2-container .select2-choice{padding:0 8px 0 0}
.select2-container.select2-drop-above .select2-choice{border-bottom-color:#aaa;border-radius:0 0 4px 4px;background-image:-webkit-gradient(linear,left bottom,left top,color-stop(0,#eee),color-stop(0.9,#fff));background-image:-webkit-linear-gradient(center bottom,#eee 0%,#fff 90%);background-image:-moz-linear-gradient(center bottom,#eee 0%,#fff 90%);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff',endColorstr='#eeeeee',GradientType=0);background-image:linear-gradient(to bottom,#eee 0%,#fff 90%)}
.select2-container.select2-allowclear .select2-choice .select2-chosen{margin-right:42px}
.select2-container .select2-choice > .select2-chosen{margin-right:26px;display:block;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;float:none;width:auto}
html[dir="rtl"] .select2-container .select2-choice > .select2-chosen, .rtl .select2-container .select2-choice > .select2-chosen{margin-left:26px;margin-right:0}
.select2-container .select2-choice abbr{display:none;width:12px;height:12px;position:absolute;right:31px;top:8px;font-size:1px;text-decoration:none;border:0;background:url(select2.png) right top no-repeat;cursor:pointer;outline:0}
.select2-container.select2-allowclear .select2-choice abbr{display:inline-block}
.select2-container .select2-choice abbr:hover{background-position:right -11px;cursor:pointer}
.select2-drop-mask{border:0;margin:0;padding:0;position:fixed;left:0;top:0;min-height:100%;min-width:100%;height:auto;width:auto;opacity:0;z-index:9998;background-color:#fff;filter:alpha(opacity=0)}
.select2-drop{width:100%;margin-top:-1px;position:absolute;z-index:9999;top:100%;background:#fff;color:#000;border:1px solid #aaa;border-top:0;border-radius:0 0 4px 4px;-webkit-box-shadow:0 4px 5px rgba(0,0,0,.15);box-shadow:0 4px 5px rgba(0,0,0,.15)}
.select2-drop.select2-drop-above{margin-top:1px;border-top:1px solid #aaa;border-bottom:0;border-radius:4px 4px 0 0;-webkit-box-shadow:0 -4px 5px rgba(0,0,0,.15);box-shadow:0 -4px 5px rgba(0,0,0,.15)}
.select2-drop-active{border:1px solid #5897fb;border-top:none}
.select2-drop.select2-drop-above.select2-drop-active{border-top:1px solid #5897fb}
.select2-drop-auto-width{border-top:1px solid #aaa;width:auto}
.select2-container .select2-choice .select2-arrow{display:inline-block;width:18px;height:100%;position:absolute;right:0;top:0;border-left:1px solid #aaa;border-radius:0 4px 4px 0;background-clip:padding-box;background:#ccc;background-image:-webkit-gradient(linear,left bottom,left top,color-stop(0,#ccc),color-stop(0.6,#eee));background-image:-webkit-linear-gradient(center bottom,#ccc 0%,#eee 60%);background-image:-moz-linear-gradient(center bottom,#ccc 0%,#eee 60%);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr = '#eeeeee',endColorstr = '#cccccc',GradientType = 0);background-image:linear-gradient(to top,#ccc 0%,#eee 60%)}
html[dir="rtl"] .select2-container .select2-choice .select2-arrow, .rtl .select2-container .select2-choice .select2-arrow{left:0;right:auto;border-left:none;border-right:none;border-radius:4px 0 0 4px}
.select2-container .select2-choice .select2-arrow b{display:block;width:100%;height:100%;background:url(select2.png) no-repeat 0 1px}
html[dir="rtl"] .select2-container .select2-choice .select2-arrow b, .rtl .select2-container .select2-choice .select2-arrow b{background-position:2px 1px}
.select2-search{display:inline-block;width:100%;min-height:26px;margin:0;padding:4px 4px 0;position:relative;z-index:10000;white-space:nowrap}
.select2-search input{width:100%;height:auto!important;min-height:26px;padding:4px 20px 4px 5px;margin:0;outline:0;font-family:sans-serif;font-size:1em;border:1px solid #aaa;border-radius:0;-webkit-box-shadow:none;box-shadow:none;background:#fff url(select2.png) no-repeat 100% -22px;background:url(select2.png) no-repeat 100% -22px,-webkit-gradient(linear,left bottom,left top,color-stop(0.85,#fff),color-stop(0.99,#eee));background:url(select2.png) no-repeat 100% -22px,-webkit-linear-gradient(center bottom,#fff 85%,#eee 99%);background:url(select2.png) no-repeat 100% -22px,-moz-linear-gradient(center bottom,#fff 85%,#eee 99%);background:url(select2.png) no-repeat 100% -22px,linear-gradient(to bottom,#fff 85%,#eee 99%) 0 0}
html[dir="rtl"] .select2-search input, .rtl .select2-search input{padding:4px 5px 4px 20px;background:#fff url(select2.png) no-repeat -37px -22px;background:url(select2.png) no-repeat -37px -22px,-webkit-gradient(linear,left bottom,left top,color-stop(0.85,#fff),color-stop(0.99,#eee));background:url(select2.png) no-repeat -37px -22px,-webkit-linear-gradient(center bottom,#fff 85%,#eee 99%);background:url(select2.png) no-repeat -37px -22px,-moz-linear-gradient(center bottom,#fff 85%,#eee 99%);background:url(select2.png) no-repeat -37px -22px,linear-gradient(to bottom,#fff 85%,#eee 99%) 0 0}
.select2-search input.select2-active{background:#fff url(select2-spinner.gif) no-repeat 100%;background:url(select2-spinner.gif) no-repeat 100%,-webkit-gradient(linear,left bottom,left top,color-stop(0.85,#fff),color-stop(0.99,#eee));background:url(select2-spinner.gif) no-repeat 100%,-webkit-linear-gradient(center bottom,#fff 85%,#eee 99%);background:url(select2-spinner.gif) no-repeat 100%,-moz-linear-gradient(center bottom,#fff 85%,#eee 99%);background:url(select2-spinner.gif) no-repeat 100%,linear-gradient(to bottom,#fff 85%,#eee 99%) 0 0}
.select2-container-active .select2-choice,.select2-container-active .select2-choices{border:1px solid #5897fb;outline:none;-webkit-box-shadow:0 0 5px rgba(0,0,0,.3);box-shadow:0 0 5px rgba(0,0,0,.3)}
.select2-dropdown-open .select2-choice{border-bottom-color:transparent;-webkit-box-shadow:0 1px 0 #fff inset;box-shadow:0 1px 0 #fff inset;border-bottom-left-radius:0;border-bottom-right-radius:0;background-color:#eee;background-image:-webkit-gradient(linear,left bottom,left top,color-stop(0,#fff),color-stop(0.5,#eee));background-image:-webkit-linear-gradient(center bottom,#fff 0%,#eee 50%);background-image:-moz-linear-gradient(center bottom,#fff 0%,#eee 50%);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee',endColorstr='#ffffff',GradientType=0);background-image:linear-gradient(to top,#fff 0%,#eee 50%)}
.select2-dropdown-open.select2-drop-above .select2-choice,.select2-dropdown-open.select2-drop-above .select2-choices{border:1px solid #5897fb;border-top-color:transparent;background-image:-webkit-gradient(linear,left top,left bottom,color-stop(0,#fff),color-stop(0.5,#eee));background-image:-webkit-linear-gradient(center top,#fff 0%,#eee 50%);background-image:-moz-linear-gradient(center top,#fff 0%,#eee 50%);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee',endColorstr='#ffffff',GradientType=0);background-image:linear-gradient(to bottom,#fff 0%,#eee 50%)}
.select2-dropdown-open .select2-choice .select2-arrow{background:transparent;border-left:none;filter:none}
html[dir="rtl"] .select2-dropdown-open .select2-choice .select2-arrow, .rtl .select2-dropdown-open .select2-choice .select2-arrow{border-right:none}
.select2-dropdown-open .select2-choice .select2-arrow b{background-position:-18px 1px}
html[dir="rtl"] .select2-dropdown-open .select2-choice .select2-arrow b, .rtl .select2-dropdown-open .select2-choice .select2-arrow b{background-position:-16px 1px}
.select2-hidden-accessible{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}
.select2-results{max-height:200px;padding:0 0 0 4px;margin:4px 4px 4px 0;position:relative;overflow-x:hidden;overflow-y:auto;-webkit-tap-highlight-color:rgba(0,0,0,0)}
html[dir="rtl"] .select2-results, .rtl .select2-results{padding:0 4px 0 0;margin:4px 0 4px 4px}
.select2-results ul.select2-result-sub{margin:0;padding-left:0}
.select2-results li{list-style:none;display:list-item;background-image:none}
.select2-results li.select2-result-with-children > .select2-result-label{font-weight:700}
.select2-results .select2-result-label{padding:5px 7px 4px;margin:0;cursor:pointer;min-height:1em;-webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
.select2-results-dept-1 .select2-result-label{padding-left:20px}
.select2-results-dept-2 .select2-result-label{padding-left:40px}
.select2-results-dept-3 .select2-result-label{padding-left:60px}
.select2-results-dept-4 .select2-result-label{padding-left:80px}
.select2-results-dept-5 .select2-result-label{padding-left:100px}
.select2-results-dept-6 .select2-result-label{padding-left:110px}
.select2-results-dept-7 .select2-result-label{padding-left:120px}
.select2-results .select2-highlighted{background:#3875d7;color:#fff}
.select2-results li em{background:#feffde;font-style:normal}
.select2-results .select2-highlighted em{background:transparent}
.select2-results .select2-highlighted ul{background:#fff;color:#000}
.select2-results .select2-no-results,.select2-results .select2-searching,.select2-results .select2-ajax-error,.select2-results .select2-selection-limit{background:#f4f4f4;display:list-item;padding-left:5px}
.select2-results .select2-disabled.select2-highlighted{color:#666;background:#f4f4f4;display:list-item;cursor:default}
.select2-results .select2-disabled{background:#f4f4f4;display:list-item;cursor:default}
.select2-results .select2-selected{display:none}
.select2-more-results.select2-active{background:#f4f4f4 url(select2-spinner.gif) no-repeat 100%}
.select2-results .select2-ajax-error{background:rgba(255,50,50,.2)}
.select2-more-results{background:#f4f4f4;display:list-item}
.select2-container.select2-container-disabled .select2-choice{background-color:#f4f4f4;background-image:none;border:1px solid #ddd;cursor:default}
.select2-container.select2-container-disabled .select2-choice .select2-arrow{background-color:#f4f4f4;background-image:none;border-left:0}
.select2-container.select2-container-disabled .select2-choice abbr{display:none}
.select2-container-multi .select2-choices{height:auto!important;height:1%;margin:0;padding:0 5px 0 0;position:relative;border:1px solid #aaa;cursor:text;overflow:hidden;background-color:#fff;background-image:-webkit-gradient(linear,0% 0%,0% 100%,color-stop(1%,#eee),color-stop(15%,#fff));background-image:-webkit-linear-gradient(top,#eee 1%,#fff 15%);background-image:-moz-linear-gradient(top,#eee 1%,#fff 15%);background-image:linear-gradient(to bottom,#eee 1%,#fff 15%)}
html[dir="rtl"] .select2-container-multi .select2-choices, .rtl .select2-container-multi .select2-choices{padding:0 0 0 5px}
.select2-locked{padding:3px 5px!important}
.select2-container-multi .select2-choices{min-height:26px}
.select2-container-multi.select2-container-active .select2-choices{border:1px solid #5897fb;outline:none;-webkit-box-shadow:0 0 5px rgba(0,0,0,.3);box-shadow:0 0 5px rgba(0,0,0,.3)}
.select2-container-multi .select2-choices li{float:left;list-style:none}
html[dir="rtl"] .select2-container-multi .select2-choices li, .rtl .select2-container-multi .select2-choices li{float:right}
.select2-container-multi .select2-choices .select2-search-field{margin:0;padding:0;white-space:nowrap}
.select2-container-multi .select2-choices .select2-search-field input{padding:5px;margin:1px 0;font-family:sans-serif;font-size:100%;color:#666;outline:0;border:0;-webkit-box-shadow:none;box-shadow:none;background:transparent!important}
.select2-container-multi .select2-choices .select2-search-field input.select2-active{background:#fff url(select2-spinner.gif) no-repeat 100%!important}
.select2-default{color:#999!important}
.select2-container-multi .select2-choices .select2-search-choice{padding:3px 5px 3px 18px;margin:3px 0 3px 5px;position:relative;line-height:13px;color:#333;cursor:default;border:1px solid #aaa;border-radius:3px;-webkit-box-shadow:0 0 2px #fff inset,0 1px 0 rgba(0,0,0,0.05);box-shadow:0 0 2px #fff inset,0 1px 0 rgba(0,0,0,0.05);background-clip:padding-box;-webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;background-color:#e4e4e4;filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee',endColorstr='#f4f4f4',GradientType=0);background-image:-webkit-gradient(linear,0% 0%,0% 100%,color-stop(20%,#f4f4f4),color-stop(50%,#f0f0f0),color-stop(52%,#e8e8e8),color-stop(100%,#eee));background-image:-webkit-linear-gradient(top,#f4f4f4 20%,#f0f0f0 50%,#e8e8e8 52%,#eee 100%);background-image:-moz-linear-gradient(top,#f4f4f4 20%,#f0f0f0 50%,#e8e8e8 52%,#eee 100%);background-image:linear-gradient(to bottom,#f4f4f4 20%,#f0f0f0 50%,#e8e8e8 52%,#eee 100%)}
html[dir="rtl"] .select2-container-multi .select2-choices .select2-search-choice, .rtl .select2-container-multi .select2-choices .select2-search-choice{margin:3px 5px 3px 0;padding:3px 18px 3px 5px}
.select2-container-multi .select2-choices .select2-search-choice .select2-chosen{cursor:default}
.select2-container-multi .select2-choices .select2-search-choice-focus{background:#d4d4d4}
.select2-search-choice-close{display:block;width:12px;height:13px;position:absolute;right:3px;top:4px;font-size:1px;outline:none;background:url(select2.png) right top no-repeat}
html[dir="rtl"] .select2-search-choice-close, .rtl .select2-search-choice-close{right:auto;left:3px}
.select2-container-multi .select2-search-choice-close{left:3px}
html[dir="rtl"] .select2-container-multi .select2-search-choice-close, .rtl .select2-container-multi .select2-search-choice-close{left:auto;right:2px}
.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:hover{background-position:right -11px}
.select2-container-multi .select2-choices .select2-search-choice-focus .select2-search-choice-close{background-position:right -11px}
.select2-container-multi.select2-container-disabled .select2-choices{background-color:#f4f4f4;background-image:none;border:1px solid #ddd;cursor:default}
.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice{padding:3px 5px;border:1px solid #ddd;background-image:none;background-color:#f4f4f4}
.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close{display:none;background:none}
.select2-result-selectable .select2-match,.select2-result-unselectable .select2-match{text-decoration:underline}
.select2-offscreen,.select2-offscreen:focus{clip:rect(0 0 0 0)!important;width:1px!important;height:1px!important;border:0!important;margin:0!important;padding:0!important;overflow:hidden!important;position:absolute!important;outline:0!important;left:0!important;top:0!important}
.select2-display-none{display:none}
.select2-measure-scrollbar{position:absolute;top:-10000px;left:-10000px;width:100px;height:100px;overflow:scroll}
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),only screen and (min-resolution: 2dppx) {
.select2-search input,.select2-search-choice-close,.select2-container .select2-choice abbr,.select2-container .select2-choice .select2-arrow b{background-image:url(select2x2.png)!important;background-repeat:no-repeat!important;background-size:60px 40px!important}
.select2-search input{background-position:100% -21px!important}
}
.select2-container.form-control{background:transparent;box-shadow:none;border:none !important;display:block;margin:0;padding:0}
.select2-container .select2-choices .select2-search-field input,.select2-container .select2-choice,.select2-container .select2-choices{background:none;padding:0;border-color:#ccc;border-radius:4px;color:#555;font-family:"Lato",Helvetica,Arial,sans-serif;background-color:#fff;filter:progid:DXImageTransform.Microsoft.gradient(enabled = false)}
.form-white.select2-container .select2-choice {background: #fff !important; background-image: none !important;}
.form-control.form-white.select2-container:hover, .form-control.form-white.select2-container:focus, .form-control.form-white.select2-container:visited {background-color: none !important;border: none;}
.form-white.select2-drop.select2-drop-active {background: #fff !important;}
.select2-search input{border-color:#ccc;border-radius:4px;color:#555;font-family:"Lato",Helvetica,Arial,sans-serif;background-color:#fff;filter:progid:DXImageTransform.Microsoft.gradient(enabled = false)}
.select2-container .select2-choices .select2-search-field input{-webkit-box-shadow:none;box-shadow:none}
.select2-container .select2-choice{height:34px;line-height:1.7}
.select2-container.select2-container-multi.form-control{height:auto}
.select2-container.input-sm .select2-choice,.input-group-sm .select2-container .select2-choice{height:30px;line-height:1.5;border-radius:3px}
.select2-container.input-lg .select2-choice,.input-group-lg .select2-container .select2-choice{height:46px;line-height:1.33;border-radius:6px}
.select2-container-multi .select2-choices .select2-search-field input{height:32px}
.select2-container-multi.input-sm .select2-choices .select2-search-field input,.input-group-sm .select2-container-multi .select2-choices .select2-search-field input{height:28px}
.select2-container-multi.input-lg .select2-choices .select2-search-field input,.input-group-lg .select2-container-multi .select2-choices .select2-search-field input{height:44px}
.select2-container-multi .select2-choices .select2-search-field input{margin:0}
.select2-chosen,.select2-choice > span:first-child,.select2-container .select2-choices .select2-search-field input{padding:6px 12px}
.input-sm .select2-chosen,.input-group-sm .select2-chosen,.input-sm .select2-choice > span:first-child,.input-group-sm .select2-choice > span:first-child,.input-sm .select2-choices .select2-search-field input,.input-group-sm .select2-choices .select2-search-field input{padding:5px 10px}
.input-lg .select2-chosen,.input-group-lg .select2-chosen,.input-lg .select2-choice > span:first-child,.input-group-lg .select2-choice > span:first-child,.input-lg .select2-choices .select2-search-field input,.input-group-lg .select2-choices .select2-search-field input{padding:10px 16px}
.select2-container-multi .select2-choices .select2-search-choice{margin-top:5px;margin-bottom:3px}
.select2-container-multi.input-sm .select2-choices .select2-search-choice,.input-group-sm .select2-container-multi .select2-choices .select2-search-choice{margin-top:3px;margin-bottom:2px}
.select2-container-multi.input-lg .select2-choices .select2-search-choice,.input-group-lg .select2-container-multi .select2-choices .select2-search-choice{line-height:24px}
.select2-container .select2-choice .select2-arrow,.select2-container .select2-choice div{border-left:none;background:none;filter:progid:DXImageTransform.Microsoft.gradient(enabled = false)}
.select2-dropdown-open .select2-choice .select2-arrow,.select2-dropdown-open .select2-choice div{border-left-color:transparent;background:none;filter:progid:DXImageTransform.Microsoft.gradient(enabled = false)}
.select2-container .select2-choice .select2-arrow b,.select2-container .select2-choice div b{background-position:0 3px}
.select2-dropdown-open .select2-choice .select2-arrow b,.select2-dropdown-open .select2-choice div b{background-position:-18px 3px}
.select2-container.input-sm .select2-choice .select2-arrow b,.input-group-sm .select2-container .select2-choice .select2-arrow b,.select2-container.input-sm .select2-choice div b,.input-group-sm .select2-container .select2-choice div b{background-position:0 1px}
.select2-dropdown-open.input-sm .select2-choice .select2-arrow b,.input-group-sm .select2-dropdown-open .select2-choice .select2-arrow b,.select2-dropdown-open.input-sm .select2-choice div b,.input-group-sm .select2-dropdown-open .select2-choice div b{background-position:-18px 1px}
.select2-container.input-lg .select2-choice .select2-arrow b,.input-group-lg .select2-container .select2-choice .select2-arrow b,.select2-container.input-lg .select2-choice div b,.input-group-lg .select2-container .select2-choice div b{background-position:0 9px}
.select2-dropdown-open.input-lg .select2-choice .select2-arrow b,.input-group-lg .select2-dropdown-open .select2-choice .select2-arrow b,.select2-dropdown-open.input-lg .select2-choice div b,.input-group-lg .select2-dropdown-open .select2-choice div b{background-position:-18px 9px}
.has-warning .select2-choice,.has-warning .select2-choices{border-color:#8a6d3b}
.has-warning .select2-container-active .select2-choice,.has-warning .select2-container-multi.select2-container-active .select2-choices{border-color:#66512c;-webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 6px #c0a16b;box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 6px #c0a16b}
.has-warning.select2-drop-active{border-color:#66512c}
.has-warning.select2-drop-active.select2-drop.select2-drop-above{border-top-color:#66512c}
.has-error .select2-choice,.has-error .select2-choices{border-color:#a94442}
.has-error .select2-container-active .select2-choice,.has-error .select2-container-multi.select2-container-active .select2-choices{border-color:#843534;-webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 6px #ce8483;box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 6px #ce8483}
.has-error.select2-drop-active{border-color:#843534}
.has-error.select2-drop-active.select2-drop.select2-drop-above{border-top-color:#843534}
.has-success .select2-choice,.has-success .select2-choices{border-color:#3c763d}
.has-success .select2-container-active .select2-choice,.has-success .select2-container-multi.select2-container-active .select2-choices{border-color:#2b542c;-webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 6px #67b168;box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 6px #67b168}
.has-success.select2-drop-active{border-color:#2b542c}
.has-success.select2-drop-active.select2-drop.select2-drop-above{border-top-color:#2b542c}
.select2-container-active .select2-choice,.select2-container-multi.select2-container-active .select2-choices{border-color:#66afe9;outline:none;-webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 8px rgba(82,168,236,0.6);box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 8px rgba(82,168,236,0.6);-webkit-transition:border-color ease-in-out 0.15s,box-shadow ease-in-out .15s;-o-transition:border-color ease-in-out 0.15s,box-shadow ease-in-out .15s;transition:border-color ease-in-out 0.15s,box-shadow ease-in-out .15s}
.select2-drop-active{border-color:#66afe9}
.select2-drop-auto-width,.select2-drop.select2-drop-above.select2-drop-active{border-top-color:#66afe9}
.input-group.select2-bootstrap-prepend [class^="select2-choice"]{border-bottom-left-radius:0!important;border-top-left-radius:0!important}
.input-group.select2-bootstrap-append [class^="select2-choice"]{border-bottom-right-radius:0!important;border-top-right-radius:0!important}
.select2-dropdown-open [class^="select2-choice"]{border-bottom-right-radius:0!important;border-bottom-left-radius:0!important}
.select2-dropdown-open.select2-drop-above [class^="select2-choice"]{border-top-right-radius:0!important;border-top-left-radius:0!important;border-bottom-right-radius:4px!important;border-bottom-left-radius:4px!important;background:#fff;filter:none}
.input-group.select2-bootstrap-prepend .select2-dropdown-open.select2-drop-above [class^="select2-choice"]{border-bottom-left-radius:0!important;border-top-left-radius:0!important}
.input-group.select2-bootstrap-append .select2-dropdown-open.select2-drop-above [class^="select2-choice"]{border-bottom-right-radius:0!important;border-top-right-radius:0!important}
.input-group.input-group-sm.select2-bootstrap-prepend .select2-dropdown-open.select2-drop-above [class^="select2-choice"]{border-bottom-right-radius:3px!important}
.input-group.input-group-lg.select2-bootstrap-prepend .select2-dropdown-open.select2-drop-above [class^="select2-choice"]{border-bottom-right-radius:6px!important}
.input-group.input-group-sm.select2-bootstrap-append .select2-dropdown-open.select2-drop-above [class^="select2-choice"]{border-bottom-left-radius:3px!important}
.input-group.input-group-lg.select2-bootstrap-append .select2-dropdown-open.select2-drop-above [class^="select2-choice"]{border-bottom-left-radius:6px!important}
.select2-results .select2-highlighted{color:#fff;background-color:#2B2E33}
.select2-bootstrap-append .select2-container-multiple,.select2-bootstrap-append .input-group-btn,.select2-bootstrap-append .input-group-btn .btn,.select2-bootstrap-prepend .select2-container-multiple,.select2-bootstrap-prepend .input-group-btn,.select2-bootstrap-prepend .input-group-btn .btn{vertical-align:top}
.select2-container-multi .select2-choices .select2-search-choice{color:#555;background:#fff;border-color:#ccc;filter:progid:DXImageTransform.Microsoft.gradient(enabled = false);-webkit-box-shadow:none;box-shadow:none}
.select2-container-multi .select2-choices .select2-search-choice-focus{background:#ebebeb;border-color:#adadad;color:#333;-webkit-box-shadow:none;box-shadow:none}
.select2-search-choice-close{margin-top:-7px;top:50%}
.select2-container .select2-choice abbr{top:40%}
.select2-results .select2-no-results,.select2-results .select2-searching,.select2-results .select2-selection-limit{background-color:#fcf8e3;color:#8a6d3b}
.select2-container.select2-container-disabled .select2-choice,.select2-container.select2-container-disabled .select2-choices{cursor:not-allowed;background-color:#eee;border-color:#ccc}
.select2-container.select2-container-disabled .select2-choice .select2-arrow,.select2-container.select2-container-disabled .select2-choice div,.select2-container.select2-container-disabled .select2-choices .select2-arrow,.select2-container.select2-container-disabled .select2-choices div{background-color:transparent;border-left:1px solid transparent}
.select2-search input.select2-active,.select2-container-multi .select2-choices .select2-search-field input.select2-active,.select2-more-results.select2-active{background-position:99%;background-position:right 4px center}
.select2-offscreen,.select2-offscreen:focus{width:1px!important;height:1px!important;position:absolute!important}